import React, { FunctionComponent } from 'react';
import Navigation from './components/Navigation';
import { Box } from '@mui/material';
import { useAppSelector } from './app/hooks';
import ROUTES from './routes';
import theme from './theme';
import { useTranslation } from 'react-i18next';

const App: FunctionComponent = () => {

    const {step} = useAppSelector((state) => state.navigation);
    const {t} = useTranslation();

    return (
        <div className="uk-container-expand uk-height-viewport"
             style={{contain: 'content', backgroundColor: theme.palette.text.secondary}}>
            <nav uk-navbar="true" className="uk-navbar-container" style={{paddingBottom: 0}}>
                <div className="uk-navbar-left">
                    <div className="brand-logo black-text">
                        <img alt="MUTAC" src="/assets/mutac_logo.png"/>
                    </div>
                    <p style={{marginLeft: 20, fontSize: '1.3rem'}}>
                        {t('translation:heading.1')}
                        <span style={{color: 'orange'}}> {t('translation:phone')} </span>
                        <span style={{fontSize: '0.7em'}}>{t('translation:heading.2')}</span>
                    </p>
                    <Navigation/>
                </div>
            </nav>

            {React.createElement(ROUTES.filter((r) => r.id === step)[0].pageWidget)}

            <Box sx={{position: 'absolute', bottom: 0, left: 0, zIndex: 0}}>
                <img alt="deco" src="/assets/decoration.png"/>
            </Box>
        </div>
    );
};

export default App;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum isConcerned {
    Celibataire = 3,
    Couple,
    ProtectedPerson,
}

export enum Civility {
    Monsieur = 1,
    Madame,
}

export enum WhatIWant {
    Financing = 41,
    Organising = 85,
    FinancingAndOrganising = 40,
    Transferring = 42,
}

export enum Payment {
    Single = 28,
    Monthly = 29,
    Annual = 29,
}

export enum Duration {
    Five = 33,
    Ten,
    Fifteen,
    Twenty,
    Unlimited = 84,
}

export enum Deferred {
    Yes = 52,
    No = 51,
    DoNotKnow = 52,
}

export enum InsuranceKnowledge {
    Yes = 13,
    No,
    DoNotKnow,
}

export enum LifeInsurance {
    Yes = 21,
    No,
    DoNotKnow,
}

export enum FinancialKnowledge {
    OnInFive = 16,
    TwoInFive,
    ThreeInFive,
    FourInFive,
    FiveInFive,
}

export enum Income {
    Between1000and3000 = 58,
    Between3001and5000,
    MoreThan5001,
    DoNotKnow = 66,
}

export enum ChargePourcent {
    LessThan30 = 61,
    Between30and50,
    Between50and75,
    Between75and90,
    MoreThan90,
    DoNotKnow = 67,
}

export enum Upgrade {
    Certain = 47,
    Potential,
    DoNotKnow = 47,
}

export enum Cnracl {
    Yes = 70,
    No,
}

export enum ElectedMandat {
    Yes = 1,
    No = 0,
}

export enum BeneficiaryType {
    Physical = 1,
    Corporation,
}

export enum SevereIllness {
    Yes = 49,
    No,
}

export enum Tie {
    Spouse = 1,
    Daughter,
    Son,
    Sister,
    Brother,
    Mother,
    Father,
    Other,
}

export enum TieMutac {
    'Conjoint(e)',
    'Fille',
    'Fils',
    'Soeur',
    'Frère',
    'Mère',
    'Père',
    'Autre',
}

export enum LegalStatus {
    Association = 1,
    Sci,
    Sas,
    Sarl,
    Snc,
    Unknown,
}

export enum PaymentSupport {
    Mutac = 1,
    Other,
}

export enum FamilySituation {
    Single,
    Married,
    CivilUnion,
    Divorced,
    Widower,
}

export enum FamilySituationMutac {
    'Célibataire',
    'Marié(e)',
    'Pacsé(e)',
    'Divorcé(e)',
    'Veuf(ve)',
}

export enum Dependent {
    NotHave = 0,
    One,
    Two,
    Three,
    MoreThree,
    DoNotKnow,
}

export interface LifeInsuranceInfos {
    contractOrOrganizationName?: string,
    contractsNumber?: number,
    totalAmount?: number,
    contractOrOrganizationNameSpouse?: string,
    contractsNumberSpouse?: number,
    totalAmountSpouse?: number,
}

export interface Beneficiary {
    id?: number,
    idWeb?: number,
    type?: BeneficiaryType,
    distribution?: string,
    streetNumber?: string,
    streetName?: string,
    zipcode?: string,
    city?: string,
    physicalLastname?: string,
    physicalFirstname?: string,
    physicalBirthday?: number,
    physicalBirthCity?: string,
    physicalTie?: string,
    tieOther?: string,
    county?: string,
    companyName?: string,
    sirenNumber?: string,
    legalStatus?: string
}

export interface KycState {
    idAccount?: number,
    idAccountSpouse?: number,
    membershipId?: string,
    membershipIdSpouse?: string,
    departmentId?: string,
    departmentLabel?: string,
    averageCost?: number,
    whoIsConcerned?: isConcerned,
    agreement?: boolean,
    whatIWant?: string,
    capital?: number,
    payment?: string,
    duration?: string,
    durationNumber?: number,
    deferred?: string,
    singlePayment?: string,
    income?: string,
    chargePourcent?: string,
    upgrade?: string,
    civility?: Civility,
    civilitySpouse?: Civility,
    lastname?: string,
    lastnameSpouse?: string,
    firstname?: string,
    firstnameSpouse?: string,
    birthDate?: number,
    birthDateSpouse?: number,
    address?: {
        number?: string,
        street?: string,
        complement?: string,
        zipcode?: string,
        city?: string,
    },
    taxResidence?: boolean,
    phoneNumber?: string,
    email?: string,
    CNRACLRetired?: string,
    CNRACLRetiredSpouse?: string,
    electedMandate?: string,
    electedMandateSpouse?: string,
    severeIllness?: string,
    severeIllnessSpouse?: string,
    lifeInsuranceKnowledge?: string,
    lifeInsuranceKnowledgeSpouse?: string,
    lifeInsuranceOwned?: string,
    lifeInsuranceOwnedSpouse?: string,
    lifeInsurance?: LifeInsuranceInfos[],
    lifeInsuranceSpouse?: LifeInsuranceInfos[],
    financialKnowledge?: string,
    financialKnowledgeSpouse?: string,
    lifeInsurancePolicy?: boolean,
    lifeInsurancePolicySpouse?: boolean,
    agreementResume1?: boolean,
    agreementResume2?: boolean,
    sendQuote?: boolean,
    familySituation?: string,
    dependent?: string,
    fromCity?: string,
    fromCountyId?: string,
    fromCountyLabel?: string,
    socialSecurityNumber?: string,
    birthName?: string,
    profession?: string,
    professionSpouse?: string,
    paymentSupport?: string,
    beneficiaries?: Beneficiary[],
    iban?: string,
    bic?: string,
    debitDay?: string,
    isRecurringPayment?: boolean,
    periodicity?: string,
    payerInformation?: { [key: string]: string },
    agreementDebit?: boolean,
    isAccountDebit?: boolean,
    agreementContract?: boolean,
    agreementGC?: boolean,
    quotationNrid?: number,
    quotationRef?: string,
    quotationDate?: string,
    quotationValidDate?: string,
    folderGuidToQuotation?: string,
    guarantyName?: string,
    agreementFormContract?: boolean,
    creationDate?: string,
    quotationUpload?: boolean,
    idCardUpload?: boolean,
    ribUpload?: boolean,
}

const initialState: KycState = {
    agreement: false,
    agreementContract: false,
    agreementDebit: false,
    agreementGC: false,
    agreementResume1: false,
    agreementResume2: false,
    agreementFormContract: false,
    beneficiaries: [],
    lifeInsurance: [],
    lifeInsuranceSpouse: [],
    quotationUpload: false,
    idCardUpload: false,
    ribUpload: false,
    isRecurringPayment: true,
};

const kycSlice = createSlice({
    name: 'kyc',
    initialState,
    reducers: {
        updateDepartment: (state: KycState, action: PayloadAction<KycState>) => {
            state.departmentId = action.payload.departmentId;
            state.departmentLabel = action.payload.departmentLabel;
        },
        updateAverageCost: (state: KycState, action: PayloadAction<number>) => {
            state.averageCost = action.payload;
        },
        updateWhoIsConcerned: (state: KycState, action: PayloadAction<number>) => {
            state.whoIsConcerned = action.payload;
        },
        updateAgreement: (state: KycState, action: PayloadAction<boolean>) => {
            state.agreement = action.payload;
        },
        updateRequirements: (state: KycState, action: PayloadAction<KycState>) => {
            state.whatIWant = (action.payload.whatIWant !== undefined) ? action.payload.whatIWant : state.whatIWant;
            state.capital = (action.payload.capital !== undefined) ? action.payload.capital : state.capital;
            state.payment = (action.payload.payment !== undefined) ? action.payload.payment : state.payment;
            state.duration = (action.payload.duration !== undefined) ? action.payload.duration : state.duration;
            state.durationNumber = (action.payload.durationNumber !== undefined) ? action.payload.durationNumber : state.durationNumber;
            state.deferred = (action.payload.deferred !== undefined) ? action.payload.deferred : state.deferred;
            state.singlePayment = (action.payload.singlePayment !== undefined) ? action.payload.singlePayment : state.singlePayment;
        },
        updateFinancial: (state: KycState, action: PayloadAction<KycState>) => {
            state.income = (action.payload.income !== undefined) ? action.payload.income : state.income;
            state.chargePourcent = (action.payload.chargePourcent !== undefined) ? action.payload.chargePourcent : state.chargePourcent;
            state.upgrade = (action.payload.upgrade !== undefined) ? action.payload.upgrade : state.upgrade;
        },
        updateIdentity: (state: KycState, action: PayloadAction<KycState>) => {
            state.civility = (action.payload.civility !== undefined) ? action.payload.civility : state.civility;
            state.birthDate = (action.payload.birthDate !== undefined) ? action.payload.birthDate : state.birthDate;
            state.lastname = (action.payload.lastname !== undefined) ? action.payload.lastname : state.lastname;
            state.firstname = (action.payload.firstname !== undefined) ? action.payload.firstname : state.firstname;
            state.civilitySpouse = (action.payload.civilitySpouse !== undefined) ? action.payload.civilitySpouse : state.civilitySpouse;
            state.birthDateSpouse = (action.payload.birthDateSpouse !== undefined) ? action.payload.birthDateSpouse : state.birthDateSpouse;
            state.lastnameSpouse = (action.payload.lastnameSpouse !== undefined) ? action.payload.lastnameSpouse : state.lastnameSpouse;
            state.firstnameSpouse = (action.payload.firstnameSpouse !== undefined) ? action.payload.firstnameSpouse : state.firstnameSpouse;
            state.address = (action.payload.address?.number !== undefined) ? {
                ...state.address,
                number: action.payload.address.number
            } : state.address;
            state.address = (action.payload.address?.street !== undefined) ? {
                ...state.address,
                street: action.payload.address.street
            } : state.address;
            state.address = (action.payload.address?.complement !== undefined) ? {
                ...state.address,
                complement: action.payload.address.complement
            } : state.address;
            state.address = (action.payload.address?.zipcode !== undefined) ? {
                ...state.address,
                zipcode: action.payload.address.zipcode
            } : state.address;
            state.address = (action.payload.address?.city !== undefined) ? {
                ...state.address,
                city: action.payload.address.city
            } : state.address;
            state.taxResidence = (action.payload.taxResidence !== undefined) ? action.payload.taxResidence : state.taxResidence;
        },
        updateIdentityContact: (state: KycState, action: PayloadAction<KycState>) => {
            state.phoneNumber = (action.payload.phoneNumber !== undefined) ? action.payload.phoneNumber : state.phoneNumber;
            state.email = (action.payload.email !== undefined) ? action.payload.email : state.email;
        },
        updateIdentity3: (state: KycState, action: PayloadAction<KycState>) => {
            state.CNRACLRetired = (action.payload.CNRACLRetired !== undefined) ? action.payload.CNRACLRetired : state.CNRACLRetired;
            state.CNRACLRetiredSpouse = (action.payload.CNRACLRetiredSpouse !== undefined) ? action.payload.CNRACLRetiredSpouse : state.CNRACLRetiredSpouse;
        },
        updateIdentity4: (state: KycState, action: PayloadAction<KycState>) => {
            state.lifeInsuranceKnowledge = (action.payload.lifeInsuranceKnowledge !== undefined) ? action.payload.lifeInsuranceKnowledge : state.lifeInsuranceKnowledge;
            state.financialKnowledge = (action.payload.financialKnowledge !== undefined) ? action.payload.financialKnowledge : state.financialKnowledge;
            state.lifeInsuranceOwned = (action.payload.lifeInsuranceOwned !== undefined) ? action.payload.lifeInsuranceOwned : state.lifeInsuranceOwned;
            state.lifeInsuranceKnowledgeSpouse = (action.payload.lifeInsuranceKnowledgeSpouse !== undefined) ? action.payload.lifeInsuranceKnowledgeSpouse : state.lifeInsuranceKnowledgeSpouse;
            state.financialKnowledgeSpouse = (action.payload.financialKnowledgeSpouse !== undefined) ? action.payload.financialKnowledgeSpouse : state.financialKnowledgeSpouse;
            state.lifeInsuranceOwnedSpouse = (action.payload.lifeInsuranceOwnedSpouse !== undefined) ? action.payload.lifeInsuranceOwnedSpouse : state.lifeInsuranceOwnedSpouse;
        },
        updateIdentity5: (state: KycState, action: PayloadAction<KycState>) => {
            state.electedMandate = (action.payload.electedMandate !== undefined) ? action.payload.electedMandate : state.electedMandate;
            state.severeIllness = (action.payload.severeIllness !== undefined) ? action.payload.severeIllness : state.severeIllness;
            state.electedMandateSpouse = (action.payload.electedMandateSpouse !== undefined) ? action.payload.electedMandateSpouse : state.electedMandateSpouse;
            state.severeIllnessSpouse = (action.payload.severeIllnessSpouse !== undefined) ? action.payload.severeIllnessSpouse : state.severeIllnessSpouse;
        },
        addLifeInsuranceContract: (state: KycState, action: PayloadAction<LifeInsuranceInfos>) => {
            state.lifeInsurance = [...state.lifeInsurance!, {
                contractOrOrganizationName: action.payload.contractOrOrganizationName,
                contractsNumber: action.payload.contractsNumber,
                totalAmount: action.payload.totalAmount
            }];
        },
        addLifeInsuranceContractSpouse: (state: KycState, action: PayloadAction<LifeInsuranceInfos>) => {
            state.lifeInsuranceSpouse = [...state.lifeInsuranceSpouse!, {
                contractOrOrganizationNameSpouse: action.payload.contractOrOrganizationNameSpouse,
                contractsNumberSpouse: action.payload.contractsNumberSpouse,
                totalAmountSpouse: action.payload.totalAmountSpouse
            }];
        },
        removeLifeInsuranceContract: (state: KycState, action: PayloadAction<LifeInsuranceInfos>) => {
            state.lifeInsurance = state.lifeInsurance?.filter((a) =>
                a.contractOrOrganizationName !== action.payload.contractOrOrganizationName ||
                a.contractsNumber !== action.payload.contractsNumber ||
                a.totalAmount !== action.payload.totalAmount
            );
        },
        removeLifeInsuranceContractSpouse: (state: KycState, action: PayloadAction<LifeInsuranceInfos>) => {
            state.lifeInsuranceSpouse = state.lifeInsuranceSpouse?.filter((a) =>
                a.contractOrOrganizationNameSpouse !== action.payload.contractOrOrganizationNameSpouse ||
                a.contractsNumberSpouse !== action.payload.contractsNumberSpouse ||
                a.totalAmountSpouse !== action.payload.totalAmountSpouse
            );
        },
        updateAgreementResume: (state: KycState, action: PayloadAction<KycState>) => {
            state.agreementResume1 = (action.payload.agreementResume1 !== undefined) ? action.payload.agreementResume1 : state.agreementResume1;
            state.agreementResume2 = (action.payload.agreementResume2 !== undefined) ? action.payload.agreementResume2 : state.agreementResume2;
        },
        updateFamilySituation: (state: KycState, action: PayloadAction<KycState>) => {
            state.familySituation = (action.payload.familySituation !== undefined) ? action.payload.familySituation : state.familySituation;
            state.dependent = (action.payload.dependent !== undefined) ? action.payload.dependent : state.dependent;
        },
        updateFromOtherInfo: (state: KycState, action: PayloadAction<KycState>) => {
            state.fromCity = action.payload.fromCity !== undefined ? action.payload.fromCity : state.fromCity;
            state.fromCountyId = action.payload.fromCountyId !== undefined ? action.payload.fromCountyId : state.fromCountyId;
            state.fromCountyLabel = action.payload.fromCountyLabel !== undefined ? action.payload.fromCountyLabel : state.fromCountyLabel;
            state.socialSecurityNumber = action.payload.socialSecurityNumber !== undefined ? action.payload.socialSecurityNumber : state.socialSecurityNumber;
            state.birthName = action.payload.birthName !== undefined ? action.payload.birthName : state.birthName;
            state.profession = action.payload.profession !== undefined ? action.payload.profession : state.profession;
            state.paymentSupport = action.payload.paymentSupport !== undefined ? action.payload.paymentSupport : state.paymentSupport;
        },
        addBeneficiary: (state: KycState, action: PayloadAction<Beneficiary>) => {
            state.beneficiaries = [...state.beneficiaries!, action.payload];
        },
        removeBeneficiary: (state: KycState, action: PayloadAction<Beneficiary>) => {
            state.beneficiaries = state.beneficiaries?.filter((b) =>
                b.idWeb !== action.payload.idWeb
            );
        },
        updateBeneficiary: (state: KycState, action: PayloadAction<Beneficiary>) => {
            let beneficiaryUpdate: Beneficiary[];

            beneficiaryUpdate = state.beneficiaries!.filter(b => {
                    return (b.physicalFirstname !== action.payload.physicalFirstname &&
                            b.physicalLastname !== action.payload.physicalLastname)
                        || b.companyName !== action.payload.companyName;
                }
            );

            if (beneficiaryUpdate) {
                beneficiaryUpdate[0].id = action.payload.id;
                state.beneficiaries = state.beneficiaries?.filter((b) =>
                    b.id !== action.payload.id
                );

                state.beneficiaries = [...state.beneficiaries!, beneficiaryUpdate[0]];
            }
        },
        updatePayment: (state: KycState, action: PayloadAction<KycState>) => {
            state.iban = action.payload.iban !== undefined ? action.payload.iban : state.iban;
            state.bic = action.payload.bic !== undefined ? action.payload.bic : state.bic;
            state.debitDay = action.payload.debitDay !== undefined ? action.payload.debitDay : state.debitDay;
            state.isRecurringPayment = action.payload.isRecurringPayment !== undefined ? action.payload.isRecurringPayment : state.isRecurringPayment;
            state.periodicity = action.payload.periodicity !== '' ? action.payload.periodicity : state.periodicity;
            state.payerInformation = action.payload.payerInformation;
            state.agreementDebit = action.payload.agreementDebit !== undefined ? action.payload.agreementDebit : state.agreementDebit;
            state.isAccountDebit = action.payload.isAccountDebit !== undefined ? action.payload.isAccountDebit : state.isAccountDebit;
        },
        updateAgreementMembership: (state: KycState, action: PayloadAction<KycState>) => {
            state.agreementContract = action.payload.agreementContract !== undefined ? action.payload.agreementContract : state.agreementContract;
            state.agreementGC = action.payload.agreementGC !== undefined ? action.payload.agreementGC : state.agreementGC;
            state.agreementFormContract = action.payload.agreementFormContract !== undefined ? action.payload.agreementFormContract : state.agreementFormContract;
        },
        updateSendQuote: (state: KycState, action: PayloadAction<KycState>) => {
            state.sendQuote = action.payload.sendQuote !== undefined ? action.payload.sendQuote : state.sendQuote;
        },
        updateAccount: (state: KycState, action: PayloadAction<KycState>) => {
            state.idAccount = action.payload.idAccount !== undefined ? action.payload.idAccount : state.idAccount;
            state.idAccountSpouse = action.payload.idAccountSpouse !== undefined ? action.payload.idAccountSpouse : state.idAccountSpouse;
            state.membershipId = action.payload.membershipId !== undefined ? action.payload.membershipId : state.membershipId;
        },
        updateQuotationNrid: (state: KycState, action: PayloadAction<KycState>) => {
            state.quotationNrid = action.payload.quotationNrid !== undefined ? action.payload.quotationNrid : state.quotationNrid;
            state.quotationRef = action.payload.quotationRef !== undefined ? action.payload.quotationRef : state.quotationRef;
            state.folderGuidToQuotation = action.payload.folderGuidToQuotation !== undefined ? action.payload.folderGuidToQuotation : state.folderGuidToQuotation;
            state.guarantyName = action.payload.guarantyName !== undefined ? action.payload.guarantyName : state.guarantyName;
            state.creationDate = action.payload.creationDate !== undefined ? action.payload.creationDate : state.creationDate;
        },
        updateFileUpload: (state: KycState, action: PayloadAction<KycState>) => {
            state.quotationUpload = action.payload.quotationUpload !== undefined ? action.payload.quotationUpload : state.quotationUpload;
            state.idCardUpload = action.payload.idCardUpload !== undefined ? action.payload.idCardUpload : state.idCardUpload;
            state.ribUpload = action.payload.ribUpload !== undefined ? action.payload.ribUpload : state.ribUpload;
        },
    }
});

export const {
    updateDepartment,
    updateAverageCost,
    updateWhoIsConcerned,
    updateAgreement,
    updateRequirements,
    updateFinancial,
    updateIdentity,
    updateIdentityContact,
    updateIdentity3,
    updateIdentity4,
    updateIdentity5,
    addLifeInsuranceContract,
    addLifeInsuranceContractSpouse,
    removeLifeInsuranceContract,
    removeLifeInsuranceContractSpouse,
    updateAgreementResume,
    updateFamilySituation,
    updateFromOtherInfo,
    addBeneficiary,
    removeBeneficiary,
    updateBeneficiary,
    updatePayment,
    updateAgreementMembership,
    updateSendQuote,
    updateAccount,
    updateQuotationNrid,
    updateFileUpload,
} = kycSlice.actions;

export default kycSlice.reducer;

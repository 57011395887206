import { Button } from '@mui/material';
import React, { FunctionComponent, MouseEventHandler } from 'react';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import theme from '../theme';

type InfoProps = { handleAction?: MouseEventHandler; color?: string; size?: any };
const InfoIconWidget: FunctionComponent<InfoProps> = (props: InfoProps) => {

    return (
        <Button
            variant="text"
            sx={{
                color: props.color || theme.palette.info.light,
                alignItems: 'stretch',
                fontSize: 50,
            }}
            onClick={props.handleAction}
        ><InfoTwoToneIcon fontSize={props.size || 'medium'}/></Button>
    );
};

export default InfoIconWidget;




import { KycState } from '../features/kyc/kycSlice';
import { httpApiPost } from './http.js';

export const youSignFile = (kyc: KycState, nameFile: string) => async () => {
    const data = {
        'firstname': kyc.firstname?.toUpperCase(),
        'lastname': kyc.lastname?.toUpperCase(),
        'email': kyc.email,
        'phone': kyc.phoneNumber,
        'filename': nameFile,
        'guidEspaceProspect': kyc.folderGuidToQuotation,
        'folderGuidToQuotation': kyc.folderGuidToQuotation,
        'idAccount': kyc.idAccount,
        'quotationNrid': kyc.quotationNrid,
        'creationDate': kyc.creationDate,
    };

    return await httpApiPost('/you-sign', data);
};

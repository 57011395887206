import {
    Box,
    Step,
    StepConnector,
    stepConnectorClasses,
    StepIconProps,
    StepLabel,
    Stepper,
    styled,
    Typography
} from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { goto } from '../features/navigation/navigationSlice';
import ROUTES from '../routes';
import theme from '../theme';
import { KycState } from '../features/kyc/kycSlice';

const ColorlibConnector = styled(StepConnector)(() => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: '#333333',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: '#333333',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ownerState}) => ({
    backgroundColor: '#dddddd',
    zIndex: 1,
    color: theme.palette.text.primary,
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor: theme.palette.text.primary,
        borderColor: '#333333',
        borderWidth: 2
    }),
    ...(ownerState.completed && {
        backgroundColor: theme.palette.text.primary,
        borderColor: '#333333',
        borderWidth: 2
    }),
}));

type IconProps = { src: string; };
const IconByImageAsset: FunctionComponent<IconProps> = (props: IconProps) => {
    return (
        <span className="uk-icon uk-icon-image" style={{backgroundImage: `url(${props.src})`}}></span>
    );
};

function ColorlibStepIcon(props: StepIconProps) {
    const {active, completed, className} = props;

    const icons: { [index: string]: React.ReactElement } = {
        1: <IconByImageAsset src="/assets/pieces.png"/>,
        2: <IconByImageAsset src="/assets/check.png"/>,
        3: <IconByImageAsset src="/assets/dossier.png"/>,
        4: <IconByImageAsset src="/assets/identite.png"/>,
        5: <IconByImageAsset src="/assets/sablier.png"/>,
        6: <IconByImageAsset src="/assets/stat.png"/>,
        7: <IconByImageAsset src="/assets/dossier.png"/>
    };

    return (
        <ColorlibStepIconRoot ownerState={{completed, active}} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

const Navigation: FunctionComponent = () => {
    const kyc: KycState = useAppSelector((state) => state.kyc);
    const step: number = useAppSelector((state) => state.navigation.step);
    const dispatch = useAppDispatch();

    const handleContinue = (target: number) => {
        if (canINavigateTo(target)) {
            dispatch(goto(target));
        }
    };

    const canINavigateTo = (target: number) => {
        if (step !== 7 && target < step) {
            if (step < 5) {
                return true;
            } else if (step > 5 && target > 4 && !kyc.quotationUpload) {
                return true;
            }
        }

        return false;
    };

    return (
        <Box sx={{width: '100%', textAlign: 'left'}}>
            <Stepper alternativeLabel activeStep={step - 1} connector={<ColorlibConnector/>}>
                {ROUTES.map((route) => (
                    <Step key={route.id} onClick={() => handleContinue(route.id)}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}
                                   style={{cursor: (canINavigateTo(route.id)) ? 'pointer' : 'default'}}><Typography
                            sx={{color: 'black'}}>{route.category}</Typography></StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
};

export default Navigation;

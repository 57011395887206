import { httpApiPost } from './http.js';
import { KycState, PaymentSupport } from '../features/kyc/kycSlice';
import { Offer } from '../features/offers/offersSlice';
import { getDda } from './dda';
import { t } from 'i18next';
import { handleEnum } from './enum';
import { getAddress } from './person';

export const postQuotationThrift = (kyc: KycState, idAccount: number, offer?: Offer) => async () => {
    return httpApiPost(
        '/quotation/thrift/save',
        {
            'type': '2',
            'partenaireNrid': process.env.REACT_APP_CODE_PARTENAIRE_NRID,
            'prospectNrid': idAccount,
            'conjointNrid': kyc.idAccountSpouse ?? 0,
            'garantieId': offer?.id,
            'capital': kyc.capital,
            'courrierPhysique': kyc.sendQuote ? t('translation:placeholderForm.yes') : t('translation:placeholderForm.no'),
            'codeCotisation': offer?.contributionCode,
            'dda': getDda(kyc),
            'ddaConjoint': {},
            'isBest': true,
            'best': {},
            'fraisGestion': offer?.feesManagement,
            'fraisVersement': offer?.feePayment,
            'montantEcheance': offer?.monthlyFees,
            'nombreEcheance': offer?.duration,
            'premierVersement': offer?.firstPayment,
        }
    );
};

export const postQuotationInsurance = (kyc: KycState, idAccount: number, offer?: Offer) => async () => {
    return httpApiPost(
        '/quotation/foresight/save',
        {
            'type': '1',
            'partenaireNrid': process.env.REACT_APP_CODE_PARTENAIRE_NRID,
            'prospectNrid': idAccount,
            'conjointNrid': kyc.idAccountSpouse ?? 0,
            'garantieId': offer?.id,
            'capital': kyc.capital,
            'codeCotisation': offer?.contributionCode,
            'montantCotisation': offer?.monthlyFees,
            'typeCotisation': offer?.duration,
            'courrierPhysique': kyc.sendQuote ? t('translation:placeholderForm.yes') : t('translation:placeholderForm.no'),
            'dda': getDda(kyc),
            'ddaConjoint': {},
            'isBest': true,
            'best': {},
        }
    );
};

export const postLinkQuotationGuid = (quotationNrid: number) => async () => {
    return httpApiPost('/quotation/add-id', quotationNrid);
};

export const sendMailToClient = (kyc: KycState, quotationNrid: string, folderGuidToQuotation: string, idAccount: number) => async () => {
    await httpApiPost(
        '/invoice-mail',
        {
            'lastname': kyc.lastname?.toUpperCase(),
            'firstname': kyc.firstname?.toUpperCase(),
            'email': kyc.email,
            'idAccount': idAccount.toString(),
            'quotationNrid': quotationNrid.toString(),
            'folderGuidToQuotation': folderGuidToQuotation,
        }
    );
};

export const postQuotationToDA = (kyc: KycState) => async () => {
    await httpApiPost(
        '/quotation/to-da',
        {
            'personneNrid': kyc.idAccount,
            'codeTiers': process.env.REACT_APP_API_ID,
            'etablissementNrid': Number(process.env.REACT_APP_CODE_ESTABLISHMENT_NRID),
            'devisNRID': kyc.quotationNrid,
            'modePaiement': kyc.periodicity ? t(`translation:membership.5.periodicities.${kyc.periodicity}`) : null,
            'mutacNonBeneficiaire': kyc.paymentSupport === handleEnum(PaymentSupport, PaymentSupport.Mutac),
            'sepa': {
                'nomTitulaire': kyc.isAccountDebit ? kyc.lastname?.toUpperCase() : kyc.payerInformation?.lastname?.toUpperCase(),
                'prenomTitulaire': kyc.isAccountDebit ? kyc.firstname?.toUpperCase() : kyc.payerInformation?.firstname?.toUpperCase(),
                'adresse': {
                    'nomRue': kyc.isAccountDebit ? getAddress(kyc) : kyc.payerInformation?.address,
                    'cp': kyc.isAccountDebit ? kyc.address?.zipcode : kyc.payerInformation?.zipcode,
                    'ville': kyc.isAccountDebit ? kyc.address?.city : kyc.payerInformation?.city
                },
                'iban': kyc.iban,
                'bic': kyc.bic,
                'prelevement': kyc.debitDay,
            }
        }
    );
};

export const postSearchQuotation = (idAccount: number, quotationNrid: number) => async () => {
    return httpApiPost(
        '/quotation/search-by-nrid',
        {
            'personneNrid': idAccount,
            'etablissementNrid': Number(process.env.REACT_APP_CODE_ESTABLISHMENT_NRID),
            'devisNrid': quotationNrid,
            'dda': false,
        }
    );
};

export const postPaymentMail = (kyc: KycState) => async () => {
    const data = {
        'firstName': kyc.firstname?.toUpperCase(),
        'lastName': kyc.lastname?.toUpperCase(),
        'email': kyc.email,
    };

    return httpApiPost('/payment-mail', data);
};

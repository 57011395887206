import { Typography } from '@mui/material';
import React, { FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import { t } from 'i18next';
import theme from '../theme';

interface WidgetProps extends PropsWithChildren<any> {
    children: ReactNode,
    minWidth?: number,
    marginLeft?: number,
    color?: boolean,
    style?: object,
}

const H6: FunctionComponent<WidgetProps> = ({
                                                children,
                                                minWidth,
                                                marginLeft,
                                                color = false,
                                                style,
                                            }: WidgetProps) => {
    const styleH1 = {
        verticalAlign: 'center',
        minWidth: t('translation:errors.rem', {value: minWidth}) ?? '7rem',
        marginLeft: t('translation:errors.rem', {value: marginLeft}) ?? '',
        color: color ? theme.palette.text.secondary : theme.palette.primary.main,
    };
    const styleAll = Object.assign({}, styleH1, style ?? {fontSize: 30});

    return (
        <Typography
            sx={styleAll}
            variant="h6"
        >{children}</Typography>
    );
};

export default H6;




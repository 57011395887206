export type AddressType = {
    number?: string,
    street?: string,
    complement?: string,
    zipcode?: string,
    city?: string
};

export default class AddressService {
    static async getAddress(
        address: AddressType
    ) {

        const axios = require('axios').default;

        let urlAddress = 'https://api-adresse.data.gouv.fr/search/?q=';
        if (address.number !== undefined) {
            urlAddress += address.number;
            urlAddress += ' ';
        }
        if (address.street !== undefined) {
            urlAddress += address.street;
        }
        if (address.zipcode !== undefined) {
            urlAddress += `&postcode=${address.zipcode}`;
        }
        if (address.city !== undefined) {
            urlAddress += `&city=${address.city}`;
        }
        const response = await axios.get(urlAddress);
        if (response.status === 200 && response.data.features.length > 0) {
            return {
                number: response.data.features[0].properties.housenumber,
                street: response.data.features[0].properties.street ?? response.data.features[0].properties.name,
                zipcode: response.data.features[0].properties.postcode,
                city: response.data.features[0].properties.city
            };
        } else {
            return address;
        }
    }
}

import { KycState, updateAgreementMembership } from '../../features/kyc/kycSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { next } from '../../features/navigation/navigationSlice';
import H6 from '../H6';
import CheckboxWidget from '../CheckboxWidget';
import theme from '../../theme';
import { postQuotationToDA } from '../../utils/quotation';
import { getActiveOffer, Offer, OffersState } from '../../features/offers/offersSlice';

const FormAgreementMembership = () => {
    const kyc: KycState = useAppSelector((state) => state.kyc);
    const offers: OffersState = useAppSelector((state) => state.offers);
    const [initialeAgreementContract, setInitialeAgreementContract] = useState(false);
    const [initialeAgreementGC, setInitialeAgreementGC] = useState(false);
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const offer: Offer = getActiveOffer(offers);

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(postQuotationToDA(kyc));
        };
        dispatch(updateAgreementMembership({
            agreementContract: initialeAgreementContract,
            agreementGC: initialeAgreementGC,
        }));
        // call the function
        !kyc.agreementGC && !kyc.agreementContract &&
        fetchData()
            // make sure to catch any error
            .catch(console.error);

        if (initialeAgreementGC && initialeAgreementContract) {
            dispatch(next());
        }
    }, [kyc.agreementGC, kyc.agreementContract, kyc.agreementDebit, next]);


    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 25,
            color: theme.typography.caption,
            marginTop: 20,
        }}
        >
            <CheckboxWidget
                id="agreementContract"
                onClick={() => {
                    setInitialeAgreementContract(!kyc.agreementContract);
                    dispatch(updateAgreementMembership({agreementContract: !kyc.agreementContract}));
                }}
                value={initialeAgreementContract}
            >
                <H6>{t('translation:membership.6.agreementContract', {value: offer ? offer.name : kyc.guarantyName})}</H6>
            </CheckboxWidget>
            <CheckboxWidget
                id="agreementGC"
                onClick={() => {
                    setInitialeAgreementGC(!kyc.agreementGC);
                    dispatch(updateAgreementMembership({agreementGC: !kyc.agreementGC}));
                }}
                value={initialeAgreementGC}
            >
                <H6>{t('translation:membership.6.agreementGC')}</H6>
            </CheckboxWidget>
        </Box>
    );
};

export default FormAgreementMembership;
import React, { FunctionComponent } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { KycState, Payment, } from '../../features/kyc/kycSlice';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { next } from '../../features/navigation/navigationSlice';
import H6 from '../H6';
import TextFieldWidget from '../TextFieldWidget';
import ButtonWidget from '../ButtonWidget';
import * as Yup from 'yup';
import { getActiveOffer, OffersState, updateOffer } from '../../features/offers/offersSlice';
import { useFormik } from 'formik';
import { postPersonalizationSavings } from '../../utils/proposition';
import { Typography } from '@mui/material';
import { getValueByKey } from '../../utils/enum';

const ThriftChoice: FunctionComponent = () => {
    const kyc: KycState = useAppSelector((state) => state.kyc);
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const offers: OffersState = useAppSelector((state) => state.offers);
    const offer = getActiveOffer(offers);
    const isSinglePayment = getValueByKey(Payment, kyc.payment) === Payment.Single;

    const validationSchema = Yup.object().shape({
        isSinglePayment: Yup.bool()
            .required(t('translation:errors.required')).nullable(),
        firstPayment: Yup.number()
            .when('isSinglePayment', {
                is: false,
                then: Yup.number()
                    .required(t('translation:errors.required')).nullable()
                    .min(12.09, t('translation:errors.firstPayment', {
                        value: 12.09
                    })),
            })
    });

    const formik = useFormik({
        initialValues: {
            isSinglePayment: isSinglePayment,
            firstPayment: offer?.firstPayment?.toString() ?? '12.09',
            monthlyPayment: offer?.monthlyFees?.toString(),
        },
        validationSchema,
        onSubmit: async (values) => {
            if (!values.isSinglePayment) {
                await handleFirstPayment(values.firstPayment);
            }
            dispatch(next());
        }
    });

    const handleFirstPayment = async (firstPayment: string) => {
        const response = await dispatch(postPersonalizationSavings(kyc, offer, firstPayment));
        const newOffers = response.data;
        newOffers.forEach((offer: any) => {
            dispatch(updateOffer(offer));
        });

        // @ts-ignore
        const newOffer = newOffers.find((o) => o.garantieId === offer.id);
        formik.setFieldValue('monthlyPayment', newOffer.montantEcheance);

        return true;
    };

    return (
        <Box mt={1} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Typography
                variant="caption"
                sx={{textAlign: 'center', fontSize: 25, margin: 'auto', lineHeight: 1.4}}
                dangerouslySetInnerHTML={{__html: t('translation:identity.20.p1')}}
            />
            <Typography
                variant="caption"
                sx={{textAlign: 'center', fontSize: 35, margin: 'auto', lineHeight: 1.4, marginY: '36px'}}
                dangerouslySetInnerHTML={{__html: t('translation:identity.20.p2')}}
            />

            <form onSubmit={formik.handleSubmit}>
                {isSinglePayment ? (
                    <>
                        <H6>{t('translation:identity.20.single-payment')}</H6>
                        <Box>
                            <H6>{t('translation:identity.20.value-single-payment')}</H6>
                            <TextFieldWidget
                                id="single-payment"
                                onChange={() => {
                                }}
                                value={formik.values.firstPayment}
                                helperText={formik.errors.firstPayment}
                                disabled
                            />
                        </Box>
                    </>
                ) : (
                    <>
                        <H6>{t('translation:identity.20.monthly-payment')}</H6>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <H6>{t('translation:identity.20.value-first-payment')}</H6>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <TextFieldWidget
                                    id="firstPayment"
                                    onChange={formik.handleChange}
                                    value={formik.values.firstPayment}
                                    helperText={formik.errors.firstPayment}
                                />
                                <ButtonWidget
                                    mx={2}
                                    label={t('translation:identity.20.update-first-payment')}
                                    handleAction={() => handleFirstPayment(formik.values.firstPayment)}
                                />
                            </Box>
                            <H6>
                                {t('translation:identity.20.value-monthly-payment', {
                                    month: kyc.durationNumber
                                })}
                            </H6>
                            <TextFieldWidget
                                id="monthlyPayment"
                                onChange={formik.handleChange}
                                value={formik.values.monthlyPayment}
                                helperText={formik.errors.monthlyPayment}
                                disabled
                            />
                        </Box>
                    </>
                )}

                <Box mt={2}>
                    <ButtonWidget
                        my={2}
                        label={t('continue')}
                        type="submit" py={1}
                        mt={isSinglePayment ? 25 : 2}
                    />
                </Box>
            </form>
        </Box>
    );
};

export default ThriftChoice;

import { configureStore } from "@reduxjs/toolkit";
import kycReducer from "../features/kyc/kycSlice";
import navigationReducer from "../features/navigation/navigationSlice";
import offersReducer from "../features/offers/offersSlice";

const store = configureStore({
    reducer: {
        kyc: kycReducer,
        navigation: navigationReducer,
        offers: offersReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export default store;
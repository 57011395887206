import React, { FunctionComponent } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { InsuranceKnowledge, KycState } from '../../features/kyc/kycSlice';
import { useTranslation } from 'react-i18next';
import IdentityForm from './IdentityForm';
import Titlebar from '../Titlebar';
import Identity4Form from './Identity4Form';
import Identity5Form from './Identity5Form';
import OffersList from './OffersList';
import SendQuote from './SendQuote';
import AgreementResume from './AgreementResume';
import { gotoStepAndSubStep, previous, previousWithSkip } from '../../features/navigation/navigationSlice';
import { getValueByKey } from '../../utils/enum';
import ThriftChoice from './ThriftChoice';
import { getActiveOffer, OffersState } from '../../features/offers/offersSlice';
import FinancialForm from './FinancialForm';
import H6 from '../H6';

const IdentityPage: FunctionComponent = () => {
    const {subStep} = useAppSelector((state) => state.navigation);
    const kyc: KycState = useAppSelector((state) => state.kyc);
    const offers: OffersState = useAppSelector((state) => state.offers);
    const offer = getActiveOffer(offers);
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const previousIdentityForm = () => {
        dispatch(gotoStepAndSubStep({step: 2, subStep: 3}));
    };

    const previousOfferList = () => {
        if (getValueByKey(InsuranceKnowledge, kyc.lifeInsuranceOwned) !== InsuranceKnowledge.Yes) {
            dispatch(previousWithSkip());
        } else {
            dispatch(previous());
        }
    };

    const previousAgreementResume = () => {
        if (offer.isThrift) {
            dispatch(previous());
        } else {
            dispatch(previousWithSkip());
        }
    };

    return (
        <div style={{margin: 20}}>
            {/***************************************************************************************************************** */}
            {subStep === 1 &&
              <div>
                <Titlebar
                  style={{marginTop: 0, marginBottom: 4}}
                  text={t('translation:identity.1.title')}
                  handleAction={previousIdentityForm}
                />
                <IdentityForm isUser={true}/>
              </div>
            }
            {/*********************************************************** ****************************************************** */}
            {subStep === 2 &&
              <div>
                <Titlebar style={{marginBottom: 13}} text={t('translation:financial.1.title')}/>
                <Identity4Form isUser={true}/>
              </div>
            }
            {/*********************************************************** ****************************************************** */}
            {subStep === 3 && kyc.lifeInsuranceOwned &&
              <div>
                <Titlebar text={t('translation:financial.1.title')}
                          handleAction={gotoStepAndSubStep({step: 4, subStep: 2})}/>
                <Identity5Form isUser={true}/>
              </div>
            }
            {/*********************************************************** ****************************************************** */}
            {subStep === 4 && kyc.lifeInsuranceOwned &&
              <div>
                <Titlebar text={t('translation:financial.1.title')} handleAction={previousOfferList}/>
                <FinancialForm/>
              </div>
            }
            {/*********************************************************** ****************************************************** */}
            {subStep === 5 &&
              <div>
                <Titlebar
                  text={t('translation:identity.8.title')}/>
                  {offers.offers.length === 1 && offers.offers[0].name === 'Mutac Epargne Obsèques' &&
                    <H6 style={{
                        textAlign: 'center',
                        marginLeft: '25rem',
                        marginRight: '25rem'
                    }}>{t('translation:identity.8.subtitle')}</H6>
                  }
                <OffersList/>
              </div>
            }
            {/*********************************************************** ****************************************************** */}
            {subStep === 6 &&
              <div>
                <Titlebar text={''}/>
                <ThriftChoice/>
              </div>
            }
            {/*********************************************************** ****************************************************** */}
            {subStep === 7 &&
              <div>
                <Titlebar style={{marginBottom: 15}} text={t('translation:identity.9.title')}
                          handleAction={previousAgreementResume}/>
                <AgreementResume/>
              </div>
            }
            {/*********************************************************** ****************************************************** */}
            {subStep === 8 &&
              <div>
                <Titlebar text={''}/>
                <SendQuote/>
              </div>
            }
        </div>
    );
};

export default IdentityPage;

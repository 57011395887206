import { httpApiPost } from './http.js';
import { KycState, Payment } from '../features/kyc/kycSlice';
import { formatDate, getDda } from './dda';
import { Offer } from '../features/offers/offersSlice';
import { getValueByKey } from './enum';

export const postPropositionWithDDA = (kyc: KycState) => async () => {
    return httpApiPost(
        '/proposition',
        {
            'capital': kyc.capital,
            'mensualite': null,
            'nombreMois': getValueByKey(Payment, kyc.payment) === Payment.Single ? 1 : 60,
            'montantPremierVersement': null,
            'frais': false,
            'dateNaissance': formatDate(kyc.birthDate),
            'dda': getDda(kyc),
            'isCuratelle': false,
            'isTutelle': false,
            'isDuo': false,
            'isCoupleGarantieCommune': false,
            'capitalConjoint': null,
            'mensualiteConjoint': null,
            'nombreMoisConjoint': null,
            'montantPremierVersementConjoint': null,
            'fraisConjoint': null,
            'dateNaissanceConjoint': kyc.birthDateSpouse ? formatDate(kyc.birthDateSpouse) : null,
            'ddaConjoint': null,
            'etablissementConcerne': process.env.REACT_APP_CODE_ESTABLISHMENT_NRID,
        }
    );
};

export const postPersonalizationSavings = (kyc: KycState, offer: Offer, firstPayment: any) => async () => {
    return httpApiPost(
        '/proposal/personalization-savings',
        {
            'capital': kyc.capital,
            'nombreVersement': offer.duration,
            'montantPremierVersement': firstPayment ?? offer.firstPayment,
            'frais': false,
            'dateNaissance': formatDate(kyc.birthDate),
            'codePromo': ''
        }
    );
};

import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { addOffer, Offer, resetOffers, selectOffer } from '../../features/offers/offersSlice';
import theme from '../../theme';
import ButtonContinue from '../ButtonContinue';
import { KycState, Payment } from '../../features/kyc/kycSlice';
import { postPropositionWithDDA } from '../../utils/proposition';
import Loader from '../Loader';
import { AxiosResponse } from 'axios';
import ButtonLinkMutac from '../ButtonLinkMutac';
import { useTranslation } from 'react-i18next';

const OffersList: FunctionComponent = () => {
    const kyc: KycState = useAppSelector((state) => state.kyc);
    const {offers, selectedOfferId, selectedContributionId} = useAppSelector((state) => state.offers);
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(resetOffers());

        const fetchData = async () => {
            const resp = await dispatch(postPropositionWithDDA(kyc));
            if (resp) {
                await UpdateOffers(resp);
            }

            setLoading(false);
        };

        // call the function
        fetchData()
            // make sure to catch any error
            .catch(console.error);
    }, []);

    const UpdateOffers = async (resp: AxiosResponse) => {
        if (resp.data.Prospect[0].epargne) {
            for (const epArray of resp.data.Prospect[0].epargne) {
                for (const ep of epArray) {
                    const offer: Offer = {
                        id: ep.garantieId,
                        isThrift: true,
                        name: ep.garantie,
                        capital: ep.capital,
                        duration: ep.nombreEcheances > 1 ? ep.nombreEcheances : 0,
                        monthlyFees: ep.montantEcheance,
                        isPromote: ep.enAvant,
                        feePayment: ep.montantFraisVersement,
                        feesManagement: ep.montantFraisGestion,
                        firstPayment: ep.premierVersement,
                    };

                    if (ep.enAvant) {
                        await dispatch(selectOffer({selectedOfferId: ep.garantieId}));
                    }
                    await dispatch(addOffer(offer));
                }
            }
        }

        if (resp.data.Prospect[0].prevoyance) {
            for (const prArray of resp.data.Prospect[0].prevoyance) {
                for (const pr of prArray) {
                    const offer: Offer = {
                        id: pr.garantieId,
                        isThrift: false,
                        name: pr.garantie,
                        capital: pr.capital,
                        duration: pr.typeCotisation,
                        monthlyFees: pr.montantCotisation,
                        isPromote: pr.enAvant,
                        feePayment: pr.montantFraisVersement,
                        feesManagement: pr.montantFraisGestion,
                        firstPayment: pr.premierVersement,
                        contributionCode: pr.codeCotisation,
                        typeContributionId: pr.typeCotisationId,
                    };

                    if (pr.enAvant) {
                        await dispatch(selectOffer({
                            selectedOfferId: pr.garantieId,
                            selectedContributionId: pr.typeCotisationId,
                        }));
                    }
                    await dispatch(addOffer(offer));
                }
            }
        }
    };

    return (
        <>
            {offers.length === 0 ?
                (
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '5rem'}}>
                        {loading ?
                            (
                                <Loader size={'10rem'} color={'light'}/>
                            ) : (
                                <Box>
                                    <Typography
                                        id="no-proposition"
                                        sx={{
                                            mt: 2,
                                            textAlign: 'center',
                                            fontSize: '1.5rem',
                                            color: theme.palette.primary.main
                                        }}
                                        dangerouslySetInnerHTML={{__html: t('translation:errors.no-proposition')}}
                                    />
                                    <ButtonLinkMutac
                                        label={t('translation:button-rdv')}
                                        url={t('translation:url-mutac')}
                                    />
                                </Box>
                            )}
                    </Box>
                ) : (
                    <Box mx="auto"
                         sx={{
                             marginTop: 5,
                             justifyContent: 'center',
                             display: 'flex',
                             alignItems: 'center',
                             flexWrap: 'wrap',
                         }}
                    >
                        {offers.map((o, k) =>
                            <Card sx={{
                                mx: 3,
                                my: 3,
                                minWidth: 300,
                                opacity: (o.id === selectedOfferId && o.typeContributionId === selectedContributionId) ? 1 : 0.4,
                                transition: 'all 0.5s'
                            }}
                                  key={k}
                                  onClick={() => {
                                      dispatch(selectOffer({
                                          selectedOfferId: o.id,
                                          selectedContributionId: o.typeContributionId
                                      }));
                                  }}
                                  style={{cursor: 'pointer'}}
                            >
                                <CardHeader title={o.name}/>
                                <CardContent sx={{justifyContent: 'center'}}>
                                    <Box>
                                        <div>{t('translation:identity.10.capital')}</div>
                                        <div>
                                            <img width="7%" src="assets/pieces.png" alt="capital"/>
                                            <span style={{
                                                fontSize: '1.5rem',
                                                fontWeight: 'bold',
                                                marginLeft: '6px',
                                                color: theme.palette.text.primary
                                            }}
                                            >
                                                    {`${Intl.NumberFormat().format(o.capital)} €`}
                                                </span>
                                        </div>
                                    </Box>
                                    {!isNaN(+o.duration) &&
                                      <Box>
                                          {o.isThrift ? (
                                              <Typography
                                                  sx={{
                                                      verticalAlign: 'center',
                                                      mt: 2,
                                                      px: 1,
                                                      py: 1,
                                                      fontWeight: 'bold',
                                                      color: theme.palette.text.secondary,
                                                  }}
                                                  dangerouslySetInnerHTML={{__html: t(`translation:identity.20.${o.monthlyFees > 0 ? 'sixtyMonth' : 'single-payment'}`)}}
                                              />
                                          ) : (
                                              <>
                                                  <div>{t('translation:identity.10.duration')}</div>
                                                  <div>
                                                      <img width="7%" src="assets/sablier.png" alt="sablier"/>

                                                      <span style={{
                                                          fontSize: '1.5rem',
                                                          fontWeight: 'bold',
                                                          marginLeft: '6px',
                                                          color: theme.palette.text.primary
                                                      }}
                                                      >
                                              <>
                                                  {t('translation:requirements.4.form.q4.years', {value: kyc.payment === Payment[29] ? o.duration : o.duration / 12})}
                                              </>
                                                </span>
                                                  </div>
                                              </>
                                          )}
                                      </Box>
                                    }
                                    {isNaN(+o.duration) &&
                                      <Box>
                                        <div>{t('translation:identity.10.duration')}</div>
                                        <div>
                                          <img width="7%" src="assets/sablier.png" alt="sablier"/>
                                          <span style={{
                                              fontSize: '1.5rem',
                                              fontWeight: 'bold',
                                              marginLeft: '6px',
                                              color: theme.palette.text.primary
                                          }}
                                          >
                                                            {o.duration}
                                                        </span>
                                        </div>
                                      </Box>
                                    }

                                    {o.monthlyFees < 1000 && !o.isThrift ?
                                        (
                                            <Box>
                                                <div>{t('translation:identity.10.membership-fee')}</div>
                                                <div>
                                                    <img width="7%" src="assets/stat.png" alt="cotisation"/>
                                                    <span style={{
                                                        fontSize: '1.5rem',
                                                        fontWeight: 'bold',
                                                        marginLeft: '6px',
                                                        color: theme.palette.text.primary
                                                    }}
                                                    >
                                                  {`${o.monthlyFees} €`}
                                              </span>
                                                    <sub>{t('translation:identity.10.by-month')}</sub>
                                                </div>
                                            </Box>
                                        ) : (!o.isThrift &&
                                        <Box>
                                          <div>{t('translation:identity.10.membership-fee')}</div>
                                          <div>
                                            <img width="7%" src="assets/stat.png" alt="cotisation"/>
                                            <span style={{
                                                fontSize: '1.5rem',
                                                fontWeight: 'bold',
                                                marginLeft: '6px',
                                                color: theme.palette.text.primary
                                            }}
                                            >
                                                            {`${o.monthlyFees} €`}
                                                        </span>
                                          </div>
                                        </Box>
                                        )
                                    }
                                    {o.isPromote &&
                                      <Typography sx={{
                                          verticalAlign: 'center',
                                          mt: 2,
                                          px: 1,
                                          py: 1,
                                          fontWeight: 'bold',
                                          color: theme.palette.text.secondary,
                                      }}>{t('translation:identity.recommendedOffer')}
                                      </Typography>
                                    }
                                    {o.id === selectedOfferId && o.typeContributionId === selectedContributionId &&
                                      <ButtonContinue fs={14} blueLight my={1} skipNextStep={!o.isThrift}/>
                                    }
                                </CardContent>
                            </Card>
                        )
                        }
                    </Box>
                )
            }
        </>
    );
};

export default OffersList;

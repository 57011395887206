import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ROUTES from '../../routes';

export interface NavigationState {
    step: number;
    subStep: number;
}

const initialState: NavigationState = {
    step: 1,
    subStep: 1,
};

const forbiddensubStepOnPrevious: NavigationState[] = [
    {step: 2, subStep: 2},
    {step: 2, subStep: 4},
    {step: 4, subStep: 2},
];

const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        next: (state: NavigationState) => {
            const maxSubStep = ROUTES.filter((r) => r.id === state.step)[0].steps.length;
            if (state.subStep < maxSubStep) {
                state.subStep++;
            } else {
                state.step += 1;
                state.subStep = 1;
            }
        },
        previous: (state: NavigationState) => {
            let ended: boolean = false;
            while (!ended){
                if (state.subStep === 1 && state.step > 1) {
                    state.step--;
                    state.subStep = ROUTES.filter((r) => r.id === state.step)[0].steps.length;
                } else if (state.subStep > 1) {
                    state.subStep--;
                }
                ended = (forbiddensubStepOnPrevious.findIndex((n) => n.step === state.step && n.subStep === state.subStep) < 0);
            }
        },
        previousWithSkip: (state: NavigationState) => {
            for (let i = 1; i <= 2; i++) {
                if (state.subStep === 1 && state.step > 1) {
                    state.step--;
                    state.subStep = ROUTES.filter((r) => r.id === state.step)[0].steps.length;
                } else if (state.subStep > 1) {
                    state.subStep--;
                }
            }
        },
        goto: (state: NavigationState, action: PayloadAction<number>) => {
            state.step = action.payload;
            state.subStep = 1;
        },
        nextWithSkip: (state: NavigationState) => {
            for (let i = 1; i <= 2; i++) {
                const maxSubStep = ROUTES.filter((r) => r.id === state.step)[0].steps.length;
                if (state.subStep < maxSubStep) {
                    state.subStep++;
                } else {
                    state.step += 1;
                    state.subStep = 1;
                }
            }
        },
        gotoStepAndSubStep: (state: NavigationState, action: PayloadAction<NavigationState>) => {
            state.step = action.payload.step;
            state.subStep = action.payload.subStep;
        },
    }
});

export const {next, previous, goto, nextWithSkip, previousWithSkip, gotoStepAndSubStep} = navigationSlice.actions;
export default navigationSlice.reducer;

import { Box, styled } from '@mui/material';
import React, { FunctionComponent, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Cnracl, ElectedMandat, KycState, updateIdentity3, updateIdentity5, } from '../../features/kyc/kycSlice';
import ButtonWidget from '../ButtonWidget';
import InfoIconWidget from '../InfoIconWidget';
import Typography from '@mui/material/Typography';
import CustomModal from '../CustomModal';
import { useTranslation } from 'react-i18next';
import theme from '../../theme';
import ButtonContinue from '../ButtonContinue';
import SelectWidget from '../SelectWidget';
import H6 from '../H6';
import { handleEnum, handleValue } from '../../utils/enum';

const BoxFormat = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    marginLeft: '11.5rem',
    color: theme.typography.caption.color,
});

const FunctionAbroad: FunctionComponent = () => {
    const kyc: KycState = useAppSelector((state) => state.kyc);
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const isUser = true;
    const var1 = isUser ? 11 : 17;
    const var2 = isUser ? 5 : 16;

    /* Help Modal : local state management */
    const [open, setOpen] = useState<number>();
    const handleModal = (index: number) => setOpen(open === index ? undefined : index);

    const q1: string[] = [t('translation:identity.2.form.q4.choice1'), t('translation:identity.2.form.q4.choice2')];
    const yesOrNoValues: string[] = [t('translation:identity.2.form.q4.choice1'), t('translation:identity.2.form.q4.choice2')];

    const electedMandateWillDispatch = (value: string): object => {
        return isUser ? {electedMandate: value} : {electedMandateSpouse: value};
    };

    const electedMandate = (): string | undefined => {
        return isUser ? kyc.electedMandate : kyc.electedMandateSpouse;
    };

    const CNRACLRetiredWillDispatch = (value: string): object => {
        return isUser ? {CNRACLRetired: value} : {CNRACLRetiredSpouse: value};
    };

    const CNRACLRetired = (): string | undefined => {
        return isUser ? kyc.CNRACLRetired : kyc.CNRACLRetiredSpouse;
    };

    return (
        <div>
            <BoxFormat>
                <InfoIconWidget size="large" handleAction={() => handleModal(1)}/>
                <H6>{t(`translation:identity.${var2}.form.q1.label`)}</H6>
                <SelectWidget
                    id={'CNRACLRetired'}
                    value={handleValue(Cnracl, CNRACLRetired()) || null}
                    onChange={(event) => {
                        dispatch(updateIdentity3(CNRACLRetiredWillDispatch(handleEnum(Cnracl, event.target.value))));
                    }}
                    object={yesOrNoValues}
                />
            </BoxFormat>
            <BoxFormat my={2} sx={{marginRight: 15}}>
                <InfoIconWidget size="large" handleAction={() => handleModal(2)}/>
                <Typography
                    variant="h6"
                    dangerouslySetInnerHTML={{__html: t(`translation:identity.${var1}.q1`)}}
                />
                <SelectWidget
                    id={'electedMandate'}
                    value={handleValue(ElectedMandat, electedMandate()) || ''}
                    onChange={(event) => {
                        dispatch(updateIdentity5(electedMandateWillDispatch(handleEnum(ElectedMandat, event.target.value))));
                    }}
                    object={q1}
                />
            </BoxFormat>

            {electedMandate() !== undefined &&
                CNRACLRetired() !== undefined &&
              <ButtonContinue mt={32} my={3}/>
            }

            <CustomModal open={open !== undefined} handleModal={() => setOpen(undefined)}>
                {open === 1 &&
                  <div>
                    <Typography
                      id="modal-modal-description"
                      sx={{mt: 4, textAlign: 'center', fontSize: '1.54rem', color: theme.palette.text.secondary}}
                      dangerouslySetInnerHTML={{__html: t('translation:identity.5.form.q1.modal.p')}}
                    />
                    <ButtonWidget
                      label={t('translation:identity.5.form.q1.modal.button')}
                      handleAction={() => setOpen(undefined)}
                      py={0.1}
                      fontSize={1.2}
                      blueLight
                    />
                  </div>
                }
                {open === 2 &&
                  <div>
                    <Typography
                      id="modal-modal-description"
                      sx={{mt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}
                      dangerouslySetInnerHTML={{__html: t('translation:identity.11.modal')}}
                    />
                    <ButtonWidget
                      label={t('translation:identity.5.form.q1.modal.button')}
                      handleAction={() => setOpen(undefined)}
                      py={0.1}
                      fontSize={1.2}
                      blueLight
                    />
                  </div>
                }
            </CustomModal>
        </div>
    );
};

export default FunctionAbroad;

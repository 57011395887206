import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './app/store';
import App from './App';
import './i18n';
import theme from './theme';
import { ThemeProvider } from '@emotion/react';
import Loader from './components/Loader';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <Suspense fallback={<Loader />}>
                    <App />
                </Suspense>
            </ThemeProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
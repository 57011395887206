import { FormControl, FormHelperText, TextField } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import frLocale from 'date-fns/locale/fr';
import theme from '../theme';

type WidgetProps = {
    minWidth?: number,
    value?: any,
    helperText?: string,
    onChange: (value: any, keyboardInputValue?: string | undefined) => void,
    disabled?: boolean,
    maxDate?: Date,
};

const Datefield: FunctionComponent<WidgetProps> = (props: WidgetProps) => {
    return (
        <FormControl
            sx={{
                ml: 2,
                minWidth: props.minWidth || 200,
                input: {
                    color: theme.typography.caption.color,
                    fontSize: theme.typography.h6.fontSize,
                    py: '11px',
                    boxShadow: '0px 2px 1px rgba(255, 255, 255, 0.5)'
                }
            }}
        >
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
                <DatePicker
                    openTo="year"
                    views={['year', 'month', 'day']}
                    inputFormat="dd/MM/yyyy"
                    value={props.value || null}
                    onChange={props.onChange}
                    renderInput={(params) => <TextField {...params} />}
                    disabled={props.disabled}
                    maxDate={props.maxDate ? props.maxDate : new Date()}
                />
                <FormHelperText>
                    {props.helperText}
                </FormHelperText>
            </LocalizationProvider>
        </FormControl>
    );
};

export default Datefield;

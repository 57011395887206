import React, { FunctionComponent, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import theme from '../../theme';
import CheckboxWidget from '../CheckboxWidget';
import { KycState, updateAgreementResume } from '../../features/kyc/kycSlice';
import H6 from '../H6';
import CustomModal from '../CustomModal';
import Typography from '@mui/material/Typography';
import ButtonWidget from '../ButtonWidget';
import { next } from '../../features/navigation/navigationSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useTranslation } from 'react-i18next';

const AgreementResume: FunctionComponent = () => {
    const [open, setOpen] = useState(false);
    const dispatch = useAppDispatch();
    const [initialeAgreementResume1, setInitialeAgreementResume1] = useState(false);
    const [initialeAgreementResume2, setInitialeAgreementResume2] = useState(false);
    const kyc: KycState = useAppSelector((state) => state.kyc);
    const {t} = useTranslation();

    const handleModal = () => setOpen(!open);

    useEffect(() => {
        dispatch(updateAgreementResume({
            agreementResume1: initialeAgreementResume1,
            agreementResume2: initialeAgreementResume2,
        }));

        if (initialeAgreementResume1 && initialeAgreementResume2) {
            dispatch(next());
        }
    }, [kyc.agreementResume1, kyc.agreementResume2]);

    return (
        <>
            <Box width={'70%'} sx={{margin: 'auto'}}>
                <Box display="flex" alignItems="center" mb={5} style={{color: theme.typography.caption.color}}>
                    <CheckboxWidget id="agreementResume1" onClick={() => {
                        setInitialeAgreementResume1(!kyc.agreementResume1);
                        dispatch(updateAgreementResume({agreementResume1: !kyc.agreementResume1}));
                    }} value={kyc.agreementResume1}/>
                    <H6>{t('translation:identity.9.p1')}</H6>
                </Box>
                <Box display="flex" alignItems="center" style={{color: theme.typography.caption.color}}>
                    <CheckboxWidget id="agreementResume2" onClick={() => {
                        setInitialeAgreementResume2(!kyc.agreementResume2);
                        dispatch(updateAgreementResume({agreementResume2: !kyc.agreementResume2}));
                    }} value={kyc.agreementResume2}/>
                    <H6>{t('translation:identity.9.p2')}
                        <a style={{color: theme.palette.primary.main, textDecoration: 'underline'}}
                           onClick={handleModal}>{t('translation:identity.9.link')}</a>
                    </H6>
                </Box>
            </Box>
            <CustomModal open={open} handleModal={() => setOpen(!open)}>
                <div>
                    <Typography
                        id="modal-modal-description"
                        sx={{mt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}
                        dangerouslySetInnerHTML={{__html: t('translation:identity.9.modal')}}
                    />
                    <ButtonWidget
                        label={t('translation:identity.5.form.q1.modal.button')}
                        handleAction={() => setOpen(!open)}
                        py={0.1}
                        fontSize={1.2}
                        blueLight
                    />
                </div>
            </CustomModal>
        </>
    );
};
export default AgreementResume;
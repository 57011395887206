import { FormControl, FormHelperText, MenuItem, Select } from '@mui/material';
import React, { FunctionComponent, useEffect, useState } from 'react';
import DepartmentService from '../utils/department-service';
import Department from '../models/department';

type CountySelectProps = {
    index?: number,
    handleAction: any,
    value: string,
    type?: string,
    name: string,
    active?: boolean,
    helperText?: string,
};

const CountySelect: FunctionComponent<CountySelectProps> = (props: CountySelectProps) => {
    const [counties, setCounties] = useState(new Array<Department>);

    useEffect(() => {
        let countiesTemp = new Array<Department>;

        if (props.active) {
            DepartmentService.getDepartments().forEach(d => {
                if (d.active) {
                    countiesTemp.push(d);
                }
            });
            setCounties(countiesTemp);
        } else {
            setCounties(DepartmentService.getDepartments());
        }
    }, []);

    return (
        <FormControl>
            <Select
                name={props.name}
                id={props.name}
                onChange={event => props.handleAction(event, props?.index, props?.type)}
                value={props.value}
                sx={{
                    ml: 1.5,
                    height: '3.5rem',
                    minWidth: '6rem',
                }}
            >
                {counties.map((d) =>
                    <MenuItem
                        id={d.id.toString()}
                        value={`${d.id} - ${d.name}`}
                        key={d.id}
                    >
                        {d.id} - {d.name}
                    </MenuItem>)}
            </Select>
            <FormHelperText>
                {props.helperText}
            </FormHelperText>
        </FormControl>
    );
};

export default CountySelect;

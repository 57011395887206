// @ts-nocheck
import { useTranslation } from 'react-i18next';
import React, { FunctionComponent, useEffect } from 'react';
import Loader from '../Loader';
import { postSearchPersonByNrid } from '../../utils/person';
import { postSearchQuotation } from '../../utils/quotation';
import {
    addBeneficiary,
    Beneficiary,
    Civility,
    Dependent,
    FamilySituation,
    FamilySituationMutac,
    updateAccount,
    updateAgreementMembership,
    updateAverageCost,
    updateDepartment,
    updateFamilySituation,
    updateFileUpload,
    updateFromOtherInfo,
    updateIdentity,
    updateIdentity5,
    updateIdentityContact,
    updatePayment,
    updateQuotationNrid,
} from '../../features/kyc/kycSlice';
import { useAppDispatch } from '../../app/hooks';
import { Box, styled } from '@mui/material';
import { gotoStepAndSubStep, next, nextWithSkip } from '../../features/navigation/navigationSlice';
import { addOffer, Offer, selectOffer } from '../../features/offers/offersSlice';
import { getNameOfCounty } from '../../utils/department-service';
import CostService from '../../utils/cost-service';
import theme from '../../theme';
import { getBeneficiaries, serializeBeneficiary } from '../../utils/beneficiaries';
import { postGetFileList } from '../../utils/files';
import { getValueByKey } from '../../utils/enum';

const BoxFormat = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    color: theme.typography.caption.color,
    marginTop: '0.625rem',
    marginBottom: '0.625rem',
    flexDirection: 'column',
    marginTop: '4rem',
    textAlign: 'center',
});

const AuthByURL: FunctionComponent = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token') ?? '';
    const county = urlParams.get('departement');
    let goStep = 5;
    let goSubStep = 1;
    let quotationUpload: boolean = false;
    let idCardUpload: boolean = false;
    let ribUpload: boolean = false;

    useEffect(() => {
        if (!token && !county) {
            dispatch(next());
        }

        if (county) {
            const countyLabel = getNameOfCounty(county.toString());

            dispatch(updateDepartment({
                departmentId: county,
                departmentLabel: countyLabel,
            }));
            dispatch(updateAverageCost(CostService.getCost(county)));
            dispatch(nextWithSkip());
        }

        if (token) {
            let tokenDecode = atob(token);
            const ids = tokenDecode.split('+');

            const fetchData = async () => {
                const respFile = await dispatch(postGetFileList(ids[2]));
                if (respFile) {
                    respFile.data.map(id => {
                        switch (id){
                            case 4:
                                quotationUpload = true;
                                dispatch(updateFileUpload({quotationUpload: quotationUpload}));
                                break;
                            case 5:
                                idCardUpload = true;
                                dispatch(updateFileUpload({idCardUpload: idCardUpload}));
                                break;
                            case 6:
                                ribUpload = true;
                                dispatch(updateFileUpload({ribUpload: ribUpload}));
                        }
                    });
                }

                if (quotationUpload || idCardUpload || ribUpload) {
                    goStep = 7;
                    goSubStep = 1;
                    dispatch(updateAgreementMembership({
                        agreementGC: true,
                        agreementContract: true,
                        agreementFormContract: true
                    }));
                }

                const respPerson = await dispatch(postSearchPersonByNrid(Number(ids[0])));
                const respQuo = await dispatch(postSearchQuotation(Number(ids[0]), Number(ids[1])));

                await serializePerson(respPerson.data, respQuo.data.mutacNonBeneficiaire);
                await serializeQuotation(respQuo, ids[2], respPerson.data.nom, respPerson.data.prenom);

                dispatch(gotoStepAndSubStep({step: goStep, subStep: goSubStep}));
            };

            fetchData().catch();
        }
    }, []);

    const serializePerson = (resp: {}, paymentSupport: number) => {
        let fromCounty: string;

        if (resp.numDepartementNaissance) {
            fromCounty = getNameOfCounty(resp.numDepartementNaissance);
        }

        dispatch(updateIdentity({
            civility: Number(Civility[resp.civilite]),
            address: {
                zipcode: resp.codePostal,
                city: resp.ville,
                street: resp.voie,
                number: '',
                complement: '',
            },
            birthDate: resp.dateNaissance,
            lastname: resp.nom,
            firstname: resp.prenom,
            taxResidence: !!(resp.codePostal && resp.ville && resp.voie),
        }));

        dispatch(updateIdentityContact({
            email: resp.email,
            phoneNumber: resp.telephone,
        }));

        dispatch(updateAccount({
            idAccount: resp.id,
        }));

        dispatch(updateFamilySituation({
            dependent: resp.nbEnfant !== undefined ? Dependent[getValueByKey(Dependent, resp.nbEnfant)] : undefined,
            familySituation: FamilySituation[getValueByKey(FamilySituationMutac, resp.situationFamiliale)],
        }));

        dispatch(updateFromOtherInfo({
            socialSecurityNumber: resp.numeroSecuriteSociale,
            profession: resp.profession,
            birthName: resp.nomNaissance,
            fromCity: resp.villeNaissance,
            paymentSupport: paymentSupport === 1 ? 'Mutac' : 'Other',
            fromCountyId: resp.numDepartementNaissance,
            fromCountyLabel: fromCounty,
        }));

        dispatch(updateIdentity5({
            electedMandate: resp.mandatElectif
        }));

        if ((resp.nbEnfant >= 0 || resp.situationFamiliale) && goStep < 6) {
            dispatch(updateAgreementMembership({agreementFormContract: true}));
            goStep = 6;
        }
        if (resp.nbEnfant || resp.situationFamiliale && goStep < 7) {
            goSubStep = 1;
        }
        if ((resp.codePostal ||
                resp.voie ||
                resp.ville) &&
            goSubStep < 3 &&
            goStep < 7
        ) {
            goSubStep = 3;
        }
        if ((resp.numeroSecuriteSociale ||
                resp.profession ||
                resp.nomNaissance ||
                resp.villeNaissance ||
                resp.mutacNonBeneficiaire) &&
            goSubStep < 5 &&
            goStep < 7
        ) {
            goSubStep = 5;
        }
    };

    const serializeQuotation = async (respQuo: {}, folderGuidToQuotation: string, lastname: string, firstname: string) => {
        const sepa = JSON.parse(respQuo.data.sepa);

        const offer: Offer = {
            id: respQuo.data.devisGarantieID,
            name: respQuo.data.devisGarantie,
            capital: respQuo.data.devisCapital,
            duration: respQuo.data.devisNbrVersements,
            firstPayment: respQuo.data.devisMtPremierVersement > 0 ? respQuo.data.devisMtPremierVersement : respQuo.data.devisMtCotisationMensuelle,
            monthlyFees: respQuo.data.devisMtCotisationMensuelle,
        };

        dispatch(addOffer(offer));
        dispatch(selectOffer({selectedOfferId: offer.id}));
        dispatch(updatePayment({
            isRecurringPayment: !!respQuo.data.devisMtCotisationMensuelle,
        }));

        const beneficiaries = await dispatch(getBeneficiaries(respQuo.data.devisRef));
        const beneficiariesPhysical = beneficiaries.data['beneficiaires'];
        const beneficiariesPM = beneficiaries.data['beneficiairesPM'];

        if (beneficiariesPhysical || beneficiariesPM) {
            let beneficiaries: Beneficiary [] = [];
            beneficiariesPhysical.map((beneficiary) => {
                const beneficiarySerialize = serializeBeneficiary(beneficiary, true);
                beneficiaries = [...beneficiaries, beneficiarySerialize];
                dispatch(addBeneficiary(beneficiarySerialize));
            });

            beneficiariesPM.map((beneficiary) => {
                const beneficiarySerialize = serializeBeneficiary(beneficiary, false);
                beneficiaries = [...beneficiaries, beneficiarySerialize];
                dispatch(addBeneficiary(beneficiarySerialize));
            });

            const distribution = beneficiaries?.map((b) => Number(b.distribution)).reduce((a, b) => a + b, 0) ?? 0;

            if (distribution === 100 && goStep < 7) {
                goSubStep = 6;
            }
        }

        dispatch(updateQuotationNrid({
            quotationNrid: respQuo.data.devisNRID,
            quotationRef: respQuo.data.devisRef,
            folderGuidToQuotation: folderGuidToQuotation,
            guarantyName: respQuo.data.devisGarantie,
            creationDate: respQuo.data.devisDateEdit,
        }));

        if (sepa) {

            dispatch(updatePayment({
                iban: sepa.iban,
                bic: sepa.bic,
                debitDay: sepa.prelevement,
                periodicity: getKeyForTranslate(respQuo.data.daModePaiement),
                agreementDebit: true,
                isAccountDebit: lastname === sepa.nomTitulaire && firstname === sepa.prenomTitulaire,
            }));

            if (lastname !== sepa.nomTitulaire && firstname !== sepa.prenomTitulaire) {
                dispatch(updatePayment({
                    payerInformation: {
                        lastname: sepa.nomTitulaire,
                        firstname: sepa.prenomTitulaire,
                        address: sepa.adresse.NomRue,
                        zipcode: sepa.adresse.CP,
                        city: sepa.adresse.Ville,
                    },
                    periodicity: getKeyForTranslate(respQuo.data.daModePaiement),
                }));
            }

            if ((sepa.iban || sepa.bic || sepa.prelevement || respQuo.data.daModePaiement) && goSubStep === 6 && goStep < 7) {
                goSubStep = 7;
            }
        }
    };

    const getKeyForTranslate = (daModePaiement: string) => {
        for (const [key, value] of Object.entries(t('translation:membership.5.periodicities', {returnObjects: true}))) {
            if (value === daModePaiement) {
                return key;
            }
        }
    };

    return (
        <BoxFormat>
            <Loader size="10rem" color={'light'}/>
        </BoxFormat>
    );
};

export default AuthByURL;

import { Box, Button, Link } from '@mui/material';
import React, { FunctionComponent, MouseEventHandler } from 'react';
import theme from '../theme';
import { useTranslation } from 'react-i18next';

type buttonMutacProps = {
    label: string,
    handleAction?: MouseEventHandler,
    type?: string,
    px?: number,
    py?: number,
    mx?: number,
    my?: number,
    mt?: number,
    url?: string,
    disabled?: boolean,
    fontSize?: number,
    blueLight?: boolean,
};

const ButtonWidget: FunctionComponent<buttonMutacProps> = (props: buttonMutacProps) => {
    const {t} = useTranslation();
    let colorButton = theme.palette.primary.main;

    if (props.blueLight) {
        colorButton = theme.palette.secondary.main;
    }

    const style = {
        borderRadius: 20,
        px: props.px || 4,
        py: props.py || 1,
        mx: props.mx || 0,
        my: props.my || 4,
        mt: props.mt ?? 4,
        opacity: props.disabled ? 0.5 : 1,
        fontSize: props.fontSize ? t('translation:errors.rem', {value: props.fontSize}) : '1.25rem',
        '&:hover': {backgroundColor: colorButton},
    };

    const handleType = () => {
        return props.type === 'submit' ? 'submit' : 'button';
    };

    return (
        <Box sx={{
            textAlign: 'center',
        }}>
            {props.url === undefined ? (
                <Button
                    variant="outlined"
                    sx={style}
                    onClick={props.handleAction}
                    disabled={props.disabled || false}
                    type={handleType()}
                >
                    {props.label}
                </Button>
            ) : (
                <Link
                    sx={{color: theme.palette.text.secondary}}
                    href={props.url}
                    variant="button"
                >
                    {props.label}
                </Link>
            )}
        </Box>
    );
};

export default ButtonWidget;
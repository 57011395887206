import Department from '../models/department';

export default class DepartmentService {
    static getDepartments(): Department[] {
        const departments: Department[] = [
            new Department('01', 'Ain', true),
            new Department('02', 'Aisne', true),
            new Department('03', 'Allier', true),
            new Department('04', 'Alpes-de-Haute-Provence', true),
            new Department('05', 'Hautes-Alpes', true),
            new Department('06', 'Alpes-Maritimes', true),
            new Department('07', 'Ardèche', true),
            new Department('08', 'Ardennes', true),
            new Department('09', 'Ariège', true),
            new Department('10', 'Aube', true),
            new Department('11', 'Aude', true),
            new Department('12', 'Aveyron', true),
            new Department('13', 'Bouches-du-Rhône', true),
            new Department('14', 'Calvados', true),
            new Department('15', 'Cantal', true),
            new Department('16', 'Charente', true),
            new Department('17', 'Charente-Maritime', true),
            new Department('18', 'Cher', true),
            new Department('19', 'Corrèze', true),
            new Department('2A', 'Corse-du-sud', true),
            new Department('2B', 'Haute-Corse', true),
            new Department('21', 'Côte-d\'Or', true),
            new Department('22', 'Côtes-d\'Armor', true),
            new Department('23', 'Creuse', true),
            new Department('24', 'Dordogne', true),
            new Department('25', 'Doubs', true),
            new Department('26', 'Drôme', true),
            new Department('27', 'Eure', true),
            new Department('28', 'Eure-et-Loir', true),
            new Department('29', 'Finistère', true),
            new Department('30', 'Gard', true),
            new Department('31', 'Haute-Garonne', true),
            new Department('32', 'Gers', true),
            new Department('33', 'Gironde', true),
            new Department('34', 'Hérault', true),
            new Department('35', 'Île-et-Vilaine', true),
            new Department('36', 'Indre', true),
            new Department('37', 'Indre-et-Loire', true),
            new Department('38', 'Isère', true),
            new Department('39', 'Jura', true),
            new Department('40', 'Landes', true),
            new Department('41', 'Loir-et-Cher', true),
            new Department('42', 'Loire', true),
            new Department('43', 'Haute-Loire', true),
            new Department('44', 'Loire-Atlantique', true),
            new Department('45', 'Loiret', true),
            new Department('46', 'Lot', true),
            new Department('47', 'Lot-et-Garonne', true),
            new Department('48', 'Lozère', true),
            new Department('49', 'Maine-et-Loire', true),
            new Department('50', 'Manche', true),
            new Department('51', 'Marne', true),
            new Department('52', 'Haute-Marne', true),
            new Department('53', 'Mayenne', true),
            new Department('54', 'Meurthe-et-Moselle', true),
            new Department('55', 'Meuse', true),
            new Department('56', 'Morbihan', true),
            new Department('57', 'Moselle', true),
            new Department('58', 'Nièvre', true),
            new Department('59', 'Nord', true),
            new Department('60', 'Oise', true),
            new Department('61', 'Orne', true),
            new Department('62', 'Pas-de-Calais', true),
            new Department('63', 'Puy-de-Dôme', true),
            new Department('64', 'Pyrénées-Atlantiques', true),
            new Department('65', 'Hautes-Pyrénées', true),
            new Department('66', 'Pyrénées-Orientales', true),
            new Department('67', 'Bas-Rhin', true),
            new Department('68', 'Haut-Rhin', true),
            new Department('69', 'Rhône', true),
            new Department('70', 'Haute-Saône', true),
            new Department('71', 'Saône-et-Loire', true),
            new Department('72', 'Sarthe', true),
            new Department('73', 'Savoie', true),
            new Department('74', 'Haute-Savoie', true),
            new Department('75', 'Paris', true),
            new Department('76', 'Seine-Maritime', true),
            new Department('77', 'Seine-et-Marne', true),
            new Department('78', 'Yvelines', true),
            new Department('79', 'Deux-Sèvres', true),
            new Department('80', 'Somme', true),
            new Department('81', 'Tarn', true),
            new Department('82', 'Tarn-et-Garonne', true),
            new Department('83', 'Var', true),
            new Department('84', 'Vaucluse', true),
            new Department('85', 'Vendée', true),
            new Department('86', 'Vienne', true),
            new Department('87', 'Haute-Vienne', true),
            new Department('88', 'Vosges', true),
            new Department('89', 'Yonne', true),
            new Department('90', 'Territoire-de-Belfort', true),
            new Department('91', 'Essonne', true),
            new Department('92', 'Hauts-de-Seine', true),
            new Department('93', 'Seine-Saint-Denis', true),
            new Department('94', 'Val-de-Marne', true),
            new Department('95', 'Val-d\'Oise', true),
            new Department('971', 'Guadeloupe', true),
            new Department('972', 'Martinique', true),
            new Department('974', 'La Réunion', true),
            new Department('976', 'Mayotte', false)
        ];
        departments.sort((a, b) => a.id.localeCompare(b.id));
        return departments;
    }
}

export const getNameOfCounty = (countyId: string) => {
    let countyLabel = '';
    DepartmentService.getDepartments().forEach(d => {
        if (countyId.toString() === d.id.toString()) {
            countyLabel = d.name;
        }
    });

    return countyLabel;
};

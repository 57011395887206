import {
    ChargePourcent,
    Deferred,
    Dependent,
    Duration,
    ElectedMandat,
    FamilySituation,
    FinancialKnowledge,
    Income,
    InsuranceKnowledge,
    KycState,
    LifeInsurance,
    Payment,
    Upgrade,
    WhatIWant,
} from '../features/kyc/kycSlice';
import { getValueByKey } from './enum';

export const getDda = (kyc: KycState) => {
    return (
        {
            '3': formatDate(kyc.birthDate),
            '4': kyc.address?.zipcode,
            '5': '56',
            '6': getValueByKey(FamilySituation, kyc.familySituation, true) || null,
            '7': getValueByKey(Dependent, kyc.dependent) || null,
            // Protected adult (not used)
            '8': '8',
            '9': getValueByKey(InsuranceKnowledge, kyc.lifeInsuranceKnowledge, true),
            '10': getValueByKey(FinancialKnowledge, kyc.financialKnowledge, true) || null,
            '11': getValueByKey(LifeInsurance, kyc.lifeInsuranceOwned, true),
            '12': getStringValue(kyc, 'contractOrOrganizationName'),
            '13': getStringValue(kyc, 'contractsNumber'),
            '14': getStringValue(kyc, 'totalAmount'),
            '15': getValueByKey(Income, kyc.income, true),
            '16': getValueByKey(ChargePourcent, kyc.chargePourcent, true),
            '17': getValueByKey(Payment, kyc.payment, true),
            // Monthly payment (not used)
            '18': '30',
            '19': getValueByKey(Payment, kyc.payment) !== Payment.Single ? getValueByKey(Duration, kyc.duration, true) : null,
            '20': kyc.singlePayment ?? null,
            '21': getValueByKey(WhatIWant, kyc.whatIWant, true),
            '24': kyc.whoIsConcerned === 3 ? '43' : '44',
            '25': kyc.whoIsConcerned === 3 ? '46' : '45',
            '26': getValueByKey(Upgrade, kyc.upgrade, true),
            '28': getValueByKey(Deferred, kyc.deferred, true),
            // Minimum coverage below €15,000
            '29': '53',
            '30': 0,
            '45': getValueByKey(ElectedMandat, kyc.electedMandate) === ElectedMandat.Yes ? '77' : '78',
        }
    );
};

export const getStringValue = (kyc: KycState, param: string): string | null => {
    let str: string = '';
    if (getValueByKey(LifeInsurance, kyc.lifeInsuranceOwned) === LifeInsurance.Yes) {
        kyc.lifeInsurance?.forEach(value => {
            // @ts-ignore
            str = str + value[param] + ';';
        });

        return str;
    }

    return null;
};

export const modifyAndFormatDate = (nbr: number) => {
    let today = new Date();
    return today.setFullYear(today.getFullYear() - nbr);
};

export const handleValueIsOne = (value: string) => {
    return Number(value) === 1;
};

export const oneOrTwo = (value: any) => {
    return value === undefined ? '' : value ? 1 : 2;
};

export const formatDate = (date: Date | number | undefined): string => {
    date = date === undefined ? new Date() : date;

    return new Date(date).toLocaleDateString('ja-JP', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });
};

export const formatStringToDate = (date: string | number) => {
    return new Date(date).toLocaleDateString('fr-FR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });
};

export const isInEnum = (e: Object, str: string): boolean => {
    const enu = Object.keys(e).filter(r => r === str);

    return !!enu;
};

import Step from "./models/step";
import RequirementsPage from "./components/step2/RequirementsPage";
import ContractPage from "./components/step5-7/ContractPage";
import MembershipPage from "./components/step6/MembershipPage";
import IdentityPage from "./components/step4/IdentityPage";
import FuneralCostsPage from "./components/step1/FuneralCostsPage";

export const ROUTES: Step[] = [
    { id: 1, category: 'Coût des obsèques', pageWidget: FuneralCostsPage, steps: ['Authentification', 'Informations géographiques', 'Coût moyen'] },
    { id: 2, category: 'Vos besoins', pageWidget: RequirementsPage, steps: ['Personne concernée', 'Rendez-vous nécessaire', 'Vos besoins', 'Rendez-vous nécessaire'] },
    { id: 3, category: 'Votre situation financière', pageWidget: RequirementsPage, steps: [] },
    { id: 4, category: 'Votre identité', pageWidget: IdentityPage, steps: ['Informations', 'Connaissances', 'Contrat AV', 'Situation Financière', 'Propositions', 'Modifier Contrat Épargne', 'Consentement', 'Envois devis'] },
    { id: 5, category: 'Votre espace devis', pageWidget: ContractPage, steps: ['Espace prospect'] },
    { id: 6, category: 'Votre adhésion', pageWidget: MembershipPage, steps: ['Situation Familiale', 'CNRACL', 'Adresse', 'Résidence fiscal','Autres informations', 'Mes bénéficiaires', 'Prélèvements', 'Terminer'] },
    { id: 7, category: 'Votre contrat', pageWidget: ContractPage, steps: ['Contrat','Resumé'] },
];

export default ROUTES;

import { httpApiPost } from './http.js';
import { Civility, KycState } from '../features/kyc/kycSlice';
import { formatDate } from './dda';

export const postLead = (kyc: KycState) => async () => {
    // the data sent must be an object in a Json ex: data: { "{'propertyOfObject': 'value'}" }
    const data = "{" +
        "'civilite': '" + Civility[`${Number(kyc.civility)}`] + "'," +
        "'nom': '" + replaceCharacters(kyc.lastname?.toUpperCase()) + "'," +
        "'prenom': '" + replaceCharacters(kyc.firstname?.toUpperCase()) + "'," +
        "'dateNaissance': '" + formatDate(kyc.birthDate) + "'," +
        "'capitalSouhaite': '" + kyc.capital + "'," +
        "'situationFamiliale': '" + null + "'," +
        "'numeroRue': '" + replaceCharacters(kyc.address?.number) + "'," +
        "'nomRue': '" + replaceCharacters(kyc.address?.street) + "'," +
        "'codePostal': '" + kyc.address?.zipcode + "'," +
        "'ville': '" + replaceCharacters(kyc.address?.city) + "'," +
        "'telephone': '" + replaceCharacters(kyc.phoneNumber) + "'," +
        "'email': '" + replaceCharacters(kyc.email) + "'," +
        (kyc.whoIsConcerned === 4 ?
            "'nomConjoint': '" + (replaceCharacters(kyc.lastnameSpouse?.toUpperCase())??'')+ "'," +
            "'prenomConjoint': '" + (replaceCharacters(kyc.firstnameSpouse?.toUpperCase())??'') + "'," +
            "'dateNaissanceConjoint': '" + (formatDate(kyc.birthDateSpouse)??'') + "',":"")+
        "'codeTiers': '" + process.env.REACT_APP_CODE_TIERS_LEAD + "'" +
        "}";

    await httpApiPost('/lead', data)
};

const replaceCharacters = (str: string | undefined): string | undefined => {
    if (!str) {
        return;
    }

    const quote = new RegExp(/'/, 'gi');
    const doubleQuote = new RegExp(/"/, 'gi');
    const backSlash = new RegExp(/\\/, 'gi');

    return str.replaceAll(backSlash, '\\\\').replaceAll(quote, '\\\'').replaceAll(doubleQuote, '\\\"');
};

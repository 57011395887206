import { Dependent, FamilySituation, KycState, updateFamilySituation } from '../../features/kyc/kycSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useTranslation } from 'react-i18next';
import React, { FunctionComponent } from 'react';
import H6 from '../H6';
import { Box } from '@mui/material';
import SelectWidget from '../SelectWidget';
import { handleEnum, handleValue } from '../../utils/enum';
import ButtonContinue from '../ButtonContinue';

const MySituation: FunctionComponent = () => {
    const kyc: KycState = useAppSelector((state) => state.kyc);
    const dispatch = useAppDispatch();
    const {t} = useTranslation();

    const familySituation: string[] = [];
    for (let i = 1; i <= Object.values(FamilySituation).length / 2; i++) {
        familySituation.push(t(`translation:membership.7.form.q1.choice${i}`));
    }
    const dependent: string[] = [];
    for (let i = 1; i <= Object.values(Dependent).length / 2; i++) {
        dependent.push(t(`translation:membership.7.form.q2.choice${i}`));
    }

    return (
        <div>
            <Box marginTop={11} marginLeft={25}>
                <H6>{t('translation:membership.7.form.label')}</H6>
                <SelectWidget
                    id={'familySituation'}
                    value={handleValue(FamilySituation, kyc.familySituation) || ''}
                    onChange={(event) => {
                        dispatch(updateFamilySituation({familySituation: handleEnum(FamilySituation, event.target.value)}));
                    }}
                    object={familySituation}
                />
            </Box>
            <Box my={3} marginLeft={25}>
                <H6>{t('translation:membership.7.form.q2.label1')}</H6>
                <SelectWidget
                    id={'dependent'}
                    value={handleValue(Dependent, kyc.dependent) || ''}
                    onChange={(event) => {
                        dispatch(updateFamilySituation({dependent: handleEnum(Dependent, event.target.value)}));
                    }}
                    object={dependent}
                />
                <H6>{t('translation:membership.7.form.q2.label2')}</H6>
            </Box>

            {kyc.familySituation !== undefined &&
                kyc.dependent !== undefined &&
              <ButtonContinue mt={19}/>
            }
        </div>
    );
};

export default MySituation;

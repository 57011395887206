import { Box, ButtonGroup, Chip, styled } from '@mui/material';
import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { KycState, updateIdentity, } from '../../features/kyc/kycSlice';
import { next, nextWithSkip } from '../../features/navigation/navigationSlice';
import ButtonWidget from '../ButtonWidget';
import Typography from '@mui/material/Typography';
import CustomModal from '../CustomModal';
import theme from '../../theme';
import TextFieldWidget from '../TextFieldWidget';
import SelectWidget from '../SelectWidget';
import H6 from '../H6';
import AddressService, { AddressType } from '../../utils/address';
import DoubleArrowOutlinedIcon from '@mui/icons-material/DoubleArrowOutlined';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useTranslation } from 'react-i18next';
import InfoIconWidget from '../InfoIconWidget';
import { oneOrTwo } from '../../utils/dda';

const BoxFormat = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    color: theme.typography.caption.color,
    marginTop: '0.625rem',
    marginBottom: '0.625rem',
});


const FormAddress: FunctionComponent = () => {
    const kyc: KycState = useAppSelector((state) => state.kyc);
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const [open, setOpen] = useState<number>();
    const [addressControlled, setAddressControlled] = useState<AddressType>({...kyc.address});
    const handleModal = (index: number) => setOpen(open === index ? undefined : index);
    const [isValid, setIsValid] = useState(true);
    const [addressExist, setAddressExist] = useState(false);
    const yesOrNoValues: string[] = [t('translation:identity.2.form.q4.choice1'), t('translation:identity.2.form.q4.choice2')];

    useEffect(() => {
        if (addressExist) {
            if (!addressChanged() && isValid) {
                if (kyc.taxResidence === true)
                    dispatch(nextWithSkip());
                else
                    dispatch(next());
            } else {
                setOpen(1);
            }
        }

    }, [addressControlled, isValid]);

    const handleTaxResidence = (value: string) => {
        return Number(value) === 1;
    };

    const checkIfExist = () => {
        if (!kyc.address) {
            return false;
        }

        return kyc?.address.street !== undefined &&
            kyc?.address.zipcode !== undefined &&
            kyc?.address.city !== undefined &&
            kyc?.address.street !== '' &&
            kyc?.address.zipcode !== '' &&
            kyc?.address.city !== '';
    };

    const addressValid = (): boolean =>
        addressControlled?.street !== undefined &&
        addressControlled?.zipcode !== undefined &&
        addressControlled?.city !== undefined &&
        addressControlled?.street !== '' &&
        addressControlled?.zipcode !== '' &&
        addressControlled?.city !== '';

    const addressChanged = (): boolean =>
        (addressControlled?.number !== kyc.address?.number ||
            addressControlled?.street !== kyc.address?.street ||
            addressControlled?.zipcode !== kyc.address?.zipcode ||
            addressControlled?.city !== kyc.address?.city
        );

    const addressControl = async () => {
        setAddressExist(true);
        // @ts-ignore
        if (!RegExp(/^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/).test(kyc.address?.zipcode)) {
            setIsValid(false);
            return;
        }

        setOpen(1);
        const addressApi = await AddressService.getAddress(
            {
                number: kyc.address?.number,
                street: kyc.address?.street,
                zipcode: kyc.address?.zipcode,
                city: kyc.address?.city
            }
        );

        setAddressControlled(addressApi);
    };

    const updatedAddressAndContinue = () => {
        dispatch(updateIdentity({address: addressControlled}));
        continueToNextScreen();
    };

    const withoutUpdateAddress = () => {
        dispatch(updateIdentity({
            address: {
                number: kyc.address?.number,
                street: kyc.address?.street,
                zipcode: kyc.address?.zipcode,
                city: kyc.address?.city
            }
        }));
        continueToNextScreen();
    };

    const continueToNextScreen = () => {
        if (kyc.taxResidence === true) {
            dispatch(nextWithSkip());
        } else {
            dispatch(next());
        }
    };

    const validate = () => {
        return (checkIfExist() &&
            kyc.taxResidence !== undefined
        );
    };

    return (
        <>
            <BoxFormat marginLeft={17}>

                <BoxFormat flexDirection="column" my={1} style={{marginLeft: 0, alignItems: 'flex-start'}}>
                    <BoxFormat my={1}>
                        <InfoIconWidget size="large" handleAction={() => handleModal(3)}/>
                        <H6 style={{fontSize: 27}}>{t('translation:identity.2.form.q3.label')}</H6>
                        <TextFieldWidget
                            placeholder={t('translation:identity.2.form.q3.placeholder-number')}
                            id="number"
                            value={kyc.address?.number}
                            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                                dispatch(updateIdentity({address: {number: event.target.value}}));
                            }}
                            minWidth={7}
                        />
                        <TextFieldWidget
                            placeholder={t('translation:identity.2.form.q3.placeholder-street')}
                            id="street"
                            value={kyc.address?.street}
                            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                                dispatch(updateIdentity({address: {street: event.target.value}}));
                            }}
                            minWidth={30.5}
                            autocomplete={'street-address'}
                        />
                    </BoxFormat>
                    <BoxFormat marginLeft={23}>
                        <TextFieldWidget
                            optional
                            placeholder={t('translation:identity.2.form.q3.placeholder-complement')}
                            id="complement"
                            value={kyc.address?.complement}
                            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                                dispatch(updateIdentity({address: {complement: event.target.value}}));
                            }}
                            minWidth={38.5}
                            autocomplete={'address-line2'}
                        />
                    </BoxFormat>
                    <BoxFormat marginLeft={23}>
                        <TextFieldWidget
                            placeholder={t('translation:identity.2.form.q3.placeholder-zipcode')}
                            id="zipcode"
                            value={kyc.address?.zipcode}
                            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                                dispatch(updateIdentity({address: {zipcode: event.target.value}}));
                            }}
                            minWidth={10}
                            maxWidth={13.75}
                            helperText={!isValid ? t('translation:errors.zipcode') : ''}
                            autocomplete={'postal-code'}
                        />
                        <TextFieldWidget
                            placeholder={t('translation:identity.2.form.q3.placeholder-city')}
                            id="city"
                            value={kyc.address?.city}
                            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                                dispatch(updateIdentity({address: {city: event.target.value}}));
                            }}
                            minWidth={27.5}
                        />
                    </BoxFormat>
                </BoxFormat>
            </BoxFormat>
            <BoxFormat marginLeft={17}>
                <InfoIconWidget size="large" handleAction={() => handleModal(4)}/>
                <H6 style={{fontSize: 27}}>{t('translation:identity.2.form.q4.label')}</H6>
                <SelectWidget
                    id={'taxResidence'}
                    value={oneOrTwo(kyc.taxResidence)}
                    onChange={(event) => {
                        dispatch(updateIdentity({taxResidence: handleTaxResidence(event.target.value)}));
                    }}
                    object={yesOrNoValues}
                />
            </BoxFormat>

            {validate() &&
              <ButtonWidget
                label={t('translation:continue')}
                handleAction={addressControl}
                mt={19}
              />
            }

            <CustomModal open={open !== undefined} handleModal={() => setOpen(undefined)}>
                {open === 1 &&
                  <div style={{margin: 10}}>
                      {addressChanged() &&
                        <BoxFormat
                          sx={{
                              color: theme.palette.text.secondary,
                              alignItems: 'center',
                              display: 'flex',
                              flexDirection: 'column'
                          }}>
                          <Typography id="modal-modal-description"
                                      sx={{
                                          textAlign: 'center',
                                          fontWeight: 'bold',
                                          minWidth: 300,
                                          fontSize: '1.3rem',
                                          color: theme.palette.text.secondary
                                      }}>
                              {t('translation:identity.2.form.q3.modalControl.label')}
                          </Typography>
                          <BoxFormat sx={{flexDirection: 'column', alignItems: 'flex-start'}}>
                              {(kyc.address?.number !== addressControlled?.number) &&
                                <BoxFormat sx={{color: theme.palette.text.secondary}} marginLeft={2} marginY={1}>
                                  <Typography
                                    id="modal-modal-description"
                                    marginRight={1}
                                  >{t('translation:identity.2.form.q3.placeholder-number')}:</Typography>
                                  <Chip label={kyc.address?.number} color="error" size="small"/>
                                  <DoubleArrowOutlinedIcon/>
                                  <Chip label={addressControlled?.number || kyc.address?.number} color="primary"
                                        size="small"/>
                                </BoxFormat>
                              }
                              {kyc.address?.street !== addressControlled?.street &&
                                <BoxFormat sx={{color: theme.palette.text.secondary}} marginLeft={2} marginY={1}>
                                  <Typography
                                    id="modal-modal-description"
                                    marginRight={1}
                                  >{t('translation:identity.2.form.q3.placeholder-street')}:</Typography>
                                  <Chip label={kyc.address?.street} color="error" size="small"/>
                                  <DoubleArrowOutlinedIcon/>
                                  <Chip label={addressControlled?.street || kyc.address?.street} color="primary"
                                        size="small"/>
                                </BoxFormat>
                              }
                              {kyc.address?.zipcode !== addressControlled?.zipcode &&
                                <BoxFormat sx={{color: theme.palette.text.secondary}} marginLeft={2} marginY={1}>
                                  <Typography
                                    id="modal-modal-description"
                                    marginRight={1}
                                  >{t('translation:identity.2.form.q3.placeholder-zipcode')}:</Typography>
                                  <Chip label={kyc.address?.zipcode} color="error" size="small"/>
                                  <DoubleArrowOutlinedIcon/>
                                  <Chip label={addressControlled?.zipcode || kyc.address?.zipcode} color="primary"
                                        size="small"/>
                                </BoxFormat>
                              }
                              {kyc.address?.city !== addressControlled?.city &&
                                <BoxFormat sx={{color: theme.palette.text.secondary}} marginLeft={2} marginY={1}>
                                  <Typography
                                    id="modal-modal-description"
                                    marginRight={1}
                                  >{t('translation:identity.2.form.q3.placeholder-city')}:</Typography>
                                  <Chip label={kyc.address?.city} color="error" size="small"/>
                                  <DoubleArrowOutlinedIcon/>
                                  <Chip label={addressControlled?.city || kyc.address?.city} color="primary"
                                        size="small"/>
                                </BoxFormat>
                              }
                          </BoxFormat>

                          <BoxFormat className="uk-text-center" marginLeft={'1rem'} marginTop={'1rem'}>
                            <ButtonGroup>
                              <ButtonWidget label={t('translation:identity.2.form.q3.modalControl.buttonKO')}
                                            handleAction={withoutUpdateAddress} py={0.5} mx={1}/>
                              <ButtonWidget label={t('translation:identity.2.form.q3.modalControl.buttonOK')}
                                            handleAction={updatedAddressAndContinue} py={0.5} mx={1}/>
                            </ButtonGroup>
                          </BoxFormat>
                        </BoxFormat>
                      }
                      {!addressValid() &&
                        <BoxFormat
                          sx={{
                              alignItems: 'center',
                              justifyContent: 'center',
                              fontSize: '1.3rem',
                              color: theme.palette.text.secondary
                          }}>
                          <Typography id="modal-modal-description"
                                      sx={{textAlign: 'center', fontWeight: 'bold'}}>
                              {t('translation:identity.2.form.q3.modalControl.labelAddressKO')}
                          </Typography>
                          <ButtonWidget
                            label={t('translation:identity.2.form.q3.modal.button')}
                            handleAction={() => setOpen(undefined)}
                            py={0.5}
                            mx={1}
                            fontSize={1.2}
                            blueLight
                          />
                        </BoxFormat>
                      }
                  </div>
                }
            </CustomModal>
        </>
    );
};

export default FormAddress;

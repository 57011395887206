import {
    addBeneficiary,
    Beneficiary,
    BeneficiaryType,
    KycState,
    LegalStatus,
    removeBeneficiary,
    Tie,
} from '../../features/kyc/kycSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardActions, CardContent, Chip, Icon, styled, Typography } from '@mui/material';
import theme from '../../theme';
import ButtonWidget from '../ButtonWidget';
import H6 from '../H6';
import SelectWidget from '../SelectWidget';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import TextFieldWidget from '../TextFieldWidget';
import Datefield from '../Datefield';
import InfoIconWidget from '../InfoIconWidget';
import CustomModal from '../CustomModal';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import ButtonContinue from '../ButtonContinue';
import { formatStringToDate, modifyAndFormatDate } from '../../utils/dda';
import { deleteBeneficiary } from '../../utils/beneficiaries';
import { postSavePerson } from '../../utils/person';
import { handleEnum, handleValue } from '../../utils/enum';

const BoxFormat = styled(Box)({
    display: 'flex',
    alignItems: 'flex-start',
    marginLeft: '12.5rem',
    color: theme.typography.caption.color,
});

const FormBeneficiary = () => {
    const kyc: KycState = useAppSelector((state) => state.kyc);
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState<number>();
    const [open2, setOpen2] = useState<number>();
    const [allDistribution, setAllDistribution] = useState<number>(kyc.beneficiaries?.map((b) => Number(b.distribution)).reduce((a, b) => a + b, 0) ?? 0);
    const [displayErrors, setDisplayErrors] = useState<boolean>(false);
    const handleModal = (index: number) => setOpen(open === index ? undefined : index);
    const handleModal2 = (index: number) => setOpen2(open2 === index ? undefined : index);
    const zipcodeRegExp: RegExp = RegExp(/^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/);
    const sirenRegExp: RegExp = RegExp(/^\d{9}$/);
    const typeValues: string[] = [];
    const tieChoices: any[] = [];
    const statusOptions: any[] = [];

    for (let i = 1; i <= Object.values(BeneficiaryType).length / 2; i++) {
        typeValues.push(t(`membership.2.beneficiaries.choice${i}`));
    }

    for (let i = 1; i <= Object.values(Tie).length / 2; i++) {
        tieChoices.push(t(`translation:membership.3.tie.choices.${i}`));
    }

    for (let i = 1; i <= Object.values(LegalStatus).length / 2; i++) {
        statusOptions.push(t(`translation:membership.4.legal-status-options.${i}`));
    }

    const validationSchema = Yup.object().shape({
        companyName: Yup.string()
            .when('type', {
                is: (f: BeneficiaryType) => f === BeneficiaryType.Corporation,
                then: Yup.string()
                    .required(t('translation:errors.required')).nullable()
            }),
        sirenNumber: Yup.string()
            .when(['legalStatus', 'type'], {
                is: (legalStatus: string, f: BeneficiaryType) => legalStatus !== 'Association' && f === BeneficiaryType.Corporation,
                then: Yup.string()
                    .matches(sirenRegExp, t('translation:errors.siren'))
                    .required(t('translation:errors.required')).nullable()
            }),
        legalStatus: Yup.string()
            .when('type', {
                is: (f: BeneficiaryType) => f === BeneficiaryType.Corporation,
                then: Yup.string().required(t('translation:errors.required')).nullable()
            }),
        physicalLastname: Yup.string()
            .when('type', {
                is: (f: BeneficiaryType) => f === BeneficiaryType.Physical,
                then: Yup.string().required(t('translation:errors.required')).nullable()
            }),
        physicalFirstname: Yup.string()
            .when('type', {
                is: (f: BeneficiaryType) => f === BeneficiaryType.Physical,
                then: Yup.string().required(t('translation:errors.required')).nullable()
            }),
        physicalBirthday: Yup.number()
            .when('type', {
                is: (f: BeneficiaryType) => f === BeneficiaryType.Physical,
                then: Yup.number()
                    .min(+new Date(modifyAndFormatDate(120)), t('translation:errors.max-age'))
                    .required(t('translation:errors.required'))

            }),
        physicalBirthCity: Yup.string()
            .when('type', {
                is: (f: BeneficiaryType) => f === BeneficiaryType.Physical,
                then: Yup.string().required(t('translation:errors.required')).nullable()
            }),
        physicalTie: Yup.string()
            .when('type', {
                is: (f: BeneficiaryType) => f === BeneficiaryType.Physical,
                then: Yup.string().required(t('translation:errors.required')).nullable()
            }),
        tieOther: Yup.string()
            .when('physicalTie', {
                is: (physicalTie: string) => physicalTie === 'other',
                then: Yup.string().required(t('translation:errors.required')).nullable()
            }),
        distribution: Yup.number()
            .typeError(t('translation:errors.distribution'))
            .positive(t('translation:errors.number-positive'))
            .integer(t('translation:errors.integer'))
            .max(100 - allDistribution, t('translation:errors.must-be', {value: 100 - allDistribution}))
            .required(t('translation:errors.required')).nullable(),
        streetNumber: Yup.string()
            .required(t('translation:errors.required')).nullable(),
        streetName: Yup.string()
            .required(t('translation:errors.required')).nullable(),
        zipcode: Yup.string()
            .matches(zipcodeRegExp, t('translation:errors.zipcode'))
            .required(t('translation:errors.required')).nullable(),
        city: Yup.string()
            .required(t('translation:errors.required')).nullable(),
    });

    useEffect(() => {
            const fetchData = async () => {
                await dispatch(postSavePerson(kyc, true, kyc.whoIsConcerned === 4));
            };
            fetchData();

        }, []
    );

    const formik = useFormik<Beneficiary>({
        initialValues: {},
        validationSchema,
        onSubmit: values => {
            setAllDistribution(allDistribution + Number(values.distribution));
            let idWeb = 1;
            kyc.beneficiaries?.map(b => {
                if (b.idWeb && idWeb <= b.idWeb) {
                    idWeb = b.idWeb + 1;
                }
            });

            if (values.type === BeneficiaryType.Physical) {
                dispatch(addBeneficiary({
                    idWeb: idWeb,
                    type: values.type,
                    distribution: values.distribution,
                    streetNumber: values.streetNumber,
                    streetName: values.streetName,
                    zipcode: values.zipcode,
                    city: values.city,
                    physicalLastname: values.physicalLastname,
                    physicalFirstname: values.physicalFirstname,
                    physicalBirthday: values.physicalBirthday,
                    physicalBirthCity: values.physicalBirthCity,
                    physicalTie: values.physicalTie,
                    tieOther: values.tieOther,
                }));
            } else {
                if (values.legalStatus === 'Association') {
                    values.sirenNumber = undefined;
                }
                dispatch(addBeneficiary({
                    idWeb: idWeb,
                    type: values.type,
                    distribution: values.distribution,
                    streetNumber: values.streetNumber,
                    streetName: values.streetName,
                    zipcode: values.zipcode,
                    city: values.city,
                    companyName: values.companyName,
                    sirenNumber: values.sirenNumber,
                    legalStatus: values.legalStatus,
                }));
            }
            formik.resetForm();
            setDisplayErrors(false);
        }
    });

    const handleDate = (date: any): number | undefined | string => {
        if (date instanceof Date && isNaN(+new Date(date))) {
            return undefined;
        }

        // date is a timestamp
        return +new Date(date);
    };

    return (
        <BoxFormat sx={{
            marginLeft: 0,
            alignItems: allDistribution === 100 ? 'center' : 'flex-start',
            flexDirection: 'column'
        }}>
            <form onSubmit={formik.handleSubmit}
                  style={{display: 'flex', flexDirection: 'column', width: 'fit-content'}}>

                {allDistribution !== 100 &&
                  <BoxFormat sx={{marginLeft: 17}}>
                    <InfoIconWidget size="large" handleAction={() => handleModal(2)}/>
                    <H6 minWidth={16}>{t('translation:membership.button-add-beneficiary')} :</H6>
                    <SelectWidget
                      id={'type'}
                      value={formik.values.type}
                      onChange={e => formik.setFieldValue('type', e.target.value)}
                      object={typeValues}
                    />
                  </BoxFormat>
                }

                {formik.values.type === BeneficiaryType.Physical &&
                  <>
                    <BoxFormat mt={2}>
                      <TextFieldWidget
                        placeholder={t('translation:placeholderForm.lastname')}
                        id="physicalLastname"
                        minWidth={18.75}
                        onChange={formik.handleChange}
                        value={formik.values.physicalLastname}
                        helperText={displayErrors ? formik.errors.physicalLastname : ''}
                        marginLeft={false}
                      />
                      <TextFieldWidget
                        placeholder={t('translation:placeholderForm.firstname')}
                        id="physicalFirstname"
                        minWidth={18.75}
                        onChange={formik.handleChange}
                        value={formik.values.physicalFirstname}
                        helperText={displayErrors ? formik.errors.physicalFirstname : ''}
                      />
                    </BoxFormat>
                    <BoxFormat mt={2}>
                      <H6>{t('translation:membership.3.birthday')}</H6>
                      <Datefield
                        onChange={date => handleDate(date) !== undefined ? formik.setFieldValue('physicalBirthday', handleDate(date)) : undefined}
                        value={formik.values.physicalBirthday}
                        helperText={displayErrors ? formik.errors.physicalBirthday : ''}
                      />
                      <H6 minWidth={1.5} marginLeft={2}>{t('translation:membership.3.from')}</H6>
                      <TextFieldWidget
                        placeholder={t('translation:placeholderForm.city')}
                        id="physicalBirthCity"
                        minWidth={18.75}
                        onChange={formik.handleChange}
                        value={formik.values.physicalBirthCity}
                        helperText={displayErrors ? formik.errors.physicalBirthCity : ''}
                      />
                    </BoxFormat>
                  </>
                }

                {formik.values.type === BeneficiaryType.Corporation &&
                  <BoxFormat mt={2}>
                    <H6>{t('translation:membership.4.company-name')}</H6>
                    <TextFieldWidget
                      placeholder={t('translation:placeholderForm.lastname')}
                      id="companyName"
                      minWidth={25}
                      onChange={formik.handleChange}
                      value={formik.values.companyName}
                      helperText={displayErrors ? formik.errors.companyName : ''}
                    />
                      {formik.values.legalStatus !== 'Association' &&
                        <>
                          <H6 marginLeft={2}>{t('translation:membership.4.siren-number')}</H6>
                          <TextFieldWidget
                            placeholder={t('translation:placeholderForm.format-siren')}
                            id="sirenNumber"
                            minWidth={25}
                            onChange={formik.handleChange}
                            value={formik.values.sirenNumber}
                            helperText={displayErrors ? formik.errors.sirenNumber : ''}
                          />
                        </>
                      }
                  </BoxFormat>
                }

                {formik.values.type !== undefined &&
                  <BoxFormat mt={2}>
                    <H6>{t('translation:membership.3.reside')}</H6>
                    <TextFieldWidget
                      placeholder={t('translation:identity.2.form.q3.placeholder-number')}
                      id="streetNumber"
                      maxWidth={13.75}
                      minWidth={6}
                      onChange={formik.handleChange}
                      value={formik.values.streetNumber}
                      helperText={displayErrors ? formik.errors.streetNumber : ''}
                    />
                    <TextFieldWidget
                      placeholder={t('translation:identity.2.form.q3.placeholder-street')}
                      id="streetName"
                      minWidth={23}
                      onChange={formik.handleChange}
                      value={formik.values.streetName}
                      helperText={displayErrors ? formik.errors.streetName : ''}
                      autocomplete={'street-address'}
                    />
                    <TextFieldWidget
                      placeholder={t('translation:identity.2.form.q3.placeholder-zipcode')}
                      id="zipcode"
                      minWidth={8}
                      maxWidth={13.75}
                      onChange={formik.handleChange}
                      value={formik.values.zipcode}
                      helperText={displayErrors ? formik.errors.zipcode : ''}
                      autocomplete={'postal-code'}
                    />
                    <TextFieldWidget
                      placeholder={t('translation:identity.2.form.q3.placeholder-city')}
                      id="city"
                      minWidth={18.75}
                      onChange={formik.handleChange}
                      value={formik.values.city}
                      helperText={displayErrors ? formik.errors.city : ''}
                    />
                  </BoxFormat>
                }

                {formik.values.type === BeneficiaryType.Physical &&
                  <>
                    <BoxFormat mt={2}>
                      <H6>{t('translation:membership.3.tie.label')}</H6>
                      <SelectWidget
                        id={'physicalTie'}
                        value={handleValue(Tie, formik.values.physicalTie)}
                        helperText={displayErrors ? formik.errors.physicalTie : ''}
                        onChange={e => formik.setFieldValue('physicalTie', handleEnum(Tie, e.target.value))}
                        object={tieChoices}
                      />

                        {formik.values.physicalTie === 'Other' &&
                          <TextFieldWidget
                            id={'tieOther'}
                            minWidth={19.5}
                            value={formik.values.tieOther}
                            helperText={displayErrors ? formik.errors.tieOther : ''}
                            onChange={formik.handleChange}
                            placeholder={t('translation:placeholderForm.specify')}
                          />
                        }
                    </BoxFormat>
                  </>
                }

                {formik.values.type === BeneficiaryType.Corporation &&
                  <BoxFormat mt={2}>
                    <H6>{t('translation:membership.4.legal-status')}</H6>
                    <SelectWidget
                      id={'legalStatus'}
                      value={handleValue(LegalStatus, formik.values.legalStatus)}
                      helperText={displayErrors ? formik.errors.legalStatus : ''}
                      onChange={e => formik.setFieldValue('legalStatus', handleEnum(LegalStatus, e.target.value))}
                      object={statusOptions}
                    />
                  </BoxFormat>
                }

                {formik.values.type !== undefined &&
                  <BoxFormat sx={{marginLeft: 17}} mt={2}>
                    <InfoIconWidget size="large" handleAction={() => handleModal2(2)}/>
                    <H6 marginLeft={1.75}>{t('translation:membership.3.distribution')}</H6>
                    <TextFieldWidget
                      placeholder="%"
                      id="distribution"
                      maxWidth={18}
                      minWidth={6}
                      onChange={formik.handleChange}
                      value={formik.values.distribution}
                      helperText={displayErrors ? formik.errors.distribution : ''}
                    />
                  </BoxFormat>
                }

                {allDistribution !== 100 &&
                  <BoxFormat>
                    <ButtonWidget
                      label={t('translation:membership.button-add-beneficiary')}
                      type="submit"
                      py={0.1}
                      mt={8}
                      handleAction={() => setDisplayErrors(true)}/>
                  </BoxFormat>
                }

            </form>

            {kyc.beneficiaries && kyc.beneficiaries.length > 0 &&
              <BoxFormat
                sx={{
                    display: 'flex',
                    alignItems: 'stretch',
                    marginTop: 1,
                    marginLeft: 0,
                    justifyContent: 'center',
                    minWidth: '-webkit-fill-available',
                }}
              >
                  {kyc.beneficiaries?.map((b, k) =>
                      <Card
                          sx={{
                              mx: 2,
                              my: 2,
                              minWidth: '18.75rem',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                          }}
                          key={k}
                      >
                          <CardContent>
                              {b.type === BeneficiaryType.Physical &&
                                <>
                                  <BoxFormat
                                    sx={{
                                        marginLeft: 0,
                                        fontWeight: 'bold',
                                        fontSize: '1.5rem',
                                        color: theme.palette.text.secondary
                                    }}>
                                    <PersonOutlineOutlinedIcon sx={{mx: 1}}/>
                                      {b.physicalFirstname} {b.physicalLastname?.toUpperCase()}
                                  </BoxFormat>
                                  <BoxFormat sx={{marginLeft: 0, mt: 1, color: theme.palette.text.secondary}}>
                                      {t('translation:membership.3.birthday')}
                                      {b.physicalBirthday &&
                                          formatStringToDate(b.physicalBirthday)
                                      }
                                      {t('translation:membership.3.from')}
                                      {b.physicalBirthCity?.toUpperCase()}
                                  </BoxFormat>
                                  <BoxFormat sx={{marginLeft: 0, color: theme.palette.text.secondary}}>
                                      {t('translation:membership.3.tie.label')} {t(`translation:membership.3.tie.choices.${handleValue(Tie, b.physicalTie)}`)}
                                  </BoxFormat>
                                  <BoxFormat
                                    sx={{
                                        marginLeft: 0,
                                        textDecorationLine: 'underline',
                                        mt: 2,
                                        color: theme.palette.text.secondary
                                    }}>
                                      {t('translation:membership.4.address')}
                                  </BoxFormat>
                                  <BoxFormat sx={{marginLeft: 0, color: theme.palette.text.secondary}}>
                                      {b.streetNumber} {b.streetName}
                                  </BoxFormat>
                                  <BoxFormat sx={{marginLeft: 0, color: theme.palette.text.secondary}}>
                                      {b.zipcode} {b.city}
                                  </BoxFormat>
                                </>
                              }
                              {
                                  (b.type === BeneficiaryType.Corporation) &&
                                <>
                                  <BoxFormat
                                    sx={{
                                        marginLeft: 0,
                                        fontWeight: 'bold',
                                        fontSize: '1.5rem',
                                        color: theme.palette.text.secondary
                                    }}>
                                    <ApartmentOutlinedIcon sx={{mx: 1}}/>
                                      {b.companyName?.toUpperCase()}
                                  </BoxFormat>
                                    {b.sirenNumber ? (
                                        <BoxFormat sx={{marginLeft: 0, mt: 1, color: theme.palette.text.secondary}}>
                                            {t('translation:membership.4.siren-number')} {b.sirenNumber} ({b.legalStatus?.toUpperCase()})
                                        </BoxFormat>
                                    ) : (
                                        <BoxFormat sx={{marginLeft: 0, mt: 1, color: theme.palette.text.secondary}}>
                                            {b.legalStatus?.toUpperCase()}
                                        </BoxFormat>
                                    )}
                                  <BoxFormat
                                    sx={{
                                        marginLeft: 0,
                                        textDecorationLine: 'underline',
                                        mt: 2,
                                        color: theme.palette.text.secondary
                                    }}>
                                      {t('translation:membership.4.address')}
                                  </BoxFormat>
                                  <BoxFormat sx={{marginLeft: 0, color: theme.palette.text.secondary}}>
                                      {b.streetNumber} {b.streetName}
                                  </BoxFormat>
                                  <BoxFormat sx={{marginLeft: 0, color: theme.palette.text.secondary}}>
                                      {b.zipcode} {b.city}
                                  </BoxFormat>
                                </>
                              }
                          </CardContent>
                          <CardActions>
                              <Button onClick={
                                  async () => {
                                      setAllDistribution(allDistribution - Number(b.distribution));
                                      dispatch(
                                          removeBeneficiary({
                                              idWeb: b.idWeb
                                          }),
                                      );
                                      if (b.id) {
                                          await deleteBeneficiary(b, kyc);
                                      }
                                  }
                              }
                                      sx={{color: theme.palette.text.secondary}}>
                                  <Icon>{t('translation:placeholderForm.delete')}</Icon>
                              </Button>
                              <Chip sx={{fontWeight: 'bold'}} label={`Répartition ${b.distribution}%`}/>
                          </CardActions>
                      </Card>)
                  }
              </BoxFormat>
            }

            {allDistribution === 100 &&
              <BoxFormat sx={{margin: 'auto'}}>
                <ButtonContinue mt={14}/>
              </BoxFormat>
            }

            <CustomModal open={open !== undefined} handleModal={() => setOpen(undefined)}>
                {open === 2 &&
                  <div>
                    <Typography
                      id="modal-modal-description"
                      sx={{mt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}
                    >
                        {t('translation:membership.2.modal1.p1')}
                    </Typography>
                    <ButtonWidget
                      label={t('translation:identity.6.form.q3.modal.button')}
                      handleAction={() => setOpen(undefined)}
                      py={0.1}
                      fontSize={1.2}
                      blueLight
                    />
                  </div>
                }
            </CustomModal>
            <CustomModal open={open2 !== undefined} handleModal={() => setOpen2(undefined)}>
                {open2 === 2 &&
                  <div style={{fontSize: '1.3rem'}}>
                    <Typography
                      id="modal-modal-description"
                      sx={{tmt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}
                    >
                        {t('translation:membership.2.modal2.p1')}
                    </Typography>
                    <Typography
                      id="modal-modal-description"
                      sx={{mt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}
                    >
                        {t('translation:membership.2.modal2.p2')}
                    </Typography>
                    <ButtonWidget
                      label={t('translation:identity.6.form.q3.modal.button')}
                      handleAction={() => setOpen2(undefined)}
                      py={0.1}
                      fontSize={1.2}
                      blueLight
                    />
                  </div>
                }
            </CustomModal>
        </BoxFormat>
    );
};

export default FormBeneficiary;

import { KycState, updateBeneficiary, updatePayment } from '../../features/kyc/kycSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useTranslation } from 'react-i18next';
import { Box, MenuItem, Select, styled } from '@mui/material';
import React, { useEffect } from 'react';
import theme from '../../theme';
import { next } from '../../features/navigation/navigationSlice';
import ButtonContinue from '../ButtonContinue';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextFieldWidget from '../TextFieldWidget';
import H6 from '../H6';
import CheckboxWidget from '../CheckboxWidget';
import { getBeneficiariesApi, postAddBeneficiaries, updateBeneficiaries } from '../../utils/beneficiaries';

const BoxFormat = styled(Box)({
    display: 'flex',
    alignItems: 'flex-start',
    color: theme.typography.caption.color,
    marginTop: 10,
});

const SelectFormat = styled(Select)({
    ml: 1.5,
    height: '3.5rem',
    minWidth: '6rem',
});

const FormPayment = () => {
    const kyc: KycState = useAppSelector((state) => state.kyc);
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    let isClientAccountDebited: boolean = !kyc.payerInformation;
    const initialeClientAccountDebited: string = kyc.payerInformation ? 'false' : 'true';
    const accountDebitedOptions = [
        {value: 'true', text: t('translation:placeholderForm.yes')},
        {value: 'false', text: t('translation:placeholderForm.no')},
        {value: '', text: ''}
    ];

    useEffect(() => {
        const fetchData = async () => {
            if (kyc.beneficiaries) {
                const data = getBeneficiariesApi(kyc, kyc.beneficiaries);

                if (data.length > 0) {
                    const resp = await dispatch(postAddBeneficiaries(data));
                    const beneficiaries = updateBeneficiaries(resp.data.beneficiaires, resp.data.beneficiairesPM);
                    beneficiaries.forEach(beneficiary => {
                        dispatch(updateBeneficiary({
                            // @ts-ignore
                            physicalFirstname: beneficiary.nom,
                            // @ts-ignore
                            physicalLastname: beneficiary.nom,
                            // @ts-ignore
                            companyName: beneficiary.nom,
                            // @ts-ignore
                            id: beneficiary.id
                        }));
                    });
                }
            }
        };

        fetchData();
    }, []);

    const validationSchema = Yup.object().shape({
        isAccountDebit: Yup.string()
            .required(t('translation:errors.required')).nullable(),
        iban: Yup.string()
            .matches(/^FR\d{12}[A-Z0-9]{11}\d{2}$/, t('translation:errors.iban'))
            .required(t('translation:errors.required')).nullable(),
        bic: Yup.string()
            .matches(/^\w{8,11}$/, t('translation:errors.bic'))
            .required(t('translation:errors.required')).nullable(),
        debitDay: Yup.string()
            .required(t('translation:errors.required')).nullable(),
        isRecurringPayment: Yup.string()
            .required(t('translation:errors.required')).nullable(),
        periodicity: Yup.string()
            .when('isRecurringPayment', {
                is: true,
                then: Yup.string().required(t('translation:errors.required')).nullable(),
            }),
        payerInformation: Yup.object()
            .when('isAccountDebit', {
                is: (f: string) => f === 'false',
                then: Yup.object({
                    lastname: Yup.string()
                        .required(t('translation:errors.required')).nullable(),
                    firstname: Yup.string()
                        .required(t('translation:errors.required')).nullable(),
                    address: Yup.string()
                        .required(t('translation:errors.required')).nullable(),
                    zipcode: Yup.string()
                        .matches(/^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/, t('translation:errors.zipcode'))
                        .required(t('translation:errors.required')).nullable(),
                    city: Yup.string()
                        .required(t('translation:errors.required')).nullable(),
                })
                    .required(t('translation:errors.required')).nullable(),
            }),
        agreementDebit: Yup.boolean()
            .oneOf([true], t('translation:errors.check')),
    });

    const initialValues = {
        isAccountDebit: initialeClientAccountDebited,
        iban: kyc.iban || '',
        bic: kyc.bic || '',
        debitDay: kyc.debitDay,
        isRecurringPayment: kyc.isRecurringPayment,
        periodicity: kyc.periodicity,
        payerInformation: kyc.payerInformation,
        agreementDebit: kyc.agreementDebit,
    };

    const validNextStep = (values: any) => {
        dispatch(updatePayment({
            iban: values.iban,
            bic: values.bic,
            debitDay: values.debitDay,
            isRecurringPayment: values.isRecurringPayment,
            agreementDebit: values.agreementDebit,
            periodicity: !values.isRecurringPayment ? undefined : values.periodicity.toString(),
            payerInformation: !values.isAccountDebit ? undefined : values.payerInformation,
            isAccountDebit: values.isAccountDebit === 'true'
        }));

        dispatch(next());
    };

    return (
        <BoxFormat>
            <Formik
                onSubmit={values => validNextStep(values)}
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                {({
                      errors,
                      handleSubmit,
                      values,
                      handleChange,
                      touched,
                      setFieldValue,
                  }) => (
                    <form style={{width: '-webkit-fill-available'}} onSubmit={handleSubmit}>
                        <BoxFormat marginLeft={25}>
                            <H6 style={{fontSize: 24}}>{t('translation:membership.5.holder-account')}</H6>
                            <SelectFormat
                                name={'isAccountDebit'}
                                value={values.isAccountDebit}
                                id={'isAccountDebit'}
                                onChange={event => {
                                    handleChange(event);
                                    isClientAccountDebited = !isClientAccountDebited;
                                }}
                            >
                                {accountDebitedOptions.map(r => (
                                    <MenuItem
                                        key={r.text}
                                        value={r.value}
                                    >
                                        {r.text}
                                    </MenuItem>
                                ))}
                            </SelectFormat>
                        </BoxFormat>

                        {values.isAccountDebit === 'false' &&
                          <BoxFormat sx={{display: 'unset', marginLeft: 25}}>
                            <H6 style={{fontSize: 24}}>{t('translation:membership.5.payer-information')}</H6>
                            <BoxFormat marginLeft={25}>
                              <TextFieldWidget
                                name={'payerInformation.lastname'}
                                onChange={handleChange}
                                id={'membership-payer-information-lastname'}
                                placeholder={t('translation:placeholderForm.lastname')}
                                  // @ts-ignore
                                value={values.payerInformation?.lastname}
                                  // @ts-ignore
                                helperText={touched.payerInformation && errors?.payerInformation?.lastname}
                              />
                              <TextFieldWidget
                                name={'payerInformation.firstname'}
                                onChange={handleChange}
                                id={'membership-payer-information-firstname'}
                                placeholder={t('translation:placeholderForm.firstname')}
                                  // @ts-ignore
                                value={values.payerInformation?.firstname}
                                  // @ts-ignore
                                error={Boolean(touched.payerInformation && errors?.payerInformation?.firstname)}
                                  // @ts-ignore
                                helperText={touched.payerInformation && errors?.payerInformation?.firstname}
                              />
                            </BoxFormat>
                            <BoxFormat marginLeft={25}>
                              <TextFieldWidget
                                name={'payerInformation.address'}
                                minWidth={32}
                                onChange={handleChange}
                                id={'membership-payer-information-address'}
                                placeholder={t('translation:placeholderForm.address')}
                                  // @ts-ignore
                                value={values.payerInformation?.address}
                                  // @ts-ignore
                                error={Boolean(touched.payerInformation && errors?.payerInformation?.address)}
                                  // @ts-ignore
                                helperText={touched.payerInformation && errors?.payerInformation?.address}
                              />
                            </BoxFormat>
                            <BoxFormat marginLeft={25}>
                              <TextFieldWidget
                                name={'payerInformation.zipcode'}
                                onChange={handleChange}
                                id={'membership-payer-information-zipcode'}
                                placeholder={t('translation:placeholderForm.zipcode')}
                                  // @ts-ignore
                                value={values.payerInformation?.zipcode}
                                  // @ts-ignore
                                error={Boolean(touched.payerInformation && errors?.payerInformation?.zipcode)}
                                  // @ts-ignore
                                helperText={touched.payerInformation && errors?.payerInformation?.zipcode}
                              />
                              <TextFieldWidget
                                name={'payerInformation.city'}
                                onChange={handleChange}
                                id={'membership-payer-information-city'}
                                placeholder={t('translation:placeholderForm.city')}
                                  // @ts-ignore
                                value={values.payerInformation?.city}
                                  // @ts-ignore
                                error={Boolean(touched.payerInformation && errors?.payerInformation?.city)}
                                  // @ts-ignore
                                helperText={touched.payerInformation && errors?.payerInformation?.city}/>
                            </BoxFormat>
                          </BoxFormat>
                        }
                        <BoxFormat marginLeft={25}>
                            <H6 style={{fontSize: 24}}>{t('translation:membership.5.account-debited')}</H6>
                        </BoxFormat>
                        <BoxFormat marginLeft={25}>
                            <H6 style={{fontSize: 24}}>{t('translation:membership.5.iban')}</H6>
                            <TextFieldWidget
                                name="iban"
                                onChange={handleChange}
                                id={'membership-iban'}
                                value={values.iban}
                                error={Boolean(touched.iban && errors.iban)}
                                // @ts-ignore
                                helperText={touched.iban && errors.iban}
                                minWidth={26}
                                maxWidth={29}
                            />
                        </BoxFormat>
                        <BoxFormat marginLeft={25}>
                            <H6 style={{fontSize: 24}}>{t('translation:membership.5.bic')}</H6>
                            <TextFieldWidget
                                name="bic"
                                onChange={handleChange}
                                id={'membership-bic'}
                                value={values.bic}
                                error={Boolean(touched.bic && errors.bic)}
                                // @ts-ignore
                                helperText={touched.bic && errors.bic}
                                minWidth={9}
                                maxWidth={23}
                            />
                        </BoxFormat>
                        <BoxFormat marginLeft={25}>
                            <H6 style={{fontSize: 24}}>{t('translation:membership.5.debit-day')}</H6>
                            <SelectFormat
                                sx={{height: '3.2rem'}}
                                id={'debitDay'}
                                value={values.debitDay}
                                error={!!errors.debitDay}
                                onChange={e => setFieldValue('debitDay', e.target.value)}
                            >
                                {Object.keys(t('translation:membership.5.frequencies', {returnObjects: true})).map((item) =>
                                    <MenuItem
                                        key={item}
                                        value={item}
                                    > {t(`translation:membership.5.frequencies.${item}`)} </MenuItem>
                                )}
                            </SelectFormat>
                        </BoxFormat>
                        {values.isRecurringPayment &&
                          <BoxFormat marginLeft={25}>
                            <H6 style={{fontSize: 24}}>{t('translation:membership.5.periodicity')}</H6>
                            <SelectFormat
                              sx={{height: '3.2rem'}}
                              id="periodicity"
                              name="periodicity"
                              onChange={handleChange}
                              value={values.periodicity}
                              error={!!errors.periodicity}
                            >
                                {Object.keys(t('translation:membership.5.periodicities', {returnObjects: true})).map((item) =>
                                    <MenuItem
                                        value={item}
                                        key={item}
                                    > {t(`translation:membership.5.periodicities.${item}`)} </MenuItem>
                                )}
                            </SelectFormat>
                          </BoxFormat>
                        }
                        <BoxFormat sx={{margin: '1rem 10rem 0 12.75rem'}}>
                            <CheckboxWidget
                                id="agreementDebit"
                                name="agreementDebit"
                                onClick={() => {
                                    setFieldValue('agreementDebit', !kyc.agreementDebit);
                                    dispatch(updatePayment({agreementDebit: !kyc.agreementDebit}));
                                }}
                                value={kyc.agreementDebit}
                            >
                                <H6 style={{
                                    fontSize: 22,
                                    lineHeight: '1.45'
                                }}>{t('translation:membership.5.agreementDebit')}</H6>
                            </CheckboxWidget>
                        </BoxFormat>
                        <ButtonContinue my={3} mt={3} fs={15} disabledSkip={true}/>
                    </form>
                )}
            </Formik>
        </BoxFormat>
    );
};

export default FormPayment;

import { Box, Button, Card, CardActions, CardContent, Chip, Icon, styled } from '@mui/material';
import React, { FunctionComponent, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    addLifeInsuranceContract,
    addLifeInsuranceContractSpouse,
    KycState,
    LifeInsuranceInfos,
    removeLifeInsuranceContract,
    removeLifeInsuranceContractSpouse,
} from '../../features/kyc/kycSlice';
import ButtonWidget from '../ButtonWidget';
import Typography from '@mui/material/Typography';
import CustomModal from '../CustomModal';
import { useTranslation } from 'react-i18next';
import theme from '../../theme';
import H6 from '../H6';
import TextFieldWidget from '../TextFieldWidget';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { next } from '../../features/navigation/navigationSlice';

const BoxFormat = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    color: theme.typography.caption.color,
    marginLeft: '15.625rem',
    marginTop: '0.625rem',
    marginBottom: '0.625rem',
});

type isUsersProps = { isUser: boolean; };
const Identity5Form: FunctionComponent<isUsersProps> = (props: isUsersProps) => {

    const kyc: KycState = useAppSelector((state) => state.kyc);
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const isUser = props.isUser;
    const var1 = isUser ? 7 : 19;

    /* Help Modal : local state management */
    const [open, setOpen] = useState<number>();

    const validationSchema = Yup.object().shape({
        organism: Yup.string()
            .required(t('translation:errors.required')).nullable(),
        contractNumber: Yup.number()
            .positive(t('translation:errors.number-positive'))
            .integer(t('translation:errors.integer'))
            .required(t('translation:errors.required')).nullable(),
        totalAmount: Yup.number()
            .positive(t('translation:errors.number-positive'))
            .required(t('translation:errors.required')).nullable()
    });

    const lifeInsurance = (): LifeInsuranceInfos[] | undefined => {
        return isUser ? kyc.lifeInsurance : kyc.lifeInsuranceSpouse;
    };

    const contractsNumber = (a: LifeInsuranceInfos): number | undefined => {
        return isUser ? a.contractsNumber : a.contractsNumberSpouse;
    };

    const removeLifeInsuranceContractWillDispatch = (values: LifeInsuranceInfos): object => {
        return {
            contractOrOrganizationName: values.contractOrOrganizationName,
            contractsNumber: Number(values.contractsNumber),
            totalAmount: Number(values.totalAmount)
        };
    };

    const removeLifeInsuranceContractSpouseWillDispatch = (values: LifeInsuranceInfos): object => {
        return {
            contractOrOrganizationNameSpouse: values.contractOrOrganizationNameSpouse,
            contractsNumberSpouse: Number(values.contractsNumberSpouse),
            totalAmountSpouse: Number(values.totalAmountSpouse)
        };
    };

    const removeDispatch = (values: LifeInsuranceInfos) => {
        return isUser ? removeLifeInsuranceContract(removeLifeInsuranceContractWillDispatch(values)) :
            removeLifeInsuranceContractSpouse(removeLifeInsuranceContractSpouseWillDispatch(values));
    };

    type contractObject = { organism: string, contractNumber: string, totalAmount: string; };
    const lifeInsuranceContractWillDispatch = (values: contractObject): object => {
        return isUser ? {
                contractOrOrganizationName: values.organism,
                contractsNumber: Number(values.contractNumber),
                totalAmount: Number(values.totalAmount)
            } :
            {
                contractOrOrganizationNameSpouse: values.organism,
                contractsNumberSpouse: Number(values.contractNumber),
                totalAmountSpouse: Number(values.totalAmount)
            };
    };


    const formik = useFormik({
        initialValues: {
            organism: '',
            contractNumber: '',
            totalAmount: ''
        },
        validationSchema,
        onSubmit: values => {
            dispatch(isUser ? addLifeInsuranceContract(lifeInsuranceContractWillDispatch(values)) :
                addLifeInsuranceContractSpouse(lifeInsuranceContractWillDispatch(values)));
            formik.resetForm();
        }
    });

    const handleContinu = () => {
        dispatch(next());
    };

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <BoxFormat>
                    <H6>{t(`translation:identity.7.subtitle`)}</H6>
                </BoxFormat>
                <BoxFormat>
                    <label htmlFor="organisme"><H6>{t('translation:identity.7.form.q1.label')}</H6></label>
                    <TextFieldWidget
                        placeholder={''}
                        id="organism"
                        minWidth={25}
                        onChange={formik.handleChange}
                        value={formik.values.organism}
                        helperText={formik.errors.organism}
                    />
                </BoxFormat>
                <BoxFormat>
                    <label htmlFor="contractNumber"><H6>{t('translation:identity.7.form.q2.label')}</H6></label>
                    <TextFieldWidget
                        placeholder={''}
                        id="contractNumber"
                        minWidth={12.5}
                        onChange={formik.handleChange}
                        value={formik.values.contractNumber}
                        helperText={formik.errors.contractNumber}
                    />
                </BoxFormat>

                <BoxFormat>
                    <label htmlFor="totalAmount"><H6>{t('translation:identity.7.form.q3.label')}</H6></label>
                    <TextFieldWidget
                        placeholder={''}
                        id="totalAmount"
                        minWidth={12.5}
                        onChange={formik.handleChange}
                        value={formik.values.totalAmount}
                        helperText={formik.errors.totalAmount}
                    />
                </BoxFormat>

                <ButtonWidget label={t('translation:identity.7.form.button')} type="submit" py={0.1}/>
            </form>
            <BoxFormat flexWrap="wrap" mx="auto" sx={{marginTop: 2, justifyContent: 'center', marginLeft: 0}}>
                {lifeInsurance()?.map((a, k) =>
                    <Card sx={{mx: 2, my: 2, minWidth: 400, minHeight: 150}} key={k}>
                        <CardContent sx={{fontSize: 25}}>
                            {isUser ? a.contractOrOrganizationName : a.contractOrOrganizationNameSpouse}
                        </CardContent>
                        <CardActions>
                            <Button
                                sx={{color: theme.palette.text.secondary}}
                                onClick={() => dispatch(removeDispatch(a))}
                            >
                                <Icon sx={{fontSize: 35}}>{t('translation:placeholderForm.delete')}</Icon>
                            </Button>
                            <Chip sx={{fontSize: 15}}
                                  label={`${contractsNumber(a)} contrat${contractsNumber(a) ! > 1 ? 's' : ''}`}/>
                            <Chip sx={{fontSize: 15}}
                                  label={`${Intl.NumberFormat().format((isUser ? a.totalAmount : a.totalAmountSpouse)!)} €`}/>
                        </CardActions>
                    </Card>)
                }
            </BoxFormat>

            {lifeInsurance()!.length > 0 &&
              <ButtonWidget label={t('translation:continue')} handleAction={handleContinu}/>
            }

            <CustomModal open={open !== undefined} handleModal={() => setOpen(undefined)}>
                {open === 3 &&
                  <div>
                    <Typography
                      id="modal-modal-description"
                      sx={{mt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}
                    >
                        {t('translation:identity.6.form.q3.modal.p')}
                    </Typography>
                    <ul>
                        {[1, 2, 3].map((num) => (
                            <li>{t(`translation:identity.6.form.q3.modal.li${num}`)}</li>
                        ))}
                    </ul>
                    <ButtonWidget
                      label={t('translation:identity.6.form.q3.modal.button')}
                      handleAction={() => setOpen(undefined)}
                      py={0.1}
                      fontSize={1.2}
                    />
                  </div>
                }
            </CustomModal>
        </div>
    );
};

export default Identity5Form;

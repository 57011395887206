import { httpApiPost } from './http.js';
import {
    Civility,
    Dependent,
    ElectedMandat,
    FamilySituation,
    FamilySituationMutac,
    isConcerned,
    KycState
} from '../features/kyc/kycSlice';
import { getValueByKey, handleValue } from './enum';
import { formatDate } from './dda';

export const postSavePerson = (kyc: KycState, user = true, spouseKnown = false) => async () => {
    return httpApiPost(
        '/person/save',
        {
            'id': (user ? kyc.idAccount : kyc.idAccountSpouse) ?? 0,
            'numeroAdherent': (user ? kyc.membershipId : kyc.membershipIdSpouse) ?? null,
            'civilite': Civility[`${Number(user ? kyc.civility : kyc.civilitySpouse)}`],
            'sexe': Civility[`${Number(user ? kyc.civility : kyc.civilitySpouse)}`] === 'Monsieur' ? 'M' : 'F',
            'nom': user ? kyc.lastname?.toUpperCase() : kyc.lastnameSpouse?.toUpperCase(),
            'prenom': user ? kyc.firstname?.toUpperCase() : kyc.firstnameSpouse?.toUpperCase(),
            'dateNaissance': user ? formatDate(kyc.birthDate) : formatDate(kyc.birthDateSpouse),
            'email': kyc.email,
            'telephone': kyc.phoneNumber,
            'typeProtection': null,
            'numeroSecuriteSociale': (user ? kyc.socialSecurityNumber : null) ?? null,
            'nomNaissance': (user ? kyc.birthName : null) ?? null,
            'situationFamiliale': null,
            'villeNaissance': (user ? kyc.fromCity : null) ?? null,
            'numDepartementNaissance': (user ? kyc.fromCountyId : null) ?? null,
            'profession': (user ? kyc.profession : null) ?? null,
            'obsequeEnvisage': null,
            'mandatElectif': getValueByKey(ElectedMandat, kyc.electedMandate) === ElectedMandat.Yes ? '77' : '78',
            'epIsExerceeUpdatedat': null,
            'voie': getAddress(kyc),
            'codePostal': kyc.address?.zipcode,
            'ville': kyc.address?.city,
            'partenaire': process.env.REACT_APP_PARTENAIRE,
            'nbEnfant': handleValue(Dependent, kyc.dependent) < 6 ? (handleValue(Dependent, kyc.dependent)) : null,
            'conjoint': spouseKnown ? {
                'id': (user ? kyc.idAccountSpouse : kyc.idAccount) ?? 0,
                'numeroAdherent': (user ? kyc.membershipIdSpouse : kyc.membershipId) ?? null,
                'civilite': Civility[`${Number(user ? kyc.civilitySpouse : kyc.civility)}`],
                'sexe': Civility[`${Number(user ? kyc.civilitySpouse : kyc.civility)}`] === 'Monsieur' ? 'M' : 'F',
                'nom': user ? kyc.lastnameSpouse?.toUpperCase() : kyc.lastname?.toUpperCase(),
                'prenom': user ? kyc.firstnameSpouse?.toUpperCase() : kyc.firstname?.toUpperCase(),
                'dateNaissance': user ? formatDate(kyc.birthDateSpouse) : formatDate(kyc.birthDate),
                'email': kyc.email,
                'telephone': kyc.phoneNumber,
                'typeProtection': null,
                'situationFamiliale': isConcerned[`${Number(kyc.whoIsConcerned)}`] ?? null,
                'obsequeEnvisage': null,
                'mandatElectif': (user ? kyc.electedMandateSpouse : kyc.electedMandate) ? 1 : 0,
                'epIsExerceeupdatedat': null,
                'voie': `${kyc.address?.number} ${kyc.address?.street} ${kyc.address?.complement}`,
                'codePostal': kyc.address?.zipcode,
                'ville': kyc.address?.city,
                'etablissement': process.env.REACT_APP_CODE_ESTABLISHMENT,
                'etablissementNrid': process.env.REACT_APP_CODE_ESTABLISHMENT_NRID,
                'partenaire': process.env.REACT_APP_PARTENAIRE,
            } : null,
            'etablissement': process.env.REACT_APP_CODE_ESTABLISHMENT,
            'etablissementNrid': process.env.REACT_APP_CODE_ESTABLISHMENT_NRID,
        }
    );
};

export const postSaveProspect = (kyc: KycState) => async () => {
    await httpApiPost(
        '/person/save-conjoint-link',
        {
            'prospectNRID': kyc.idAccount,
            'conjointNRID': kyc.idAccountSpouse
        }
    );
};

export const postSearchPersonByNrid = (idAccount: number) => async () => {
    const data = [idAccount, Number(process.env.REACT_APP_CODE_ESTABLISHMENT_NRID)];

    return await httpApiPost(
        '/person/search-by-nrid',
        data
    );
};

export const postSearchPerson = (kyc: KycState) => async () => {
    const data = {
        nom: kyc.lastname?.toUpperCase(),
        prenom: kyc.firstname?.toUpperCase(),
        dateNaissance: formatDate(kyc.birthDate),
        nrids: [Number(process.env.REACT_APP_CODE_ESTABLISHMENT_NRID)],
    };

    return await httpApiPost(
        '/person/search',
        data
    );
};

export const postFamilySituation = (kyc: KycState) => async () => {

    await httpApiPost(
        '/person/update-family-situation',
        {
            'devisNrid': kyc.quotationNrid,
            'personneNrid': kyc.idAccount,
            'personnesACharge': getValueByKey(Dependent, kyc.dependent) > 4 ? null : getValueByKey(Dependent, kyc.dependent, true),
            'situationFamiliale': FamilySituationMutac[handleValue(FamilySituation, kyc.familySituation) - 1]
        }
    );
};

export const getAddress = (kyc: KycState) => {
    let address = '';

    if (kyc.address?.number) {
        address = address + kyc.address?.number;
    }
    if (kyc.address?.street) {
        address = address + ' ' + kyc.address?.street;
    }
    if (kyc.address?.complement) {
        address = address + ' ' + kyc.address?.complement;
    }

    return address.trim();
};

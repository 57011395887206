import { Box } from '@mui/material';
import React, { FunctionComponent, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { KycState, updateAverageCost, updateDepartment } from '../../features/kyc/kycSlice';
import { next } from '../../features/navigation/navigationSlice';
import ButtonWidget from '../ButtonWidget';
import InfoIconWidget from '../InfoIconWidget';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import H6 from '../H6';
import CustomModal from '../CustomModal';
import theme from '../../theme';
import CountySelect from '../CountySelect';
import CostService from '../../utils/cost-service';

const DepartmentForm: FunctionComponent = () => {
    const kyc: KycState = useAppSelector((state) => state.kyc);
    const dispatch = useAppDispatch();
    const {t} = useTranslation();

    /* Help Modal : local state management */
    const [open, setOpen] = useState(false);
    const handleModal = () => setOpen(!open);

    const currentLabelDepartmentValue: string = kyc.departmentId === undefined ? '' : `${kyc.departmentId} - ${kyc.departmentLabel}`;

    const handleNext = async () => {
        if (kyc.departmentId) {
            dispatch(updateAverageCost(CostService.getCost(kyc.departmentId)));
        }
        dispatch(next());
    };

    return (
        <div style={{color: theme.typography.caption.color}}>
            <Box display="flex" alignItems="center" style={{marginLeft: 185}}>
                <InfoIconWidget size="inherit" handleAction={handleModal}/>
                <H6 style={{fontSize: 36}}>{t('translation:funeralCosts.2.p1')}</H6>
                <CountySelect
                    handleAction={(event: any) => {
                        dispatch(updateDepartment({
                            departmentId: event.target.value.toString().split(' - ')[0],
                            departmentLabel: event.target.value.toString().split(' - ')[1]
                        }));
                    }}
                    value={currentLabelDepartmentValue}
                    name={'department'}
                />
            </Box>
            {kyc.departmentId !== undefined &&
              <ButtonWidget
                label={t('translation:funeralCosts.2.button')}
                handleAction={handleNext}
                mt={27}
              />
            }
            <CustomModal open={open} handleModal={() => setOpen(!open)}>
                <div>
                    <Typography id="modal-modal-description"
                                sx={{
                                    mt: 2,
                                    textAlign: 'center',
                                    fontSize: '1.3rem',
                                    color: theme.palette.text.secondary,
                                }}>
                        {t('translation:funeralCosts.2.modal.p1')}
                    </Typography>
                    <Typography
                        sx={{mt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}>
                        {t('translation:funeralCosts.2.modal.p2')}
                    </Typography>
                    <ButtonWidget
                        label={t('translation:funeralCosts.2.modal.button-ok')}
                        handleAction={handleModal} py={0.1}
                        fontSize={1.2}
                        blueLight
                    />
                </div>
            </CustomModal>
        </div>
    );
};

export default DepartmentForm;

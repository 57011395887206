import React, { FunctionComponent, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import DepartmentForm from './DepartmentForm';
import ButtonWidget from '../ButtonWidget';
import { Box, Button, ButtonGroup, Typography } from '@mui/material';
import { KycState, updateAgreement } from '../../features/kyc/kycSlice';
import { next, previous } from '../../features/navigation/navigationSlice';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useTranslation } from 'react-i18next';
import CustomModal from '../CustomModal';
import CheckboxWidget from '../CheckboxWidget';
import theme from '../../theme';
import AuthByURL from './AuthByURL';

const FuneralCostsPage: FunctionComponent = () => {
    const subStep: number = useAppSelector((state) => state.navigation.subStep);
    const kyc: KycState = useAppSelector((state) => state.kyc);
    const dispatch = useAppDispatch();
    const {t} = useTranslation();

    /* Help Modal  : local state management  */
    const [open, setOpen] = useState(false);
    const handleModal = () => setOpen(!open);

    const handleNext = async () => {
        dispatch(next());
    };

    const handleKo = async () => {
        dispatch(updateAgreement(false));
        handleModal();
    };

    const handleOk = async () => {
        dispatch(updateAgreement(true));
        handleModal();
    };

    return (
        <div>
            {/******************************************************************************************************************/}
            {subStep === 1 &&
              <AuthByURL/>
            }
            {/******************************************************************************************************************/}
            {subStep === 2 &&
              <div>
                <Typography
                  variant="h1"
                  style={{lineHeight: 1.5, marginBottom: 170}}
                  dangerouslySetInnerHTML={{__html: t('funeralCosts.2.title')}}
                />
                <DepartmentForm/>
              </div>
            }
            {/******************************************************************************************************************/}
            {subStep === 3 &&
              <>
                <Box display={'flex'} mt={3} justifyContent="center">
                  <Button
                    onClick={() => dispatch(previous())}
                    sx={{
                        justifyContent: 'flex-start',
                        height: 'fit-content',
                        position: 'absolute',
                        left: '5px',
                    }}
                  >
                    <NavigateBeforeIcon fontSize="large" color="primary"/>
                    <span>{t('translation:placeholderForm.back')}</span>
                  </Button>
                  <Typography
                    variant="h1"
                    dangerouslySetInnerHTML={{
                        __html: t('translation:funeralCosts.3.title', {
                            dpt: kyc.departmentId?.toString() + ' - ' + kyc.departmentLabel,
                            cost: kyc.averageCost
                        })
                    }}
                    sx={{marginBottom: 7}}
                  />
                </Box>
                <Box
                  sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      color: theme.typography.caption.color,
                  }}
                  mx={31}
                >
                  <Typography variant="caption"
                              sx={{textAlign: 'center', fontSize: 28, margin: 'auto', lineHeight: 1.5}}
                              dangerouslySetInnerHTML={{__html: t('translation:funeralCosts.3.p')}}/>
                  <Box sx={{margin: 'auto', mt: 7}}>
                    <CheckboxWidget id="agreement" onClick={handleModal} value={kyc.agreement}>
                      <Typography variant="caption"
                                  sx={{fontSize: 19}}
                                  dangerouslySetInnerHTML={{__html: t('translation:funeralCosts.3.check')}}/>
                    </CheckboxWidget>
                  </Box>
                </Box>
                <ButtonWidget
                  my={1}
                  label={t('translation:funeralCosts.3.button')}
                  handleAction={handleNext}
                  disabled={!kyc.agreement}
                />
                <CustomModal open={open} handleModal={() => setOpen(!open)}>
                  <div>
                    <Typography id="modal-modal-description"
                                sx={{
                                    mt: 2,
                                    textAlign: 'center',
                                    fontSize: '1.3rem',
                                    color: theme.palette.text.secondary
                                }}>
                        {t('translation:funeralCosts.3.modal.p1')}
                    </Typography>
                    <Typography
                      sx={{mt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}>
                        {t('translation:funeralCosts.3.modal.p2')}
                    </Typography>
                    <Typography
                      sx={{mt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}
                      dangerouslySetInnerHTML={{__html: `${t('translation:funeralCosts.3.modal.p3')}`}}
                    />
                    <Box className="uk-text-center">
                      <ButtonGroup>
                        <ButtonWidget
                          label={t('translation:funeralCosts.3.modal.button-ko')}
                          handleAction={handleKo}
                          py={0.2}
                          mx={5}
                          fontSize={1.2}
                          blueLight
                        />
                        <ButtonWidget
                          label={t('translation:funeralCosts.3.modal.button-ok')}
                          handleAction={handleOk}
                          py={0.2}
                          mx={5}
                          fontSize={1.2}
                          blueLight
                        />
                      </ButtonGroup>
                    </Box>
                  </div>
                </CustomModal>
              </>
            }
        </div>
    );
};

export default FuneralCostsPage;


import { Box } from '@mui/material';
import React, { FunctionComponent, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    Civility,
    Dependent,
    FamilySituation,
    FamilySituationMutac,
    KycState,
    updateAccount,
    updateFamilySituation,
    updateFromOtherInfo,
    updateIdentity,
    updateIdentity5,
    updateIdentityContact,
    updateQuotationNrid,
    updateSendQuote
} from '../../features/kyc/kycSlice';
import ButtonWidget from '../ButtonWidget';
import { useTranslation } from 'react-i18next';
import SelectWidget from '../SelectWidget';
import H6 from '../H6';
import theme from '../../theme';
import { next } from '../../features/navigation/navigationSlice';
import { handleValueIsOne, oneOrTwo } from '../../utils/dda';
import { getActiveOffer, OffersState } from '../../features/offers/offersSlice';
import {
    postLinkQuotationGuid,
    postQuotationInsurance,
    postQuotationThrift,
    sendMailToClient
} from '../../utils/quotation';
import Loader from '../Loader';
import { AxiosResponse } from 'axios';
import { postSavePerson, postSearchPerson } from '../../utils/person';
import { handleValue } from '../../utils/enum';

const SendQuote: FunctionComponent = () => {
    const kyc: KycState = useAppSelector((state) => state.kyc);
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const q1: string[] = [t('translation:identity.2.form.q4.choice1'), t('translation:identity.2.form.q4.choice2')];
    const offers: OffersState = useAppSelector((state) => state.offers);
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        const offer = getActiveOffer(offers);
        let resp: AxiosResponse;
        let respLink: AxiosResponse;
        let folderGuidToQuotation = '';
        let responsePerson: AxiosResponse;
        let idAccount: number;

        responsePerson = await dispatch(postSearchPerson(kyc));

        if (responsePerson.data.length === 0) {
            responsePerson = await dispatch(postSavePerson(kyc));
            idAccount = responsePerson?.data.id;
        } else {
            const resp = responsePerson.data[0];
            dispatch(updateIdentity({
                civility: handleValue(Civility, resp.civilite),
                address: {
                    street: resp.voie,
                    zipcode: resp.codePostal,
                    city: resp.ville,
                }
            }));
            dispatch(updateIdentity5({
                electedMandate: resp.mandatElectif,
            }));
            dispatch(updateFromOtherInfo({
                socialSecurityNumber: resp.numeroSecuriteSociale,
                birthName: resp.nomNaissance,
                fromCity: resp.villeNaissance,
                profession: resp.profession,
            }));
            dispatch(updateFamilySituation({
                dependent: Dependent[resp.nbEnfant],
                familySituation: FamilySituation[handleValue(FamilySituationMutac, resp.situationFamiliale)],
            }));
            dispatch(updateIdentityContact({
                email: resp.email,
                phoneNumber: resp.telephone,
            }));
            idAccount = resp.id;
        }

        dispatch(updateAccount({idAccount: idAccount}));
        if (offer.isThrift) {
            resp = await dispatch(postQuotationThrift(kyc, idAccount, offer));
        } else {
            resp = await dispatch(postQuotationInsurance(kyc, idAccount, offer));
        }

        if (resp.data) {
            dispatch(updateQuotationNrid({
                quotationNrid: resp.data.devisNRID,
                quotationRef: resp.data.devisRef,
                creationDate: resp.data.devisDateEdit
            }));
            respLink = await dispatch(postLinkQuotationGuid(resp.data.devisNRID));

            folderGuidToQuotation = respLink.data;
        }
        if (resp.data) {
            dispatch(updateQuotationNrid({folderGuidToQuotation: folderGuidToQuotation}));
            dispatch(sendMailToClient(kyc, resp.data.devisNRID, folderGuidToQuotation, idAccount));
        }
        setLoading(false);

        dispatch(next());
    };

    return (

        <>
            {loading ?
                (
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '5rem'}}>
                        <Loader size={'10rem'} color={'light'}/>
                    </Box>
                ) : (
                    <>
                        <Box
                            my={4}
                            ml={5}
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                marginLeft: '11.5rem',
                                flexDirection: 'column',
                                color: theme.typography.caption.color,
                            }}
                        >
                            <H6>{t('translation:identity.12.p1')}</H6>
                            <Box>
                                <H6>{t('translation:identity.12.q1')}</H6>
                                <SelectWidget
                                    id={'sendQuote'}
                                    value={oneOrTwo(kyc.sendQuote)}
                                    onChange={(event) => {
                                        dispatch(updateSendQuote({sendQuote: handleValueIsOne(event.target.value)}));
                                    }}
                                    object={q1}
                                />
                            </Box>
                            <H6>{t('translation:identity.12.p2')}</H6>
                            <ul style={{
                                fontSize: '1.8rem',
                                fontFamily: theme.typography.h6.fontFamily,
                                listStyleType: 'none',
                            }}>
                                <li>{t('translation:identity.12.li1')}</li>
                                <li>{t('translation:identity.12.li2')}</li>
                                <li>{t('translation:identity.12.li3')}</li>
                                <li>{t('translation:identity.12.li4')}</li>
                            </ul>
                        </Box>

                        {kyc.sendQuote !== undefined &&
                          <ButtonWidget label={t('translation:identity.12.button')} handleAction={() => {
                              setLoading(true);
                              fetchData();
                          }}/>
                        }
                    </>
                )
            }
        </>
    );
};

export default SendQuote;

import { Box, Button } from '@mui/material';
import React, { FunctionComponent, MouseEventHandler } from 'react';
import { useAppDispatch } from '../app/hooks';
import { next, nextWithSkip } from '../features/navigation/navigationSlice';
import { useTranslation } from 'react-i18next';
import theme from '../theme';

type buttonContinueProps = {
    skipNextStep?: boolean,
    handleWithModal?: MouseEventHandler,
    my?: number,
    mt?: number,
    fs?: number,
    disabledSkip?: boolean,
    blueLight?: boolean,
};
const ButtonContinue: FunctionComponent<buttonContinueProps> = (props: buttonContinueProps) => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    let colorButton = theme.palette.primary.main;

    if (props.blueLight) {
        colorButton = theme.palette.secondary.main;
    }

    const nextStep = () => {
        if (!props.disabledSkip) {
            if (props.skipNextStep)
                dispatch(nextWithSkip());
            else
                dispatch(next());
        }
    };

    return (
        <Box
            sx={{
                textAlign: 'center',
                position: 'relative',
            }}
        >
            <Button variant="outlined"
                    sx={{
                        '&:hover': {backgroundColor: colorButton},
                        borderRadius: 20,
                        px: 4,
                        py: 1,
                        mx: 0,
                        my: props.my ?? 7,
                        mt: props.mt ?? 7,
                        fontSize: props.fs ?? '1.25rem',
                    }}
                    onClick={props.handleWithModal ?? nextStep}
                    type={'submit'}
            >{t('translation:continue')}</Button>
        </Box>
    );
};

export default ButtonContinue;

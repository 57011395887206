import { Box, ButtonGroup, styled, Typography } from '@mui/material';
import React, { FunctionComponent, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ChargePourcent, Income, KycState, updateFinancial, Upgrade, } from '../../features/kyc/kycSlice';
import { next } from '../../features/navigation/navigationSlice';
import ButtonWidget from '../ButtonWidget';
import InfoIconWidget from '../InfoIconWidget';
import CustomModal from '../CustomModal';
import { useTranslation } from 'react-i18next';
import theme from '../../theme';
import H6 from '../H6';
import SelectWidget from '../SelectWidget';
import H3 from '../H3';
import { getValueByKey, handleEnum, handleValue } from '../../utils/enum';

const BoxFormat = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    marginLeft: 250,
    color: theme.typography.caption.color,
});

const TypographyStyled = styled(Typography)({
    mt: 2,
    textAlign: 'center',
    fontSize: '1.3rem',
    color: theme.palette.text.secondary,
});

const FinancialForm: FunctionComponent = () => {
    const kyc: KycState = useAppSelector((state) => state.kyc);
    const dispatch = useAppDispatch();
    const {t} = useTranslation();

    /* Help Modal : local state management */
    const [open, setOpen] = useState<number>();
    const handleModal = (index: number) => setOpen(open === index ? undefined : index);

    const incomeValues: string[] = [];
    for (let i = 1; i <= Object.values(Income).length / 2; i++) {
        incomeValues.push(t(`translation:financial.2.form.q1.choice${i}`));
    }
    const chargeValues: string[] = [];
    for (let i = 1; i <= Object.values(ChargePourcent).length / 2; i++) {
        chargeValues.push(t(`translation:financial.2.form.q2.choice${i}`));
    }
    const upgradeValues: string[] = [];
    for (let i = 1; i <= Math.round(Object.values(Upgrade).length / 2); i++) {
        upgradeValues.push(t(`translation:financial.2.form.q3.choice${i}`));
    }

    const handleContinu = () => {
        if ((kyc.income && getValueByKey(Income, kyc.income) === Income.DoNotKnow) ||
            (kyc.chargePourcent && getValueByKey(Income, kyc.chargePourcent) === ChargePourcent.DoNotKnow) ||
            (kyc.upgrade && getValueByKey(Income, kyc.upgrade) === Upgrade.DoNotKnow)) {
            setOpen(9);
        } else {
            dispatch(next());
        }
    };

    return (
        <div>
            <Typography variant="caption"
                        sx={{textAlign: 'center', fontSize: 25, margin: 'auto', lineHeight: 1.4}}
                        dangerouslySetInnerHTML={{__html: t('translation:financial.2.form.p')}}/>
            <BoxFormat mt={5}>
                <H6>{t('translation:financial.2.form.q1.label')}</H6>
                <SelectWidget
                    id={'income'}
                    value={handleValue(Income, kyc.income) || ''}
                    onChange={(event) => {
                        dispatch(updateFinancial({income: handleEnum(Income, event.target.value)}));
                    }}
                    object={incomeValues}
                />
            </BoxFormat>
            <BoxFormat my={3}>
                <H6>{t('translation:financial.2.form.q2.label1')}</H6>
                <SelectWidget
                    id={'chargePourcent'}
                    value={handleValue(ChargePourcent, kyc.chargePourcent) || ''}
                    onChange={(event) => {
                        dispatch(updateFinancial({chargePourcent: handleEnum(ChargePourcent, event.target.value)}));
                    }}
                    object={chargeValues}
                />
                <H6>{t('translation:financial.2.form.q2.label2')}</H6>
            </BoxFormat>
            <BoxFormat sx={{marginLeft: 23}}>
                <InfoIconWidget size="large" handleAction={() => handleModal(3)}/>
                <H6>{t('translation:financial.2.form.q3.label')}</H6>
                <SelectWidget
                    id={'upgrade'}
                    value={handleValue(Upgrade, kyc.upgrade) || ''}
                    onChange={(event) => {
                        dispatch(updateFinancial({upgrade: handleEnum(Upgrade, event.target.value)}));
                    }}
                    object={upgradeValues}
                />
            </BoxFormat>

            {kyc.income !== undefined &&
                kyc.chargePourcent !== undefined &&
                kyc.upgrade !== undefined &&
              <ButtonWidget
                mt={7}
                handleAction={handleContinu}
                label={t('translation:continue')}
              />
            }

            <CustomModal open={open !== undefined} handleModal={() => setOpen(undefined)}>
                {open === 9 &&
                  <div>
                    <H3 type center text={t('translation:financial.2.incomplete.title')}/>
                    <TypographyStyled id="modal-modal-description">
                        {t('translation:financial.2.incomplete.modal.p1', {cost: kyc.averageCost})}
                    </TypographyStyled>
                    <TypographyStyled>
                        {t('translation:financial.2.incomplete.modal.p2')}
                    </TypographyStyled>
                    <TypographyStyled>
                        {t('translation:financial.2.incomplete.modal.p3')}
                    </TypographyStyled>
                    <BoxFormat sx={{justifyContent: 'center', marginLeft: 0, mt: 4}} className="uk-text-center">
                      <ButtonGroup>
                        <ButtonWidget
                          label={t('translation:financial.2.incomplete.modal.button-change')}
                          handleAction={() => setOpen(undefined)} py={0.2} mx={1}
                          fontSize={1.2}
                        />
                        <ButtonWidget
                          label={t('translation:financial.2.incomplete.modal.button-next')}
                          handleAction={() => dispatch(next())} py={0.2} mx={1}
                          fontSize={1.2}
                        />
                      </ButtonGroup>
                    </BoxFormat>
                  </div>
                }
                {open === 3 &&
                  <div style={{textAlignLast: 'center'}}>
                    <img alt="upgradeArray" src="assets/upgradeArray.png"/>
                    <TypographyStyled
                      id="modal-modal-description"
                    >
                        {t('translation:financial.2.form.q3.modal.p1')}
                    </TypographyStyled>
                    <TypographyStyled>
                        {t('translation:financial.2.form.q3.modal.p2')} <span
                      style={{color: 'orange'}}> {t('translation:phone')} </span>
                    </TypographyStyled>
                    <ButtonWidget
                      label={t('translation:financial.2.form.q3.modal.button')}
                      handleAction={() => setOpen(undefined)}
                      py={0.1}
                      fontSize={1.2}
                      blueLight
                    />
                  </div>
                }
            </CustomModal>
        </div>
    );
};

export default FinancialForm;

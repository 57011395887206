import { Box, ButtonGroup, styled } from '@mui/material';
import React, { FunctionComponent, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Civility, KycState, updateIdentity, updateIdentityContact } from '../../features/kyc/kycSlice';
import { next } from '../../features/navigation/navigationSlice';
import ButtonWidget from '../ButtonWidget';
import Typography from '@mui/material/Typography';
import CustomModal from '../CustomModal';
import { useTranslation } from 'react-i18next';
import theme from '../../theme';
import TextFieldWidget from '../TextFieldWidget';
import SelectWidget from '../SelectWidget';
import H6 from '../H6';
import Datefield from '../Datefield';
import Loader from '../Loader';
import { modifyAndFormatDate } from '../../utils/dda';
import ButtonLinkMutac from '../ButtonLinkMutac';
import * as Yup from 'yup';
import { useFormik } from 'formik';

const BoxFormat = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    color: theme.typography.caption.color,
    marginTop: '0.625rem',
    marginBottom: '0.625rem',
});

type isUserProps = { isUser: boolean; };
const IdentityForm: FunctionComponent<isUserProps> = (props: isUserProps) => {
    const kyc: KycState = useAppSelector((state) => state.kyc);
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const isUser: boolean = props.isUser;
    const var1 = isUser ? 2 : 14;
    const [displayErrors, setDisplayErrors] = useState<boolean>(false);

    /* Help Modal : local state management */
    const [open, setOpen] = useState<number>();

    const civilityValues: string[] = [];

    for (let i = 1; i <= Object.values(Civility).length / 2; i++) {
        civilityValues.push(t(`identity.2.form.q1.choice${i}`));
    }

    const handleDate = (date: any): number | undefined => {
        if (date instanceof Date && isNaN(+new Date(date))) {
            return undefined;
        }

        // date is a timestamp
        return +new Date(date);
    };

    const phoneRegExp: RegExp = RegExp(/^0[1-7](?:[\s.-]*\d{2}){4}$/);
    const validationSchema = Yup.object().shape({
        civility: Yup.string()
            .required(t('translation:errors.required')).nullable(),
        lastname: Yup.string()
            .required(t('translation:errors.required')).nullable(),
        firstname: Yup.string()
            .required(t('translation:errors.required')).nullable(),
        birthday: Yup.number()
            .min(+new Date(modifyAndFormatDate(200)), t('translation:errors.max-age'))
            .max(+new Date(modifyAndFormatDate(18)), t('translation:errors.min-age'))
            .required(t('translation:errors.required')),
        phoneNumber: Yup.string()
            .matches(phoneRegExp, t('translation:errors.format-phone'))
            .required(t('translation:errors.required')).nullable(),
        email: Yup.string()
            .required(t('translation:errors.required')).nullable(),
    });

    const HandelErrorEmail = (values: any) => {
        return (!values.email || /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email));
    };

    const updateIdentityContactWillDispatch = (values: KycState): object => {
        return isUser ? {
                phoneNumber: values.phoneNumber,
                email: values.email
            } :
            {
                phoneNumberSpouse: values.phoneNumber,
                emailSpouse: values.email
            };
    };

    const formik = useFormik({
        initialValues: {
            civility: kyc.civility,
            lastname: kyc.lastname,
            firstname: kyc.firstname,
            birthday: kyc.birthDate,
            phoneNumber: kyc.phoneNumber,
            email: kyc.email
        },
        validationSchema,
        onSubmit: values => {
            if (HandelErrorEmail(values)) {
                dispatch(updateIdentityContact(updateIdentityContactWillDispatch(values)));
                dispatch(updateIdentity({
                    civility: values.civility,
                    // @ts-ignore
                    lastname: replaceAccents(values.lastname),
                    // @ts-ignore
                    firstname: replaceAccents(values.firstname),
                    birthDate: values.birthday,
                }));
                setDisplayErrors(false);
                dispatch(next());
            } else {
                setOpen(2);
            }

        }
    });

    function replaceAccents(word: string): string {
        const accentsMap: { [key: string]: string } = {
            "á": "a",
            "é": "e",
            "í": "i",
            "ó": "o",
            "ú": "u",
            "à": "a",
            "è": "e",
            "ì": "i",
            "ò": "o",
            "ù": "u",
            "â": "a",
            "ê": "e",
            "î": "i",
            "ô": "o",
            "û": "u",
            "ä": "a",
            "ë": "e",
            "ï": "i",
            "ö": "o",
            "ü": "u",
            "ç": "c",
            "ñ": "n",
        };

        return word.replace(/[áéíóúàèìòùâêîôûäëïöüçñ]/gi, function (match) {
            return accentsMap[match];
        });
    }

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <BoxFormat marginLeft={25.5}>
                    <H6 style={{fontSize: 27}}>{t(`translation:identity.${var1}.form.q1.label`)}</H6>
                    <SelectWidget
                        id={'civility'}
                        value={formik.values.civility}
                        onChange={event => formik.setFieldValue('civility', event.target.value)}
                        object={civilityValues}
                    />
                    <TextFieldWidget
                        placeholder={t('translation:identity.2.form.q1.placeholder-lastname')}
                        id="lastname"
                        value={formik.values.lastname}
                        onChange={formik.handleChange}
                        helperText={formik.errors.lastname}
                        minWidth={19.5}
                        autocomplete={'cc-family-name'}
                    />
                    <TextFieldWidget
                        placeholder={t('translation:identity.2.form.q1.placeholder-firstname')}
                        id="firstname"
                        value={formik.values.firstname}
                        onChange={formik.handleChange}
                        helperText={formik.errors.firstname}
                        minWidth={19.5}
                        autocomplete={'cc-additional-name'}
                    />
                </BoxFormat>
                <BoxFormat marginLeft={25}>
                    <H6 style={{fontSize: 27}}>{t('translation:identity.2.form.q2.label')}</H6>
                    <Datefield
                        onChange={date => handleDate(date) !== undefined ? formik.setFieldValue('birthday', handleDate(date)) : undefined}
                        value={formik.values.birthday}
                        helperText={displayErrors ? formik.errors.birthday : ''}
                    />
                </BoxFormat>
                <BoxFormat marginLeft={25.5}>
                    <H6>{t(`translation:identity.4.form.q1.label`)}</H6>
                    <TextFieldWidget
                        placeholder={t('translation:placeholderForm.phone')}
                        id="phoneNumber"
                        value={formik.values.phoneNumber}
                        onChange={formik.handleChange}
                        helperText={formik.errors.phoneNumber}
                        autocomplete={'tel'}
                    />
                </BoxFormat>
                <BoxFormat marginLeft={25.5} my={2}>
                    <H6>{t(`translation:identity.4.form.q3.label`)}</H6>
                    <TextFieldWidget
                        placeholder={t('translation:identity.4.form.q3.placeholder')}
                        id="email"
                        minWidth={25}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        helperText={formik.errors.email}
                        autocomplete={'email'}
                    />
                </BoxFormat>
                <Box mt={23}>
                    <ButtonWidget label={t('continue')} type="submit" py={1}
                                  handleAction={() => setDisplayErrors(true)}/>
                </Box>
            </form>

            {isUser &&

              <CustomModal open={open !== undefined} handleModal={() => setOpen(undefined)}>
                  {open === 9 &&
                    <div>
                      <Typography
                        id="modal-modal-description"
                        sx={{mt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}
                      >
                          {t('translation:financial.2.incomplete.modal.p1', {cost: kyc.averageCost})}
                      </Typography>
                      <Typography
                        sx={{mt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}>
                          {t('translation:financial.2.incomplete.modal.p2')}
                      </Typography>
                      <Typography
                        sx={{mt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}>
                          {t('translation:financial.2.incomplete.modal.p3')}
                      </Typography>
                      <BoxFormat className="uk-text-center">
                        <ButtonGroup>
                          <ButtonWidget
                            label={t('translation:financial.2.incomplete.modal.button-change')}
                            handleAction={() => setOpen(undefined)} py={0.2} mx={1}
                            fontSize={1.2}
                          />
                          <ButtonWidget
                            label={t('translation:financial.2.incomplete.modal.button-next')}
                            handleAction={() => dispatch(next())} py={0.2} mx={1}
                            fontSize={1.2
                            }/>
                        </ButtonGroup>
                      </BoxFormat>
                    </div>
                  }
                  {open === 1 &&
                    <BoxFormat
                      sx={{alignItems: 'center', flexDirection: 'column', color: theme.palette.text.secondary}}>
                      <H6 color>{t('translation:placeholderForm.verifyAddress')}</H6>
                      <Loader size={'5rem'}/>
                    </BoxFormat>
                  }
                  {open === 2 &&
                    <>
                      <Typography
                        id="modal-modal-description"
                        sx={{mt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}
                        dangerouslySetInnerHTML={{__html: t('translation:errors.email')}}
                      >
                      </Typography>
                      <ButtonWidget
                        label={t('translation:identity.5.form.q1.modal.button')}
                        handleAction={() => setOpen(undefined)}
                        py={0.1}
                        fontSize={1.2}
                        blueLight
                      />
                    </>
                  }
                  {open === 3 &&
                    <div>
                      <Typography
                        id="modal-modal-description"
                        sx={{mt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}
                      >
                          {t('translation:identity.2.form.q3.modal.p1')}
                      </Typography>
                      <Typography
                        sx={{mt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}>
                      <span style={{
                          fontSize: '1.5rem',
                          color: theme.palette.text.secondary
                      }}>❶</span> {t('translation:identity.2.form.q3.modal.li1')}
                      </Typography>
                      <Typography
                        sx={{mt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}>
                      <span style={{
                          fontSize: '1.5rem',
                          color: theme.palette.text.secondary
                      }}>❷</span> {t('translation:identity.2.form.q3.modal.li2')}
                      </Typography>

                      <ButtonWidget
                        label={t('translation:identity.2.form.q3.modal.button')}
                        handleAction={() => setOpen(undefined)}
                        py={0.1}
                        fontSize={1.2}
                        blueLight
                      />
                    </div>
                  }
                  {open === 4 &&
                    <div>
                      <Typography
                        id="modal-modal-description"
                        sx={{mt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}
                      >
                          {t('translation:identity.2.form.q4.modal.p1')}
                      </Typography>
                      <Typography
                        id="modal-modal-description"
                        sx={{mt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}
                      >
                          {t('translation:identity.2.form.q4.modal.p2')}
                      </Typography>
                      <Typography
                        id="modal-modal-description"
                        sx={{mt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}
                      >
                          {t('translation:identity.2.form.q4.modal.p3')}
                      </Typography>
                      <BoxFormat sx={{justifyContent: 'center'}}>
                        <ButtonLinkMutac
                          label={t('translation:button-rdv')}
                          url={t('requirements.3.url')}
                          blueLight
                        />
                        <ButtonWidget
                          label={t('translation:identity.2.form.q4.modal.button-change')}
                          handleAction={() => setOpen(undefined)}
                          py={0.2}
                          mx={1}
                          fontSize={1.15}
                          blueLight
                        />
                      </BoxFormat>
                    </div>
                  }
              </CustomModal>
            }
        </div>
    );
};

export default IdentityForm;

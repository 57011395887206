import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, styled, Typography } from '@mui/material';
import { KycState, PaymentSupport, updateFromOtherInfo, } from '../../features/kyc/kycSlice';
import theme from '../../theme';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import InfoIconWidget from '../InfoIconWidget';
import ButtonWidget from '../ButtonWidget';
import ButtonContinue from '../ButtonContinue';
import { next } from '../../features/navigation/navigationSlice';
import CustomModal from '../CustomModal';
import H6 from '../H6';
import TextFieldWidget from '../TextFieldWidget';
import { Formik } from 'formik';
import * as Yup from 'yup';
import SelectWidget from '../SelectWidget';
import { handleEnum, handleValue } from '../../utils/enum';
import { postFamilySituation } from '../../utils/person';

const BoxFormat = styled(Box)({
    display: 'flex',
    alignItems: 'flex-start',
    marginLeft: 250,
    color: theme.typography.caption.color,
    marginTop: '1rem',
    marginBottom: '1rem',
});

const FormOtherInfo = () => {
    const kyc: KycState = useAppSelector((state) => state.kyc);
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [openModal1, setOpenModal1] = useState(false);
    const [openModal2, setOpenModal2] = useState(false);
    const [socialSecurityMessage, setSocialSecurityMessage] = useState(true);
    const [socialSecurityValidate, setSocialSecurityValidate] = useState('');
    const [socialSecurity, setSocialSecurity] = useState('');
    const handleModal1 = () => setOpenModal1(!openModal1);
    const handleModal2 = () => setOpenModal2(!openModal2);
    const paymentSupport: any[] = [];

    for (let i = 1; i <= Object.values(PaymentSupport).length / 2; i++) {
        paymentSupport.push(t(`translation:membership.1.payment-supports.${i}`));
    }

    useEffect(() => {
        const fetchData = () => {
            dispatch(postFamilySituation(kyc));
        };

        fetchData();
    }, []);

    const validationSchema = Yup.object().shape({
        fromCity: Yup.string()
            .required(t('translation:errors.required')).nullable(),
        birthName: Yup.string()
            .required(t('translation:errors.required')).nullable(),
        profession: Yup.string()
            .required(t('translation:errors.required')).nullable(),
        paymentSupport: Yup.string()
            .required(t('translation:errors.required')).nullable(),
    });

    const initialValues = {
        fromCity: kyc.fromCity || '',
        socialSecurityNumber: kyc.socialSecurityNumber || '',
        birthName: kyc.birthName || '',
        profession: kyc.profession || '',
        paymentSupport: kyc.paymentSupport
    };

    const validNextStep = (values: any) => {
        if (socialSecurityMessage && !kyc.socialSecurityNumber) {
            setSocialSecurityValidate(t('translation:errors.required'));
        } else if (kyc.socialSecurityNumber && socialSecurityValidate === '') {
            dispatch(updateFromOtherInfo({
                fromCity: values.fromCity,
                birthName: values.birthName,
                profession: values.profession,
                paymentSupport: values.paymentSupport,
            }));

            dispatch(next());
        }
    };

    const HandelErrorSS = (values: string) => {
        return (!values || /^[1-2]\d{12}$/i.test(values));
    };

    const socialSecurityHandel = (value: any) => {
        if (value.target.value === '') {
            setSocialSecurityMessage(true);
        } else {
            setSocialSecurityMessage(false);
        }
        if (HandelErrorSS(value.target.value)) {
            dispatch(updateFromOtherInfo({
                socialSecurityNumber: value.target.value,
            }));
            setSocialSecurityValidate('');
        } else {
            setSocialSecurityValidate(t('translation:errors.ssn'));
        }
        setSocialSecurity(value.target.value);
    };

    return (
        <BoxFormat sx={{marginLeft: 0, flexDirection: 'column', marginTop: 4}}>
            <Formik
                onSubmit={values => validNextStep(values)}
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                {({
                      errors,
                      handleSubmit,
                      values,
                      handleChange,
                      setFieldValue,
                      touched,
                  }) => (
                    <form onSubmit={handleSubmit} style={{width: '-webkit-fill-available'}}>
                        <BoxFormat>
                            <BoxFormat sx={{marginLeft: 0}}>
                                <H6>{t('translation:membership.1.from')}</H6>
                                <TextFieldWidget
                                    placeholder={t('translation:placeholderForm.city')}
                                    id="fromCity"
                                    value={values.fromCity}
                                    onChange={handleChange}
                                    // @ts-ignore
                                    helperText={touched.fromCity && errors.fromCity}
                                />
                            </BoxFormat>
                        </BoxFormat>
                        <BoxFormat sx={{marginLeft: 23}}>
                            <InfoIconWidget size="large" handleAction={handleModal1}/>
                            <H6>{t('translation:membership.1.social-security-number')}</H6>
                            <TextFieldWidget
                                placeholder={t('translation:placeholderForm.format-SSN')}
                                id="socialSecurityNumber"
                                value={socialSecurity === '' ? kyc.socialSecurityNumber : socialSecurity}
                                onChange={(event) => {
                                    socialSecurityHandel(event);
                                }}
                                helperText={socialSecurityValidate}
                            />
                        </BoxFormat>
                        <BoxFormat>
                            <H6>{t('translation:membership.1.birth-name')}</H6>
                            <TextFieldWidget
                                placeholder={t('translation:placeholderForm.lastname')}
                                id="birthName"
                                value={values.birthName}
                                onChange={handleChange}
                                // @ts-ignore
                                helperText={touched.birthName && errors.birthName}
                            />
                        </BoxFormat>
                        <BoxFormat>
                            <H6>{t('translation:membership.1.profession')}</H6>
                            <TextFieldWidget
                                placeholder={t('translation:placeholderForm.profession')}
                                id="profession"
                                value={values.profession}
                                onChange={handleChange}
                                // @ts-ignore
                                helperText={touched.profession && errors.profession}
                            />
                        </BoxFormat>
                        <BoxFormat sx={{mx: 23}}>
                            <InfoIconWidget size="large" handleAction={handleModal2}/>
                            <H6>{t('translation:membership.1.payment-support')}</H6>
                            <SelectWidget
                                id={'paymentSupport'}
                                value={handleValue(PaymentSupport, values.paymentSupport)}
                                onChange={e => setFieldValue('paymentSupport', handleEnum(PaymentSupport, e.target.value))}
                                // @ts-ignore
                                helperText={touched.paymentSupport && errors.paymentSupport}
                                object={paymentSupport}
                            />
                        </BoxFormat>
                        <div>
                            <ButtonContinue mt={4} my={1} disabledSkip={true}/>
                        </div>
                    </form>
                )}
            </Formik>

            <CustomModal open={openModal1} handleModal={handleModal1}>
                <Typography
                    id="modal-modal1-title" component="h2"
                    sx={{textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}
                >
                    <InfoIconWidget color="black" size="large"/>
                </Typography>
                <Typography
                    id="modal-modal1-description"
                    sx={{mt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}
                >
                    {t('translation:membership.1.modal1.p1')}
                </Typography>
                <Typography sx={{mt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}>
                    {t('translation:membership.1.modal1.p2')}
                </Typography>
                <ButtonWidget
                    label={'OK'}
                    handleAction={handleModal1}
                    py={0.1}
                    fontSize={1.2}
                    blueLight
                />
            </CustomModal>

            <CustomModal open={openModal2} handleModal={handleModal2}>
                <Typography id="modal-modal2-title" component="h2"
                            sx={{textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}>
                    <InfoIconWidget color="black" size="large"/>
                </Typography>
                <Typography id="modal-modal2-description"
                            sx={{mt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}>
                    {t('translation:membership.1.modal2.p1')}
                </Typography>
                <Typography id="modal-modal2-description-option-1"
                            sx={{mt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}>
                    {t('translation:membership.1.modal2.p2')}
                </Typography>
                <Typography id="modal-modal2-description-option-2"
                            sx={{mt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}>
                    {t('translation:membership.1.modal2.p3')}
                </Typography>
                <Typography sx={{mt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}>
                    {t('translation:membership.1.modal2.p4')}
                </Typography>
                <ButtonWidget
                    label={t('translation:funeralCosts.2.modal.button-ok')}
                    handleAction={handleModal2}
                    py={0.1}
                    fontSize={1.2}
                    blueLight
                />
            </CustomModal>
        </BoxFormat>
    );
};

export default FormOtherInfo;

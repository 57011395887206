import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Offer {
    id: number,
    capital: number,
    duration: number,
    monthlyFees: number,
    name?: string,
    isThrift?: boolean,
    isPromote?: boolean,
    numberDeadline?: number,
    feePayment?: number,
    feesManagement?: number,
    firstPayment?: number,
    contributionCode?: string,
    typeContributionId?: number,
}

export interface OffersState {
    offers: Offer[],
    selectedOfferId?: number,
    selectedContributionId?: number,
}

const initialState: OffersState = {
    offers: [],
};

const offersSlice = createSlice({
    name: 'offers',
    initialState,
    reducers: {
        addOffer: (state: OffersState, action: PayloadAction<Offer>) => {
            state.offers = [...state.offers, {
                id: action.payload.id,
                isThrift: action.payload.isThrift,
                name: action.payload.name,
                capital: action.payload.capital,
                duration: action.payload.duration,
                monthlyFees: action.payload.monthlyFees,
                isPromote: action.payload.isPromote,
                numberDeadline: action.payload.numberDeadline,
                feePayment: action.payload.feePayment,
                feesManagement: action.payload.feesManagement,
                firstPayment: action.payload.firstPayment,
                contributionCode: action.payload.contributionCode,
                typeContributionId: action.payload.typeContributionId,
            }];
        },
        removeOffer: (state: OffersState, action: PayloadAction<number>) => {
            state.offers = state.offers?.filter((a) =>
                a.id !== action.payload
            );
        },
        resetOffers: (state: OffersState) => {
            state.offers = [];
        },
        selectOffer: (state: OffersState, action) => {
            state.selectedOfferId = action.payload.selectedOfferId;
            state.selectedContributionId = action.payload.selectedContributionId;
        },
        updateOffer: (state: OffersState, action) => {
            const offerUpdate = state.offers?.find((o) =>
                o.id === action.payload.garantieId,
            );

            if (offerUpdate) {
                offerUpdate.firstPayment = action.payload.premierVersement;
                offerUpdate.monthlyFees = action.payload.montantEcheance;
                offerUpdate.feePayment = action.payload.montantFraisVersement;
                offerUpdate.feesManagement = action.payload.montantFraisGestion;
                state.offers = state.offers?.filter((o) =>
                    o.id !== action.payload.garantieId
                );

                state.offers = [...state.offers, offerUpdate];
            }
        }
    }
});

export const getActiveOffer = (offers: OffersState) => {
    return offers.offers.filter(offer => offer.id === offers.selectedOfferId &&
        offer.typeContributionId === offers.selectedContributionId)[0];
};

export const {
    addOffer,
    removeOffer,
    selectOffer,
    resetOffers,
    updateOffer,
} = offersSlice.actions;

export default offersSlice.reducer;

import { Box, styled } from '@mui/material';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    FinancialKnowledge,
    InsuranceKnowledge,
    KycState,
    LifeInsurance,
    updateIdentity4,
} from '../../features/kyc/kycSlice';
import ButtonWidget from '../ButtonWidget';
import InfoIconWidget from '../InfoIconWidget';
import Typography from '@mui/material/Typography';
import CustomModal from '../CustomModal';
import { useTranslation } from 'react-i18next';
import theme from '../../theme';
import H6 from '../H6';
import SelectWidget from '../SelectWidget';
import { getValueByKey, handleEnum, handleValue, } from '../../utils/enum';
import { next, nextWithSkip } from '../../features/navigation/navigationSlice';
import { postLead } from '../../utils/lead';

const BoxFormat = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    marginLeft: '15.625rem',
    color: theme.typography.caption.color,
});

type isUserProps = { isUser: boolean; }
const Identity4Form: FunctionComponent<isUserProps> = (props: isUserProps) => {

    const kyc: KycState = useAppSelector((state) => state.kyc);
    const subSTep = useAppSelector((state) => state.navigation.subStep);
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const marks: string[] = [t('translation:identity.6.form.q2.choice1'), t('translation:identity.6.form.q2.choice2'), t('translation:identity.6.form.q2.choice3'), t('translation:identity.6.form.q2.choice4'), t('translation:identity.6.form.q2.choice5')];
    const isUser = props.isUser;
    const step = isUser ? 6 : 18;

    /* Help Modal : local state management */
    const [open, setOpen] = useState<number>();
    const handleModal = (index: number) => setOpen(open === index ? undefined : index);

    const yesOrNoValues: string[] = [t('translation:identity.6.form.q3.choice1'), t('translation:identity.6.form.q3.choice2')];
    const values: string[] = [];
    for (let i = 1; i <= Object.values(InsuranceKnowledge).length / 2; i++) {
        values.push(t(`translation:identity.6.form.q3.choice${i}`));
    }
    const lifeInsuranceKnowledge = (): string | undefined => {
        return isUser ? kyc.lifeInsuranceKnowledge : kyc.lifeInsuranceKnowledgeSpouse;
    };

    const financialKnowledge = (): string | undefined => {
        return isUser ? kyc.financialKnowledge : kyc.financialKnowledgeSpouse;
    };

    const lifeInsuranceOwned = (): string | undefined => {
        return isUser ? kyc.lifeInsuranceOwned : kyc.lifeInsuranceOwnedSpouse;
    };

    const lifeInsuranceKnowledgeWillDispatch = (value: number): object => {
        return isUser ? {lifeInsuranceKnowledge: handleEnum(InsuranceKnowledge, value)} : {lifeInsuranceKnowledgeSpouse: handleEnum(InsuranceKnowledge, value)};
    };

    const financialKnowledgeWillDispatch = (value: number): object => {
        return isUser ? {financialKnowledge: handleEnum(FinancialKnowledge, value)} : {financialKnowledgeSpouse: handleEnum(FinancialKnowledge, value)};
    };

    const lifeInsuranceOwnedWillDispatch = (value: number): object => {
        return isUser ? {lifeInsuranceOwned: handleEnum(LifeInsurance, value)} : {lifeInsuranceOwnedSpouse: handleEnum(LifeInsurance, value)};
    };

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(postLead(kyc));
        };

        // call the function
        fetchData()
            // make sure to catch any error
            .catch(console.error);
    }, []);

    const ValidNextStep = () => {
        if (lifeInsuranceKnowledge() && getValueByKey(InsuranceKnowledge, lifeInsuranceKnowledge()) !== InsuranceKnowledge.No) {
            return financialKnowledge() !== undefined && lifeInsuranceOwned() !== undefined;
        } else {
            return lifeInsuranceKnowledge() !== undefined && lifeInsuranceOwned() !== undefined;
        }
    };

    const handleContinu = () => {
        if (kyc.whoIsConcerned === 3 &&
            kyc.lifeInsuranceOwned !== undefined &&
            getValueByKey(InsuranceKnowledge, kyc.lifeInsuranceOwned) !== InsuranceKnowledge.Yes) {
            //Go to offerList
            dispatch(nextWithSkip());
        } else {
            dispatch(next());
        }
    };

    const marginButton = () => {
        if (lifeInsuranceKnowledge() && getValueByKey(InsuranceKnowledge, lifeInsuranceKnowledge()) !== InsuranceKnowledge.No) {
            return 14;
        } else {
            return 25;
        }
    };

    return (
        <div>
            <BoxFormat style={{marginBottom: 30, marginRight: '5rem'}}>
                <H6>{t(`translation:identity.${step}.form.q1.label`)}</H6>
                <SelectWidget
                    id={'lifeInsuranceKnowledge'}
                    value={handleValue(InsuranceKnowledge, lifeInsuranceKnowledge()) || ''}
                    onChange={(event) => {
                        dispatch(updateIdentity4(lifeInsuranceKnowledgeWillDispatch(event.target.value)));
                    }}
                    object={yesOrNoValues}
                />
            </BoxFormat>
            {lifeInsuranceKnowledge() && getValueByKey(InsuranceKnowledge, lifeInsuranceKnowledge()) !== InsuranceKnowledge.No &&
              <BoxFormat style={{marginBottom: 30}}>
                <H6>{t(`translation:identity.${step}.form.q2.label`)}</H6>
                <SelectWidget
                  id={'financialKnowledge'}
                  value={handleValue(FinancialKnowledge, financialKnowledge()) || ''}
                  onChange={(event) => {
                      dispatch(updateIdentity4(financialKnowledgeWillDispatch(event.target.value)));
                  }}
                  object={marks}
                />
              </BoxFormat>
            }
            <BoxFormat style={{marginLeft: 185}}>
                <InfoIconWidget size="large" handleAction={() => handleModal(3)}/>
                <H6>{t(`translation:identity.${step}.form.q3.label`)}</H6>
                <SelectWidget
                    id={'lifeInsuranceOwned'}
                    value={handleValue(LifeInsurance, lifeInsuranceOwned()) || ''}
                    onChange={(event) => {
                        dispatch(updateIdentity4(lifeInsuranceOwnedWillDispatch(event.target.value)));
                    }}
                    object={values}
                />
            </BoxFormat>
            {ValidNextStep() &&
              <ButtonWidget
                mt={marginButton()}
                handleAction={handleContinu}
                label={t('translation:continue')}
              />
            }

            <CustomModal open={open !== undefined} handleModal={() => setOpen(undefined)}>
                {open === 3 &&
                  <div>
                    <Typography
                      id="modal-modal-description"
                      sx={{mt: 2, textAlign: 'center', fontSize: '1.3rem', color: theme.palette.text.secondary}}
                    >
                        {t('translation:identity.6.form.q3.modal.p')}
                    </Typography>
                    <ul style={{fontSize: '1.3rem', color: theme.palette.text.secondary}}>
                        {[1, 2, 3].map((num) => (
                            <li>{t(`translation:identity.6.form.q3.modal.li${num}`)}</li>
                        ))}
                    </ul>
                    <ButtonWidget
                      label={t('translation:identity.6.form.q3.modal.button')}
                      handleAction={() => setOpen(undefined)}
                      py={0.1}
                      fontSize={1.2}
                      blueLight
                    />
                  </div>
                }
            </CustomModal>
        </div>
    );
};

export default Identity4Form;

import { Box, Typography } from '@mui/material';
import React, { FormEvent, FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import InputFile from './InputFile';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { postGetFileList, saveFiles, sessionMail } from '../../utils/files';
import { KycState, updateFileUpload } from '../../features/kyc/kycSlice';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ButtonWidget from '../ButtonWidget';
import Loader from '../Loader';
import { AxiosResponse } from 'axios';
import { postPaymentMail } from '../../utils/quotation';
import { youSignFile } from '../../utils/yousign';
import CustomModal from '../CustomModal';
import H6 from '../H6';

const DragAndDrop: FunctionComponent = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const kyc: KycState = useAppSelector((state) => state.kyc);
    const [alertFormatCard, setAlertFormatCard] = React.useState('');
    const [alertFormatRib, setAlertFormatRib] = React.useState('');
    const [alertFormatQuotation, setAlertFormatQuotation] = React.useState('');
    const [fileCard, setFileCard] = React.useState({name: ''});
    const [fileRib, setFileRib] = React.useState({name: ''});
    const [fileQuotation, setFileQuotation] = React.useState({name: ''});
    const [loading, setLoading] = useState(false);
    const [iframe, setIframe] = useState('');
    const [quotationSend, setQuotationSend] = useState(false);
    const [fileDeposed, setFileDeposed] = useState(false);
    // @ts-ignore
    const timer: number = parseInt(process.env.REACT_APP_TIMER_SIGN_YOUSIGN);
    const carte: string = 'carte';
    const devis: string = 'devis';
    const rib: string = 'rib';

    const showToastMessage = () => {
        toast.warning(t('translation:contract.alert'), {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    };

    const handleDrop = (event: any, type: string) => {
        setFileDeposed(true)
        const file = event.dataTransfer.files[0];
        event.preventDefault();
        event.stopPropagation();

        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            if (event.dataTransfer.files.length > 1) {
                showToastMessage();
            } else {
                handleFile(file, type);
            }
        }
    };

    const isValidFileUploaded = (file: any) => {
        const validExtensions = ['png', 'jpeg', 'pdf'];
        const fileExtension = file.type.split('/')[1];

        return validExtensions.includes(fileExtension);
    };

    const fileChange = (e: any, type: string) => {
        if (e.target.files.length < 1) {
            return;
        }
        setFileDeposed(true);

        const file = e.target.files[0];

        switch (true){
            case type === carte:
                if (isValidFileUploaded(file)) {
                    setAlertFormatCard('');
                    setFileCard(e.target.files[0]);
                } else {
                    setAlertFormatCard(t('translation:contract.type'));
                }
                break;
            case type === devis:
                if (isValidFileUploaded(file)) {
                    setAlertFormatQuotation('');
                    let formData = new FormData();
                    formData.append('formData', e.target.files[0]);
                    setFileQuotation(e.target.files[0]);
                } else {
                    setAlertFormatQuotation(t('translation:contract.type'));
                }
                break;
            case type === rib:
                if (isValidFileUploaded(file)) {
                    setAlertFormatRib('');
                    setFileRib(e.target.files[0]);
                } else {
                    setAlertFormatRib(t('translation:contract.type'));
                }
                break;
        }
    };

    const handleFile = (file: any, type: string) => {
        switch (type){
            case carte:
                if (kyc.idCardUpload) {
                    break;
                }
                if (isValidFileUploaded(file)) {
                    setAlertFormatCard('');
                    setFileCard(file);
                } else {
                    setAlertFormatCard(t('translation:contract.type'));
                    setFileCard({name: ''});
                }
                break;
            case devis:
                if (kyc.quotationUpload) {
                    break;
                }
                if (isValidFileUploaded(file)) {
                    setAlertFormatQuotation('');
                    setFileQuotation(file);
                } else {
                    setAlertFormatQuotation(t('translation:contract.type'));
                    setFileQuotation({name: ''});
                }
                break;
            case rib:
                if (kyc.ribUpload) {
                    break;
                }
                if (isValidFileUploaded(file)) {
                    setAlertFormatRib('');
                    setFileRib(file);
                } else {
                    setAlertFormatRib(t('translation:contract.type'));
                    setFileRib({name: ''});
                }
                break;
        }
    };

    const submit = async (e: FormEvent) => {
        setLoading(true);
        e.preventDefault();
        let filesNameSend: string[] = [];

        let quoIsSent: boolean = kyc.quotationUpload ?? false;
        let cardIsSent: boolean = kyc.idCardUpload ?? false;
        let ribIsSent: boolean = kyc.ribUpload ?? false;

        let respQuotation: AxiosResponse<any, any>;
        let respCard: AxiosResponse<any, any>;
        let respRib: AxiosResponse<any, any>;

        if (fileQuotation.name && !kyc.quotationUpload) {
            filesNameSend.push('Bulletin d\'adhésion');
            respQuotation = await dispatch(saveFiles(kyc, fileQuotation, 4));
            setFileDeposed(false);
            if (respQuotation?.status === 200) {
                dispatch(updateFileUpload({quotationUpload: true}));
                quoIsSent = true;
            }
        }
        if (fileCard.name && !kyc.idCardUpload) {
            filesNameSend.push('Carte d\'identité');
            respCard = await dispatch(saveFiles(kyc, fileCard, 5));
            setFileDeposed(false);
            if (respCard.status === 200) {
                dispatch(updateFileUpload({idCardUpload: true}));
                cardIsSent = true;
            }
        }
        if (fileRib.name && !kyc.ribUpload) {
            filesNameSend.push('Rib');
            respRib = await dispatch(saveFiles(kyc, fileRib, 6));
            setFileDeposed(false);
            if (respRib.status === 200) {
                dispatch(updateFileUpload({ribUpload: true}));
                ribIsSent = true;
            }
        }

        await dispatch(sessionMail(kyc, filesNameSend));

        if (quoIsSent && cardIsSent && ribIsSent) {
            await dispatch(postPaymentMail(kyc));
        }
        setLoading(false);
    };

    const electronicallySign = async () => {
        let res: any;
        setLoading(true);
        res = await dispatch(youSignFile(kyc, t('translation:contract.2.p1')));
        setLoading(false);
        setIframe(res.data);
    };


    const handleCloseModal = () => {
        setLoading(true);
        setQuotationSend(true);
        let fileReceived: boolean = false;
        setTimeout(async () => {
            //@ts-ignore
            const respFile = await dispatch(postGetFileList(kyc.folderGuidToQuotation));

            respFile.data.filter((id: number) => {
                if (id === 4) {
                    fileReceived = true;
                    dispatch(updateFileUpload({quotationUpload: true}));
                }
            });

            if (!fileReceived) {
                toast.warning(t('translation:errors.failed-file-received'), {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            }
            setQuotationSend(false);
            setLoading(false);
        }, timer);
    };

    return (
        <>
            <Form
                id="form-file-upload"
                onSubmit={(e) => submit(e)}
                encType="multipart/form-data"
            >
                {loading ? (
                    <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                        <Loader size="10rem" color={'light'}/>
                        {quotationSend &&
                          <Box sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              marginTop: '3rem',
                              marginLeft: '3rem',
                              marginRight: '4rem'
                          }}>
                            <H6 style={{textAlign: 'center'}}>
                              <div dangerouslySetInnerHTML={{__html: t('translation:contract.p')}}/>
                            </H6>
                          </Box>
                        }
                    </Box>
                ) : (
                    <>
                        {!(kyc.quotationUpload || kyc.idCardUpload || kyc.ribUpload) &&
                          <Typography variant={'h6'} textAlign={'center'}
                                      mx={8}>{t('translation:contract.empty')}</Typography>
                        }
                        {kyc.quotationUpload ? (
                            <InputFile
                                onChange={(e) => fileChange(e, devis)}
                                formatFile={alertFormatQuotation}
                                idInput={devis}
                                onDrop={(e) => handleDrop(e, devis)}
                                nameFile={fileQuotation.name}
                                alreadySign={kyc.quotationUpload}
                                comment={t(`translation:contract.already-sent.devis`)}
                                icon
                            />
                        ) : (
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <InputFile
                                    onChange={(e) => fileChange(e, devis)}
                                    formatFile={alertFormatQuotation}
                                    idInput={devis}
                                    onDrop={(e) => handleDrop(e, devis)}
                                    nameFile={fileQuotation.name}
                                    alreadySign={kyc.quotationUpload}
                                    comment={t(`translation:contract.send.devis-manually`)}
                                    small
                                />
                                <Typography sx={{margin: 'unset'}} variant={'h6'} textAlign={'center'}
                                            mx={8}>{t('translation:placeholderForm.or')}</Typography>
                                <BigButton
                                    onClick={electronicallySign}
                                >{t(`translation:contract.send.devis-you-sign`)}
                                </BigButton>
                            </div>
                        )}
                        <InputFile
                            onChange={(e) => fileChange(e, carte)}
                            formatFile={alertFormatCard}
                            idInput={carte}
                            onDrop={(e) => handleDrop(e, carte)}
                            nameFile={fileCard.name}
                            alreadySign={kyc.idCardUpload}
                            comment={kyc.idCardUpload
                                ? t(`translation:contract.already-sent.carte`)
                                : t(`translation:contract.send.carte`)}
                            icon
                        />
                        <InputFile
                            onChange={(e) => fileChange(e, rib)}
                            formatFile={alertFormatRib}
                            idInput={rib}
                            onDrop={(e) => handleDrop(e, rib)}
                            nameFile={fileRib.name}
                            alreadySign={kyc.ribUpload}
                            comment={kyc.ribUpload
                                ? t(`translation:contract.already-sent.rib`)
                                : t(`translation:contract.send.rib`)}
                            icon
                        />
                        {!(kyc.quotationUpload && kyc.idCardUpload && kyc.ribUpload) && fileDeposed &&
                          <ButtonWidget type={'submit'} label={t('translation:placeholderForm.send')}/>
                        }
                    </>
                )}
                <ToastContainer/>
            </Form>

            <CustomModal open={iframe !== ''} handleModal={() => setIframe('')} hideBackdrop={true}>
                <iframe id={'myiframe'} style={{'width': '80vw', 'height': '80vh'}}
                        src={iframe}></iframe>
                <ButtonWidget
                    label={t('translation:contract.close')}
                    handleAction={() => {
                        handleCloseModal();
                        setIframe('');
                    }}
                    py={0.1}
                    fontSize={1.2}
                    blueLight
                />
            </CustomModal>
        </>
    );
};

const Form = styled.form`
    height: fit-content;
`;

const BigButton = styled.button`
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #FFF;
    margin: 1rem;
    height: 165px;
    background-color: #011C77;
    color: #FFF;
    font-size: 1.9rem;
    font-family: Nunito;
    width: 50%;
    line-height: 3rem;
`;

export default DragAndDrop;

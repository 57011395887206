// @ts-nocheck
import { httpApiGet, httpApiPost } from './http.js';
import { Beneficiary, KycState, Tie, TieMutac } from '../features/kyc/kycSlice';
import { formatDate, isInEnum } from './dda';
import { getValueByKey, handleValue } from './enum';

export const postAddBeneficiaries = (data: {}) => async () => {
    return httpApiPost(
        '/beneficiaries',
        data
    );
};

export const getBeneficiariesApi = (kyc: KycState, beneficiaries: Beneficiary[]) => {
    let beneficiariesApi: any = [];
    beneficiaries.forEach(beneficiary => {
        if (!beneficiary.id) {
            beneficiariesApi.push(deserializeBeneficiary(beneficiary, kyc));
        }
    });

    return beneficiariesApi;
};

const deserializeBeneficiary = (beneficiary: Beneficiary, kyc: KycState) => {
    if (beneficiary.type === 1) {


        return {
            'id': beneficiary.id,
            'adresse': getAddressBeneficiary(beneficiary),
            'codePostal': beneficiary.zipcode,
            'dateNaissance': formatDate(beneficiary.physicalBirthday),
            'devisNrid': kyc.quotationNrid,
            'devisReference': kyc.quotationRef,
            'lienParente': TieMutac[handleValue(Tie, beneficiary.physicalTie) - 1],
            'lieuNaissance': beneficiary.physicalBirthCity,
            'nom': beneficiary.physicalLastname,
            'personNrid': kyc.idAccount,
            'prenom': beneficiary.physicalFirstname,
            'quotePart': beneficiary.distribution,
            'type': 1,
            'ville': beneficiary.city,
        };
    } else {
        return {
            'id': beneficiary.id,
            'adresse': getAddressBeneficiary(beneficiary),
            'codePostal': beneficiary.zipcode,
            'devisNrid': kyc.quotationNrid,
            'devisReference': kyc.quotationRef,
            'nom': beneficiary.companyName,
            'personNrid': kyc.idAccount,
            'quotePart': beneficiary.distribution,
            'sirenNumber': beneficiary.sirenNumber,
            'statutLegal': beneficiary.legalStatus,
            'type': 2,
            'ville': beneficiary.city,
        };
    }
};

export const serializeBeneficiary = (object: Object, isPhysicalBeneficiary: boolean): Beneficiary => {
    let beneficiary: Beneficiary = {
        id: object.id,
        streetName: object.adresse,
        zipcode: object.codePostal,
        distribution: object.quotePart,
        city: object.ville,
    };

    if (isPhysicalBeneficiary) {
        beneficiary = {
            ...beneficiary,
            physicalBirthday: object.dateNaissance,
            physicalBirthCity: object.lieuNaissance,
            physicalTie: isInEnum(Tie, object.lienParente) ? Tie[getValueByKey(TieMutac, object.lienParente)] : Tie[8],
            tieOther: isInEnum(Tie, object.lienParente) ? '' : object.lienParente,
            physicalLastname: object.nom,
            physicalFirstname: object.prenom,
            type: 1,
        };
    } else {
        beneficiary = {
            ...beneficiary,
            companyName: object.nom,
            legalStatus: object.statutLegal,
            type: 2,
        };
    }

    return beneficiary;
};

export const getAddressBeneficiary = (beneficiary: Beneficiary) => {
    return beneficiary.streetNumber + ' ' + beneficiary.streetName;
};

export const updateBeneficiaries = (beneficiaries: [], beneficiariesPM: []) => {
    return [...beneficiaries, ...beneficiariesPM];
};

export const deleteBeneficiary = async (beneficiary: Beneficiary, kyc: KycState) => {
    return httpApiPost(
        '/delete-beneficiary',
        {
            'type': beneficiary.type,
            'id': beneficiary.id,
            'devisNrid': kyc.quotationNrid,
        }
    );
};

export const getBeneficiaries = (quotationRef: string) => async () => {
    return await httpApiGet(
        `/get-beneficiaries?quotationRef=${quotationRef}`
    );
};

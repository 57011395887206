import React, { FunctionComponent } from 'react';
import { Box, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import FormOtherInfo from './FormOtherInfo';
import FormBeneficiary from './FormBeneficiary';
import FormPayment from './FormPayment';
import FormAgreementMembership from './FormAgreementMembership';
import Titlebar from '../Titlebar';
import { useTranslation } from 'react-i18next';
import MySituation from './MySituation';
import FormAddress from './FormAddress';
import FunctionAbroad from './FunctionAbroad';
import ButtonLinkMutac from '../ButtonLinkMutac';
import { KycState } from '../../features/kyc/kycSlice';
import { previousWithSkip } from '../../features/navigation/navigationSlice';

const MembershipPage: FunctionComponent = () => {
    const {subStep: subStep} = useAppSelector((state) => state.navigation);
    const kyc: KycState = useAppSelector((state) => state.kyc);
    const dispatch = useAppDispatch();
    const {t} = useTranslation();

    const previousOtherInfo = () => {
        dispatch(previousWithSkip());
    };

    return (
        <Box sx={{contain: 'content'}}>

            {/******************************************************************************************************************/}
            {subStep === 1 &&
              <>
                <Titlebar style={{marginBottom: 13}} text={t('translation:membership.7.title')}/>
                <MySituation/>
              </>
            }
            {/******************************************************************************************************************/}
            {subStep === 2 &&
              <>
                <Titlebar text={t('translation:identity.2.title')}/>
                <FunctionAbroad/>
              </>
            }
            {/******************************************************************************************************************/}
            {subStep === 3 &&
              <>
                <Titlebar text={t('translation:identity.2.title')}/>
                <FormAddress/>
              </>
            }
            {/*********************************************************** ****************************************************** */}
            {subStep === 4 && kyc.taxResidence === false &&
              <div>
                <Titlebar text={t('translation:identity.3.title')}/>
                <Box mx={18} my={4} sx={{textAlign: 'center'}}>
                  <Typography
                    variant="caption"
                    sx={{textAlign: 'center', fontSize: 25, margin: 'auto', lineHeight: 1.4}}
                    dangerouslySetInnerHTML={{__html: t('translation:identity.3.p1')}}
                  />
                </Box>
                <ButtonLinkMutac label={t('translation:button-rdv')} url={t('translation:url-mutac-contact-rdv')}/>
              </div>
            }
            {/******************************************************************************************************************/}
            {subStep === 5 &&
              <>
                <Titlebar style={{marginTop: 0, marginBottom: 3}} text={t('translation:membership.1.title')}
                          handleAction={previousOtherInfo}/>
                <FormOtherInfo/>
              </>
            }
            {/******************************************************************************************************************/}
            {subStep === 6 &&
              <>
                <Titlebar text={t('translation:membership.beneficiary')} style={{'marginBottom': 'unset'}} subtitle={t('translation:membership.beneficiary-sub-title')}/>
                <FormBeneficiary/>
              </>
            }
            {/******************************************************************************************************************/}
            {subStep === 7 &&
              <>
                <Titlebar style={{marginBottom: 0}} variant={'H2'} text={t('translation:membership.5.title')}/>
                <FormPayment/>
              </>
            }
            {/******************************************************************************************************************/}
            {subStep === 8 &&
              <>
                <Titlebar text={t('translation:membership.6.title')}/>
                <FormAgreementMembership/>
              </>
            }
        </Box>
    );
};

export default MembershipPage;

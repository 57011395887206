import { Box, Modal } from '@mui/material';
import React, { FunctionComponent, MouseEventHandler, ReactNode } from 'react';

type MutacModalProps = { open: boolean, handleModal: MouseEventHandler, children: ReactNode, hideBackdrop?: boolean };
const CustomModal: FunctionComponent<MutacModalProps> = (props: MutacModalProps) => {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: '50%',
        maxWidth: '80%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        borderRadius: '20px',
        boxShadow: 24,
        p: 4,
    };
    return (
        <Modal
            open={props.open}
            onClose={props.handleModal}
            hideBackdrop={props.hideBackdrop}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {props.children}
            </Box>
        </Modal>
    );
};

export default CustomModal;




import { TextField } from '@mui/material';
import React, { ChangeEventHandler, FunctionComponent, PropsWithChildren } from 'react';
import theme from '../theme';
import { t } from 'i18next';

interface WidgetProps extends PropsWithChildren<any> {
    id: string,
    onChange: ChangeEventHandler,
    minWidth?: number,
    maxWidth?: number,
    placeholder?: string,
    helperText?: string
    value?: string,
    name?: string,
    marginLeft?: boolean,
    disabled?: boolean,
    autocomplete?: string,
}

const TextFieldWidget: FunctionComponent<WidgetProps> = ({
    id,
    onChange,
    minWidth,
    maxWidth,
    placeholder,
    helperText,
    value,
    name,
    marginLeft = true,
    disabled = false,
    autocomplete,
}: WidgetProps) => {
    const defineWidth = (input: boolean) => {
        if (!maxWidth && minWidth) {
            return input ? t('translation:errors.rem', {value: minWidth - 1}) : t('translation:errors.rem', {value: minWidth});
        }

        if (input && maxWidth && minWidth) {
            return helperText ? t('translation:errors.rem', {value: maxWidth - 1}) : t('translation:errors.rem', {value: minWidth - 1});
        }

        return helperText ? t('translation:errors.rem', {value: maxWidth}) : t('translation:errors.rem', {value: minWidth});
    };

    return (
        <TextField
            sx={{
                ml: marginLeft ? 2 : 0.75,
                input: {
                    color: theme.typography.caption.color,
                    fontSize: theme.typography.h6.fontSize,
                    height: '1rem',
                    boxShadow: '0 2px 1px rgba(255, 255, 255, 0.5)',
                    borderRadius: 1,
                    maxWidth: defineWidth(true),
                    minWidth: '-webkit-fill-available',

                    '&:disabled': {
                        '-webkit-text-fill-color': '#AAA',
                        color: '#BBB',
                    },
                    name: autocomplete,
                },

                width: defineWidth(false),
                alignItems: 'flex-start',
            }}
            hiddenLabel
            placeholder={placeholder}
            id={id}
            variant="outlined"
            value={value}
            onChange={onChange}
            helperText={helperText}
            name={name}
            disabled={disabled}
            autoComplete={autocomplete ? 'on' : 'unfill'}
        />
    );
};

export default TextFieldWidget;




export default class CostService {
    static getCost(dpt: string): number {
        // @ts-ignore
        return costsByDepartment[dpt];
    }
}

const costsByDepartment =
    {
            '01': 4369,
            '02': 6329,
            '03': 3787,
            '04': 3507,
            '05': 3040,
            '06': 3620,
            '07': 3444,
            '08': 4943,
            '09': 3656,
            '10': 5084,
            '11': 3408,
            '12': 3613,
            '13': 3321,
            '14': 4015,
            '15': 3978,
            '16': 3500,
            '17': 4085,
            '18': 4708,
            '19': 3383,
            '20': 4423,
            '2A': 4423,
            '2B': 4423,
            '21': 3878,
            '22': 3697,
            '23': 4005,
            '24': 3703,
            '25': 4643,
            '26': 4443,
            '27': 4040,
            '28': 4351,
            '29': 3571,
            '30': 3706,
            '31': 3719,
            '32': 3766,
            '33': 3350,
            '34': 3490,
            '35': 3500,
            '36': 2930,
            '37': 3519,
            '38': 3465,
            '39': 3964,
            '40': 3396,
            '41': 4208,
            '42': 3283,
            '43': 3301,
            '44': 4338,
            '45': 4086,
            '46': 2736,
            '47': 3848,
            '48': 2844,
            '49': 4305,
            '50': 4874,
            '51': 4321,
            '52': 3945,
            '53': 3073,
            '54': 4468,
            '55': 5843,
            '56': 3322,
            '57': 3406,
            '58': 4523,
            '59': 4671,
            '60': 4724,
            '61': 4338,
            '62': 4303,
            '63': 4609,
            '64': 3504,
            '65': 3426,
            '66': 3585,
            '67': 3364,
            '68': 3174,
            '69': 3998,
            '70': 3710,
            '71': 3810,
            '72': 4240,
            '73': 3541,
            '74': 4115,
            '75': 4798,
            '76': 4429,
            '77': 4741,
            '78': 5618,
            '79': 3943,
            '80': 3946,
            '81': 3731,
            '82': 3608,
            '83': 3826,
            '84': 3239,
            '85': 4319,
            '86': 4063,
            '87': 3165,
            '88': 4668,
            '89': 3781,
            '90': 3628,
            '91': 4314,
            '92': 3182,
            '93': 6751,
            '94': 4200,
            '95': 5255,
            '971': 5170,
            '972': 3763,
            '973': 3770,
            '974': 2030,
            '975': 3770,
            '976': 3770
    };

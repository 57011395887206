import React, { FunctionComponent } from 'react';
import { Box, Typography } from '@mui/material';
import theme from '../../theme';
import styled from '@emotion/styled';
import H6 from '../H6';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

type InputFileProps = {
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    formatFile?: string,
    idInput?: string,
    nameFile?: string,
    onDrop?: React.DragEventHandler<HTMLFormElement>,
    alreadySign?: boolean,
    comment?: string,
    small?: boolean,
    icon?: boolean,
};
const InputFile: FunctionComponent<InputFileProps> = (props: InputFileProps) => {
    const [dragActive, setDragActive] = React.useState(false);

    const handleDrag = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.type === 'dragenter' || event.type === 'dragover') {
            setDragActive(true);
        } else if (event.type === 'dragleave') {
            setDragActive(false);
        }
    };

    const getStyle = () => {
        return props.small ? {width: '50%'} : {};
    };

    const getStyleText = () => {
        return {margin: '0', fontSize: 30};
    };

    return (
        <div style={getStyle()}>
            {!props.alreadySign &&
              <Input type="file" id={props.idInput} onChange={props.onChange}
                     accept="image/jpeg, application/pdf, image/png"/>
            }
            <Label style={{'position': 'relative'}} id={props.idInput} htmlFor={props.idInput} onDragEnter={handleDrag}
                   className={dragActive ? 'drag-active' : ''}>
                <Box sx={{textAlign: 'center', color: theme.typography.caption}}>
                    {props.alreadySign &&
                      <CheckCircleIcon color={'success'} fontSize={'large'}/>
                    }
                    {props.icon && !props.alreadySign &&
                      <img src="/assets/addDocument.png" alt={'icon folder'}/>
                    }
                </Box>
                <Box sx={{textAlign: 'center', color: theme.typography.caption}}>
                    <H6 style={getStyleText()}>{props.comment}</H6>
                </Box>
                <Box>
                    <Typography style={{color: theme.palette.error.light}}>
                        {props.nameFile}
                    </Typography>
                </Box>
                <Div
                    id="drag-file-element"
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={props.onDrop}
                >
                </Div>
            </Label>
            <p style={{color: theme.palette.error.light, textAlign: 'center'}}>
                {props.formatFile}
            </p>
        </div>
    );
};

const Input = styled.input`
    display: none;
`;

const Label = styled.label`
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    margin: 1rem;
    padding: 1rem;
    flex-direction: column;
    max-height: 127px;
    img {
        height: 80px;
    }
`;

const Div = styled.form`
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
`;

export default InputFile;

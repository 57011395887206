import React, { FunctionComponent, useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import FormContract from './FormContract';
import Titlebar from '../Titlebar';
import Resume from './Resume';
import H2 from '../H2';
import DragAndDrop from './DragAndDrop';
import ButtonWidget from '../ButtonWidget';
import { KycState } from '../../features/kyc/kycSlice';
import { next } from '../../features/navigation/navigationSlice';
import H3 from '../H3';
import Loader from '../Loader';

const ContractPage: FunctionComponent = () => {
    const kyc: KycState = useAppSelector((state) => state.kyc);
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const {step} = useAppSelector((state) => state.navigation);
    const {subStep: subStep} = useAppSelector((state) => state.navigation);
    const [isDisable, setIsDisable] = useState(false);
    const [isSend, setIsSend] = React.useState(false);

    const handleNextStep = async () => {
        setIsDisable(!isDisable);

        dispatch(next());
    };

    useEffect(() => {
        if (kyc.quotationUpload && kyc.idCardUpload && kyc.ribUpload) {
            setTimeout(() => {
                setIsSend(true);
            }, 2100);
        }
    }, [kyc.quotationUpload, kyc.idCardUpload, kyc.ribUpload]);

    return (
        <Box>
            {step === 5 &&
              <>
                <H2 mb={2}>{t('translation:contract.title-prospect')}</H2>
                <H3 center={true} text={t('translation:contract.sub-title-1')}/>
                <FormContract keyI18n={'contract.1'}/>
              </>
            }
            {/******************************************************************************************************************/}
            {step === 7 && subStep === 1 &&
              <Grid container
                    style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{borderRight: 'inset'}}
                >
                  <Titlebar style={{marginTop: 0, marginBottom: 4}} text={t('translation:contract.title-contract')}/>
                  <Box mt={6}>
                    <H2 mb={3.5}>{t('translation:contract.sub-title-1')}</H2>
                    <FormContract keyI18n={'contract.2'}/>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{display: 'flex', flexDirection: 'column', margin: 'auto', marginTop: '1rem'}}
                >
                  <H2 mb={3.5}>{t('translation:contract.sub-title-2')}</H2>
                  <DragAndDrop/>
                </Grid>
                  {kyc.quotationUpload && kyc.idCardUpload && kyc.ribUpload && isSend &&
                    <ButtonWidget
                      label={t('translation:continue')}
                      handleAction={handleNextStep}
                      my={3}
                      disabled={isDisable}
                    />
                  }
                  {kyc.quotationUpload && kyc.idCardUpload && kyc.ribUpload && !isSend &&
                    <Box mt={4}>
                      <Loader color={'light'} size={'3rem'}/>
                    </Box>
                  }
              </Grid>
            }
            {/******************************************************************************************************************/}
            {step === 7 && subStep === 2 &&
              <Resume/>
            }
        </Box>
    );
};

export default ContractPage;
